import React from 'react';
import styled from "@emotion/styled";
import Html from './Html'

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"

const ImgWithDesc = ({ blockData, ...props }) => {
    const { url, title, description, isImageLeft } = blockData;
    let isTitle = title !== "";
    const srcUrl = url.replace("0,0,0,0", `${window.innerWidth},0,0,0`)
    return (
        <Content isImageLeft={isImageLeft} title={isTitle}>
            <img src={srcUrl} alt="" onLoad={() => props.updateHeight()} />
            <section >
                {isTitle &&
                    <div className="imgwithdesc-title">
                        <Html html={title} />
                    </div>}
                <div className="imgwithdesc-description">
                    <Html html={description} />
                </div>
            </section>
        </Content>
    )
}

const Content = styled.div`
a {
    color: rgb(255, 102, 0) !important;
    &:hover{
        text-decoration: none;
    }
}
height: auto;
display: flex;
flex-direction: ${props => props.isImageLeft ? 'column' : 'column-reverse'};
    section{
       .imgwithdesc-title > div{
            margin: 15px 38px;	
            font-family: 'Playfair Display', serif;
            font-weight: 600;
            color: #272424; 
            font-size: 17px;
            letter-spacing: -0.34px;	
            line-height: 23px;
        }
        
        .imgwithdesc-description div{	
            margin: 0 38px;
            color: #262626;	
            font-size: 17px;	
            font-weight: 300;	
            letter-spacing: -0.34px;	
            line-height: 23px;
        }
    }

    img{
        margin: 35px 0;
        object-fit: cover;
        width: 100%;
    }

    @media (min-width: 992px) {
        flex-direction: ${props => props.isImageLeft ? 'row' : 'row-reverse'};
        margin: 85px 0;
        img{
            margin: 0;
            width:50%;
        }

        section{
            margin: auto;
            display: flex;
            flex-direction: column;
            max-width: 660px;
            .imgwithdesc-title div{
                margin: 15px auto;
                font-weight: 400;
                font-size: 38px;
                line-height: 35px;
                margin-bottom: 35px;
            }
            .imgwithdesc-description div{
                width: 469px;
                margin: ${props => props.title ? '0 auto' : 'auto'};
                margin-left: 0;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
`

export default withDrawingBoard(props => props.blockData.hasCanvas)(ImgWithDesc)