import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { EmbeddedVideo } from "react-facebook";

import Link from "../Link";
import KulkiSvg from "./KulkiSvg"

import PlayIcon from "../../assets/img/play.svg";
import CommentImage from "../../assets/img/comment_gray.svg";
import FacebookImage from "../../assets/img/facebook_round_gray.svg";
import Youtube from "../Article/Parts/Youtube";
import OnnetworkPlayer from "../OnnetworkPlayer";

import Media from "./Media";

const SIZES = {
  'sm': 171,
  'md': 190,
  'lg': 292,
  "embed": 387,
  'default': 139
}

const PhotoOnTop = props => {
  const { article, size = 'default', handleClick = () => { } } = props;
  const isDesktop = window.innerWidth > 992;
  const [height, setHeight] = useState(isDesktop ? SIZES[size] : SIZES['md']);
  const imageHeight = (props.imageHeight > 0 ? props.imageHeight : window.innerWidth > 992 ? 154 : 454);
  const imageWidth = window.innerWidth > 992 ? 300 : 720;
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const label = (article.label ? <Label>{article.label}</Label> : null);
  const player = (parseInt(article.player) === 1 ? <Player /> : null);
  const showAutor = (article.author && article.author.show && article.author.name && article.author.name.length > 0);
  const ref = useRef(null);
  const authorImgUrl = (article.author && article.author.img ? article.author.img : "");
  const imgUrl = (article.author?.url?.includes("blogi") || !article.picture?.img) ? article.author.img : article.picture.img;
  const hasTitle = article.title !== ""

  useEffect(() => {
    let cHeight;
    if (isDesktop && (props.size === 'sm')) {
      cHeight = Math.ceil(ref.current.offsetWidth / 1.59)
    } else {
      cHeight = Math.ceil(ref.current.offsetWidth / 1.94)
    }

    if (cHeight !== height) {
      setHeight(cHeight)
    }
  }, [])

  var stats = [];
  if (article.stats && article.stats.shares) {
    stats.push(<span key='fb-shares'><img src={FacebookImage} alt="" /> <span data-facebook-stats>{article.stats.shares}</span></span>);
  }
  if (article.stats && article.stats.comments) {
    stats.push(<span key='fb-comments'><img src={CommentImage} alt="" /> <span data-comments-stats>{article.stats.comments}</span></span>);
  }
  stats = <Stats data-stat-furl={article.url}>{stats}</Stats>;

  var category = null;
  if (article.category && article.category.name) {
    category = (<Tag>#{article.category.name}</Tag>);
  }

  var footer = null;
  if (showAutor) {
    footer = (
      <Footer>
        <Author>
          <img src={authorImgUrl} alt={article.author.name} />
          <AuthorName>
            {article.author.name}
          </AuthorName>
        </Author>
        {stats}
      </Footer>
    );
  }

  var embed = null;
  if (article.embed && article.embed.type) {
    if (article.embed.type == 'youtube') {
      embed = <EmbedElement size={props.size}>
        <Youtube videoId={article.embed.id} />
      </EmbedElement>
    } else if (article.embed.type == 'onnetwork') {
      embed = <EmbedElement size={props.size}>
        <div className="pot--embed-onnetwork">
          <OnnetworkPlayer
            mid={article.embed.mid}
          />
        </div>
      </EmbedElement>
    } else if (article.embed.type == 'facebook') {
      embed = (<EmbedElement size={props.size}>
        <div className="pot--embed--facebook">
          <EmbeddedVideo href={article.embed.url} />
        </div>
      </EmbedElement>);
    }
  }

  return (
    <BoxWrapper onClick={handleClick} ref={ref} hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs} className={article.background ? 'background--' + article.background : null + " photo-on-top"}>
      <Link to={article.url}>
        <ImageWrapper>
          {/* {embed ? null : <img alt="" src={imgUrl} />} */}
          {embed ? null : <Media
            media={props.media}
            src={imgUrl}
            preserveRatio={false}
            aspectRatio={size === 'sm' ? 1.59 : 1.94}
            height={height} />}
          {/* 5805 */}
          {/* {showAutor ? null : stats} */}
          {player}
          {embed}
          {partner}
          {label}
        </ImageWrapper>

        {category}
        {(!hasTitle && article?.embed?.type == 'onnetwork') || <Title className="photo-on-top__title" size={props.size} hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs}>
          <KulkiSvg />
          {article.title}
        </Title>}
        {footer}
      </Link>
    </BoxWrapper>
  );
};

const Title = styled.span`
  color: ${({ hasMobilePcs }) => hasMobilePcs ? '#fff' : '#272424'};
  font-size: 14.1px;
  line-height: 18px;
  padding: 5px;
  padding-left: 25px;  
  display: block;
  position: relative;
  height: 60px;
  overflow: hidden;

  svg {
    display: block;
    position: absolute;
    top: 0;
    left: 9px;
  } 

  /* opcja z dużym tekstem, wykorzystywana w "dwójka wysoka" */
  @media (min-width: 992px) {
  color: ${({ hasDesktopPcs }) => hasDesktopPcs ? '#fff' : '#272424'};
  background-color: ${({ hasDesktopPcs }) => hasDesktopPcs && 'transaprent'};
  ${props => props.size == 'lg' && ({
    fontSize: '24px',
    lineHeight: '27px',
    padding: '10px',
    paddingLeft: '45px',
    height: 'auto',
    maxHeight: '143px',
    svg: {
      width: '20px',
      height: '50px',
      top: '-5px',
      left: '13px'
    }
  })};
  }
`;

const BoxWrapper = styled.div`
  padding: 12px 0;
  position: relative;

  /* kreska pozioma */
  &:after{
    content:"";
    width: 0;
    height: 4px;
    position: absolute;
    bottom: 12px; /* = padding na BoxWrapper !!! */
    left: 0;
    transition: all 0.2s linear;
    transition-delay: 0s;
    background: ${props => props.theme.mainColor};
    border-bottom-left-radius: 4px;
    z-index: 10;
  }
  &:hover::after{
    width: 100%;
    transition-delay: 0.2s;
  }
 
  > a {
    border: 1px solid rgba(151,151,151,0.15);
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 6px;
    background-color: ${({ hasMobilePcs }) => hasMobilePcs ? 'rgba(1, 1, 1, 0.2)' : '#ffffff'};
    text-decoration: none;
    display: block;
    position: relative;
    /* kreska pionowa */
    &:before{
      content:"";
      width:4px;
      height:0;
      position: absolute;
      top: 0;
      left: -2px;
      transition: all 0.2s linear;
      transition-delay: 0.2s;
      background: ${props => props.theme.mainColor};
      border-bottom-left-radius: 4px;
      z-index: 10;
    }
    &:hover {
      text-decoration: none;
      
      ${Title} {
        color: ${props => props.theme.mainColor};
      };

      &::before {
        height: 100%;
        transition-delay: 0s;
      }

      circle{
        fill: #000;
        stroke: #000;
      }
    }
    circle {
      transition: all .2s linear;
    }
  }

  /* na głównej, opcja czarna i czerwona */
  &.background {
  	&--red {
  		> a {background-color: #ED2024;}
  		${Title} {color: white;}
  		
	  	&:hover {
			${Title} {color: white;}
		}
  	}
  	
  	&--black {
  		> a {background-color: black;}
  		${Title} {color: white;}
  		
	  	&:hover {
			${Title} {color: white;}
		}	  		
  	}
  }
  @media (min-width: 992px) {
    > a {
      background-color: ${({ hasDesktopPcs }) => hasDesktopPcs ? 'rgba(1, 1, 1, 0.2)' : '#ffffff'};
    }
  }
`;

const Stats = styled.span`
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  align-items: center;
  white-space: nowrap;
  color: #8b8b8b;
  z-index: 4;

  > span {
    display: inline-block;
    font-size: 11px;
    margin-left: 10px;
    vertical-align: middle;
    img {
      width: 10px;
      display: inline-block;
      margin-top: -1px;
    }
  }
`;

const ImageWrapper = styled.span`
  display: block;
  position: relative;
  margin-left: -1px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  ${Stats} {
    color: white;
  }
`;

const EmbedElement = styled.span`
  display: block;
  position: static;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .pot--embed--facebook {
    height: auto;
    min-height: 165px;
    padding: 0 15px;
    background: black;
    text-align: center;

  }

  .part--youtube-video,
  .onnetwork-video
   {
    margin: 0;
    padding-top: 0;
    padding-bottom: 54%;
    background-color: black;
   }
  @media (min-width: 992px) {
  ${props => props.size == 'lg' && ({
    position: 'static',
  })};
    background-color: black;
    .pot--embed--facebook,
    .part--youtube-video{
      height: auto;
    }
    .pot--embed-onnetwork{
      /* position: absolute; */
    }
  }
`;

const Partner = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0px 8px;
  background-color: #D8D8D8;
	color: #8B8B8B;
  line-height: 16px;
  font-size: 11px;
  z-index: 4;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  left: 30px;
  top: 30px;
  padding: 0px 10px;
  background: ${props => props.theme.mainColor};
  color: white;
  line-height: 22px;
  font-size: 15px;
  font-weight: 500;
  z-index: 5;
`;

const Player = styled.span`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 120px;
  height: 120px;
  background: transparent url(${PlayIcon}) no-repeat center center;
  background-size: 105px auto;
  z-index: 5;
`;

const Tag = styled.div`
  display: block;
  color: #272424;
  font-size: 13px;
  padding-top: 8px;
  padding-left: 25px;

  @media (min-width: 992px) {
    font-size: 12px; /* 12px jest mniejsze po prawej: układ 4/1/2 PhotoOnTop , wcześniej 14px; dwa duże PhotoOnTop */
    padding-top: 10px;
    padding-left: 25px;

    ${props => props.size == 'lg' && ({
    paddingLeft: '45px'
  })};

  }
  /* 
   padding-left: 25px;
   gdy PhotoOnTop jest mniejsze po prawej: układ 4/1/2
  */
`;

const Footer = styled.div`
  display: block;
  /* justify-content: space-between; */
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-bottom: 14px;
  margin-left: 15px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #eee;
    margin-right: 12px;
  }
`;
const AuthorName = styled.div`
  font-size: 12px;
  color: #000;
`;


export default PhotoOnTop;
