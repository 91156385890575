import { observable, action, computed } from "mobx";
import fetch from "cross-fetch";
import openIDB from "../IDB";
import { pushDataLayer } from "../helpers/pushDataLayer"

const dbPromise = openIDB();


class CategoryStore {
  constructor(RootStore) {
    this.RootStore = RootStore;
  }
  @observable lastModification = null;
  @observable status = null;
  message = null;

  fetchedCategory = { category: { type: "natemat" } };
  currentSlug = null;

  @computed
  get blocks() {
    if (this.fetchedCategory)
      return this.fetchedCategory.category.wizard;
    else
      return [];
  }

  @computed
  get categoryName() {
    if (this.fetchedCategory)
      return this.fetchedCategory.category.name;
    else
      return "";
  }

  @action('Change store status')
  setStatus = (status, message = null) => {
    this.message = message;
    this.status = status;
  }

  @action("Display Category")
  displayCategory = category => {
    // console.log('[ES] CategoryStore::displayCategory');
    const { updateGoogleAdKeywords } = this.RootStore.UIStore;
    const keywordObj = {
      "url": [category.category.url],
      "category": [category.category.name],
      ...(typeof category.topics !== "undefined" && { "topics": category.topics })
    };
    updateGoogleAdKeywords({
      ...category?.page_parameters?.adKeywords,
      ...keywordObj
    });
    this.fetchedCategory = category;
    this.lastModification = category.category.last_modification;
    this.setStatus("valid");
  };

  @action("Get category based on current URL")
  updateSlug = (slug = "") => {
    const that = this;

    if (this.currentSlug !== slug) {
      // prawdopodobnie "wstecz", brak aktualizacji
      this.currentSlug = slug;
      this.setStatus("loading");
    }

    // console.log('[ES] CategoryStore.updateSlug', slug);

    //// RETURN FROM DB
    dbPromise
      .then(function (db) {
        const articleDbStore = db
          .transaction("categories")
          .objectStore("categories");
        return articleDbStore.get(slug);
      })
      .then(function (category) {
        if (category === undefined) {
          return Promise.Reject();
        }
        that.displayCategory(category);
      })

    this._fetchFromNetwork(slug, true);
  };

  _fetchFromNetwork = (slug, justUpdate = false) => {
    const getParams = (window.location.search || '?');
    const that = this,
      fetchUrl = `${window.API_HOST}/pwa-category/${slug}${getParams}`;

    fetch(fetchUrl)
      .then(response => {
        return response.ok
          ? response.json()
          : Promise.reject(response.status);
      })
      .then(res => {
        pushDataLayer({
          'event': 'virtualPageview',
          'virtualPageURL': res.category.OID === 0 ? "/" : `/${res.category.url}`,
          'virtualPageTitle': res.category.seo_title,
          'pageType': res.category.OID === 0 ? "Strona główna" : "Lista artykułów"
        })
        that.displayCategory(res);
        this._saveToDb(res, slug);
      })
      .catch(err => {
        window.APP_TRACK_ERROR("CategoryStore-fetchFromNetwork", { url: fetchUrl, error: err });

        if (err === 404) {
          that.setStatus("error", "Nie znaleziono takiej strony");
          return;
        } else if (justUpdate) {
          return;
        } else if (!window.navigator.onLine) {
          that.setStatus("error", "Brak połączenia z internetem. Nadal możesz korzystać z aplikacji, choć część treści, tak jak ta, będzie niedostępna");
          return;
        } else {
          that.setStatus("error", "Ups! Wystąpił problem podczas pobierania strony. Sprawdź połączenie z internetem i spróbuj ponownie");
          return;
        }
      });
  };

  _saveToDb = (networkResponse, slug) => {
    // console.log(networkResponse);
    dbPromise
      .then(function (db) {
        // console.log('[DB]', networkResponse)

        var tx = db.transaction("categories", "readwrite");
        var categoriesDbStore = tx.objectStore("categories");
        // console.log('[CAT]', categoriesDbStore)
        categoriesDbStore.put(networkResponse, slug);
        return tx.complete;
      })
      .then(function () {
        console.log(`Updated "${slug}" in categories`);
      });
  };
}

export default CategoryStore;
