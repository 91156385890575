import * as React from "react"
import { toJS } from "mobx"

import { SlateArticleType } from "../../../../stores/ArticleStore"
import { AnyElement } from "./types"

import SlateBlocksBizon from "./SlateBlocksBizon"

export type SlateRendererProps = {
    article: SlateArticleType;
    parts: any[];
    hideAdslots?: boolean;
}

export type SlateElementOrLegacyElement = AnyElement | { type: "post-title" | "author-date" | "lead" | "social_icons" | "youtube" | "media_links" | "podcast_header" | "adslot" | "onnetwork" | "similar_articles" | "poll" | "quiz" | "cta-button"; data: any }

const SlateRenderer: React.FC<SlateRendererProps> = (props) => (
    <SlateBlocksBizon {...props} />
)

export default SlateRenderer