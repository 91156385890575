import * as React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

const RestrictedRoute: React.FC<RouteProps> = (props) => {
    const [{ isAuthed }] = useAuth();

    if (isAuthed === null) {
        return null
    } else if (!isAuthed) {
        return <Route {...props} />
    } else {
        return <Redirect to='/' />
    }
}

export default RestrictedRoute