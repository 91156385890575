import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

import { Author } from "./BridAuthors";
import Html from "../../Html";
import { h4Strong, bodyLarge, body } from "../../../../../redesign/commonStyles/Fonts";

import * as S from "../common/styles/Styles";
import { styled } from "../../../../../stitches.config";

import ArrowIcon from "../../../../../redesign/assets/icons/arrow-down-orange.svg"

const AuthorCardMobile: React.FC<{ author: Author }> = ({ author }) => {
    const [height, setHeight] = useState<number | string>(120);

    return (
        <StyledAnimate onClick={() => setHeight(height === 120 ? "auto" : 120)}>
            <AnimateHeight duration={500} height={height}>
                <UpperTile>
                    <S.CircleImg diameter={72} src={author.image} />
                    <div>
                        <h4 className={h4Strong()}>{author.name}</h4>
                        <span className={body()}>{author.profession}</span>
                    </div>
                    <Icon className={height === 120 ? "" : "arrow-active"}>
                        <img src={ArrowIcon} />
                    </Icon>
                </UpperTile>
                <Line />
                <p className={bodyLarge()}>{author.about}</p>
                <p className={body()}>Portfolio:</p>
                <Portfolio>
                    {author.portfolio
                        .filter((item) => item !== "")
                        .map((item, index) => (
                            <PortfolioLink key={index} html={item} />
                        ))}
                </Portfolio>
            </AnimateHeight>
        </StyledAnimate>
    );
};

const StyledAnimate = styled("div", {
    margin: "8px 0 0 0",
    padding: "0 24px",
    backgroundColor: "$grayscale10",
    "& p": {
        fontWeight: 600,
    },
    ".arrow-active": {
        transform: "rotate(180deg)",
    }
});

const Line = styled("div", {
    margin: "0 0 8px 0",
    width: "100%",
    height: "1px",
    backgroundColor: "$grayscale25",
});

const Icon = styled("div", {
    margin: " 0 16px 0 auto !important",
});

const UpperTile = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    padding: "24px 0",
    "& > div": {
        marginLeft: 20,
        h4: {
            margin: 0,
            color: "$grayscale100",
        },
        span: {
            color: "$grayscale75",
        },
    },
});

const PortfolioLink = styled(Html, {

});

const Portfolio = styled("div", {
    paddingBottom: "28px",
    "& div": {
        maxWidth: "100%",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "18px",
        marginBottom: "12px",
        fontFamily: "$grotesk",
    },
    "& a": {
        textDecoration: "underline",
        color: "$primary",
    }
});


export default AuthorCardMobile;
