import React from 'react';
import { styled } from "../stitches.config";
import { bodyLarge } from "./commonStyles/Fonts";

import { ReactComponent as FBIcon } from "../redesign/assets/icons/social/facebook-white.svg";

const CtaMamaduButton: React.FC = () => {

    return (
        <StyledCtaButton className={bodyLarge()}>
            <a data-analytics-name="article-cta-btn" href='https://www.facebook.com/groups/227390574536324'>
                <FBIcon /> Dołącz do zamkniętej grupy rodziców na Fb! Tu rozmawiamy i wygrywamy nagrody.
            </a>
        </StyledCtaButton>
    );

};

export default CtaMamaduButton;

const StyledCtaButton = styled('div', {
    "a": {
        width: "100%",
        margin: "0 auto 18px auto",
        color: "$grayscale0Fixed !important",
        backgroundColor: "$primary",
        border: "2px solid $primary",
        cursor: "pointer",
        padding: "7px 16px 9px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "$primary120",
            border: "2px solid $primary120",
        },
        "svg": {
            minWidth: "20px",
            marginRight: "1rem"
        }
    }
});