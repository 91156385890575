import React from "react";
/** @jsx jsx */ import { jsx, css } from "@emotion/core";

import { withlazyload } from "../../../HOCs/WithLazyLoad";

@withlazyload({
  height: 500,
  once: true,
  offset: 600
})
export default class YoutubeVideo extends React.Component {

  render() {
    return (
      <div
        css={css`
	        position: relative;
	        padding-bottom: 56.25%;
	        padding-top: 25px;
          margin: 20px 0;
          @media (min-width: 996px) {
            min-height: 474px;
          }
             
	      `}
      >
        <iframe
          title={"Youtube video" + this.props.dataId}
          css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            `}
          src={"https://www.youtube.com/embed/" + this.props.dataId}
          frameborder="0"
          allow="autoplay; encrypted-media" allowfullscreen>
        </iframe>
        {this.props.description ?
          (<span className="image-gallery-description">{this.props.description}</span>)
          : null}
      </div>
    );
  }
}

