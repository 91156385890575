import React from 'react';
import { styled } from "../../stitches.config"

import PartnershipInfo from "../PartnershipInfo"
import Author from '../Author';

interface ArticleAuthorBottomProps {
    article: ArticleAuthor;
    partnership?: string;
    isPodcast?: boolean;
}

const ArticleAuthorBottom: React.FC<ArticleAuthorBottomProps> = ({
    article,
    partnership = "",
    isPodcast,
}) => {
    const isMobile = window.innerWidth >= 768;

    return (
        <>
            {article?.author?.url ?
                <StyledArticleAuthorBottom>
                    < div className={isMobile ? "container" : "full-width"} >
                        <div className="col-md-8 author-bottom-semi">
                            <Wrapper>
                                <Author
                                    isPodcast={isPodcast}
                                    article={article}
                                    authorSize={"big"}
                                    border={true}
                                    description={article?.author?.articleDescription}
                                    link={article?.author?.url} />
                            </Wrapper>
                            {!isPodcast && <>{partnership && <PartnershipInfo partner={partnership} />}</>}
                        </div>
                    </div >
                </StyledArticleAuthorBottom >
                :
                null
            }
        </>
    );
};

const StyledArticleAuthorBottom = styled('div', {
    width: "100%",
    marginTop: "40px",
    "@bp4": {
        marginTop: "68px",
    },
    "& .col-md-8": {
        padding: 0,
    },
    "& .full-width": {
        width: "100%",
        margin: 0,
    },
    "& .podcastAuthor": {
        backgroundColor: "$grayscale10 !important",
    }
})


const Wrapper = styled('div', {
    marginBottom: "8px"
})

export default ArticleAuthorBottom