import React from "react";
import TrackingCodeParser from "../../helpers/TrackingCodeParser"
import Breadcrumbs from "../../redesign/components/Breadcrumbs";
import styled from "@emotion/styled";
import SlateRenderer from "./Parts/Slate/SlateRenderer";
import Parts from "./Parts/Parts";
import { withThemeContext } from "../../redesign/components/StitchesThemeProvider";

import AtavistBottomAuthors from "../../redesign/components/AtavistBottomAuthors";


class Header extends React.Component {
  componentDidMount() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.setAttribute("src", "/vendor/Hyphenator.js");

    document.getElementsByTagName("body")[0].appendChild(s);
  }


  render() {

    const { article, isDarkMode } = this.props;
    const isBreadcrumbsInverted = !article.semi;
    const parts = (article && article?.TitlePart ? article.TitlePart : []);
    const isSemi = article?.semi;
    const isDesktop = window.innerWidth >= 996;
    const isRichArticle = article?.display_type === 'RICH_ARTICLE';
    const isLive = article?.display_type === "LIVE";
    const isBrid = article?.display_type === "BRID";

    return (
      <Section isDarkMode={isDarkMode} isBrid={isBrid} isRichArticle={isRichArticle} isLive={isLive} isRichArticle={isRichArticle} isSemi={isSemi} className={!isRichArticle ? "" : "row"}>
        <div class={!isRichArticle && !isBrid && "container"}>
          <SectionWrapper isDarkMode={isDarkMode} className={!isRichArticle ? "article-column-content" : "col-lg-8 article-column-content"}>
            <TrackingCodeParser trackingCode={article?.BrandingAnalyticsCode} />

            {!isRichArticle && !isBrid && <Breadcrumbs
              category={{
                name: article?.category?.name,
                link: article?.category?.url,
              }}
              invert={isBreadcrumbsInverted}
              allWhite={isSemi}
            />}
            <div className={isSemi ? " article-type-semi" : ""}>
              {["ARTICLE_SLATE", "ARTICLE_VIDEO", "ARTICLE_PODCAST", "LIVE"].includes(article?.display_type) ? (
                <SlateRenderer
                  article={article}
                  parts={article?.TitlePart}
                  hideAdslots={this.props.hideAdslots}
                />
              ) : (
                <Parts titlePart parts={parts} article={article} hideAdslots={this.props.hideAdslots} />
              )}
              {isRichArticle && <AtavistBottomAuthors article={article} />}
            </div>
          </SectionWrapper>
        </div>
        {!isRichArticle && !isBrid && <div className="dots">
          <svg
            width="100"
            height="350"
            viewBox="0 0 104 363"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M207.994 101.582C208.218 121.987 202.281 141.995 190.94 159.054C179.599 176.113 163.369 189.449 144.32 197.36C125.271 205.272 104.267 207.4 83.9874 203.473C63.7083 199.546 45.0735 189.743 30.4609 175.313C15.8483 160.884 5.92034 142.483 1.94372 122.458C-2.0329 102.433 0.122152 81.6922 8.13391 62.8819C16.1457 44.0716 29.6509 28.0446 46.9264 16.846C64.2018 5.64729 84.4643 -0.215365 105.128 0.0060478C132.199 0.66871 157.975 11.5831 177.122 30.4906C196.27 49.398 207.323 74.851 207.994 101.582ZM207.994 360.189C208.218 380.595 202.281 400.603 190.94 417.662C179.599 434.721 163.369 448.057 144.32 455.968C125.271 463.879 104.267 466.007 83.9874 462.081C63.7083 458.154 45.0735 448.35 30.4609 433.921C15.8483 419.491 5.92034 401.09 1.94372 381.065C-2.0329 361.041 0.122152 340.3 8.13391 321.49C16.1457 302.679 29.6509 286.652 46.9264 275.454C64.2018 264.255 84.4643 258.392 105.128 258.614C132.199 259.276 157.975 270.191 177.122 289.098C196.27 308.006 207.323 333.459 207.994 360.189Z" />
          </svg>
        </div>}
      </Section>
    );
  }
}

export const Section = styled.section`
    background-color:${(props) => props.isSemi ? "#131212" : "#FFF"};
    background-color:${(props) => props.isRichArticle && "#F5F5F5"};
    background-color:${(props) => props.isBrid && "#E5E5E5"};
    background-color:${(props) => props.isDarkMode && "#131212"};
    padding-top:${(props) => props.isRichArticle ? "0" : "24px"};
    padding-bottom:${(props) => props.isSemi ? "80px" : null};
    position: relative;
    @media (min-width: 996px) {
      padding-top:${(props) => props.isRichArticle ? "0" : "32px"};
      padding-bottom:${(props) => props.isRichArticle ? "0" : "80px"};
      padding-bottom:${(props) => props.isLive && "56px"};
      padding-bottom:${(props) => props.isBrid && "0"};
      .article-column-content {
        margin:${(props) => props.isSemi ? "0 auto" : null};
      }
    }
    @media (min-width: 1310px) {
      .article-column-content {
        width:${(props) => props.isRichArticle ? "100%" : "840px"};
      }
    }

    .container {
       display: flex;
       justify-content: center;
       @media (min-width: 1310px) {
         display: block;
       }
    }

  .dots {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 350px;
    width: 100px;
    display: none;
    svg {
      fill: ${({ isDarkMode, theme }) => isDarkMode ? "#2b2a2a" : theme.mainColor};
      fill: ${(props) => props.isSemi ? "#2b2a2a" : null};
    }
    @media (min-width: 1310px) {
      /* nie 996px ponieważ zasłania treść przy tapecie */
      display: block;
    }
  }
`;


export const SectionWrapper = styled.div`
  h1 {
    color:${(props) => props.isDarkMode ? "#fff" : "#131212"};
  }
  p {
    color:${(props) => props.isDarkMode ? "#E7E7E7" : "#131212"};
  }
  span {
    color:${(props) => props.isDarkMode && "#8D8D8D"};
  }
  .article-type-semi {
      color: white !important;
      div,
      p,
      a,
      h1 {
        color: white !important;
      }
      .author-date {
        .social-btn-icon {
          background-color: ${props => props.theme.mainColor};
        }
      }
  }
`;

export default withThemeContext(Header);