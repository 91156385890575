import React from "react";
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core";
import { observer, inject } from "mobx-react";

@inject("UIStore")
@observer
export default class Loader extends React.Component {
  render() {
    const { UIStore } = this.props;
    return (
      <LoaderWrapper>
        <AnimatedLogo src={UIStore.appTheme.logoSimple} />
      </LoaderWrapper>
    );
  }
}

const LoaderWrapper = styled.div`
  height: 100vh;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
`;

const AnimatedLogo = styled.img`
  height: 100px;
  margin-bottom: 25px;
  box-shadow: 9px 9px 16px 1px rgba(0, 0, 0, 0.15);
  opacity: 1;
  animation: ${fadeInOut} 1.5s ease-in-out infinite;
`;
