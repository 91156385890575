import { PaymentParams, PaymentStatus, PaymentType } from "../types/Payment";
import PaymentModal from "./payment/PaymentModal";

type PaymentFields = {
  type: ModalType.PAYMENT;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  paymentEditorId?: number;
  paymentBridId?: number;
};

export enum ModalType {
  PAYMENT = "PAYMENT",
}
export type Params = PaymentParams;
export type ModalFields = PaymentFields;

type Config = {
  [key in keyof typeof ModalType]: {
    modalType: ModalType;
    params: Params[];
    component: () => JSX.Element;
    requireParams: {
      [key in keyof typeof PaymentType]?: PaymentParams[];
    };
  };
};

const MODAL_CONFIG: Config = {
  [ModalType.PAYMENT]: {
    modalType: ModalType.PAYMENT,
    params: Object.values(PaymentParams),
    component: PaymentModal,
    requireParams: {
      TIP: [
        PaymentParams.PAYMENT_EDITOR_ID,
        PaymentParams.PAYMENT_STATUS,
        PaymentParams.PAYMENT_TYPE,
      ],
      BRID: [
        PaymentParams.PAYMENT_BRID_ID,
        PaymentParams.PAYMENT_STATUS,
        PaymentParams.PAYMENT_TYPE,
      ],
    },
  },
};

export default MODAL_CONFIG;
