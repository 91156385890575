import { Formik } from "formik";
import React from "react";
import Link from "../../../components/Link";
import useModal from "../../../helpers/useModal";
import { styled } from "../../../stitches.config";
import Author from "../../Author";
import { bodyLarge, bodyLargeStrong, h3 } from "../../commonStyles/Fonts";
import CustomLink from "../../Link";
import { PaymentStatus } from "../../types/Payment";
import * as Yup from "yup";
import { withThemeContext } from "../../components/StitchesThemeProvider";

interface Props {
  onPayButtonClick: (amount: number, email: string, message?: string) => void;
  isDarkMode: boolean;
}

const defaultAmounts = [
  {
    amountText: "2 zł",
    amountValue: 2,
  },
  {
    amountText: "5 zł",
    amountValue: 5,
  },
  {
    amountText: "10 zł",
    amountValue: 10,
  },
  {
    amountText: "Inne",
  },
];

const SuccessTipModal = ({ authorId }: { authorId: number }) => {
  return (
    <SuccessTipWrapper>
      <h3 className={h3()}>
        Dzięki za napiwek! Cieszę się, że podoba Ci się moja praca i że ją
        doceniasz{" "}
      </h3>
      <p>Tutaj wpadnie widget autora o id: {authorId}</p>'
    </SuccessTipWrapper>
  );
};

type TipFromValues = {
  email: string;
  message: string;
  amount: number;
  privacyPolicy: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Pole wymagane")
    .email("Wprowadź poprawny adres e-mail"),
  amount: Yup.number().required("Pole wymagane").min(1, "Pole wymagane"),
  message: Yup.string(),
  privacyPolicy: Yup.boolean()
    .required("Musisz zaakceptować warunki")
    .oneOf([true], "Musisz zaakceptować warunki."),
});

const TipModal: React.FC<Props> = ({ onPayButtonClick, isDarkMode }) => {
  const { modalData } = useModal();
  const [selectedAmountText, setSelectedAmountText] = React.useState<string>();

  const initialValues: TipFromValues = {
    email: "",
    message: "",
    amount: 0,
    privacyPolicy: false,
  };

  const isOtherValue = selectedAmountText === "Inne";

  const isSuccess = modalData?.paymentStatus === PaymentStatus.SUCCESS;
  if (isSuccess && modalData?.paymentEditorId) {
    return (
      <Wrapper>
        <SuccessTipModal authorId={modalData?.paymentEditorId} />
      </Wrapper>
    );
  }

  const onSubmit = ({
    email,
    amount,
    message,
    privacyPolicy,
  }: TipFromValues) => {
    onPayButtonClick(amount, email, message);
  };
  return (
    <Wrapper>
      <TextColumn>
        <h3 className={h3()}>
          Spodobał Ci się artykuł?
          <br />
          Zostaw mały napiwek za ciekawy artykuł!
        </h3>
        <p className={bodyLarge()}>
          Cała kwota zostanie przelana na konto autora. Wraz z napiwkiem możesz
          przekazać autorowi wiadomość.
        </p>
        <CustomLink text="Sprawdź jak to działa" url="#" size="monochromatic" />
      </TextColumn>
      <TipColumn>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <p className={bodyLargeStrong()}>Napiwek</p>
              <AmountWrapper>
                {defaultAmounts.map(({ amountText, amountValue }, index) => {
                  return (
                    <AmountButton
                      key={index}
                      type="button"
                      isDarkMode={isDarkMode}
                      selected={selectedAmountText === amountText}
                      onClick={() => {
                        setSelectedAmountText(amountText);
                        amountValue && setFieldValue("amount", amountValue);
                      }}
                    >
                      {amountText}
                    </AmountButton>
                  );
                })}
              </AmountWrapper>
              {isOtherValue && (
                <>
                  <p className={bodyLargeStrong()}>Podaj kwotę</p>
                  <Input
                    type="number"
                    name="amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                    isDarkMode={isDarkMode}
                  />
                </>
              )}
              <Error>{errors.amount && touched.amount && errors.amount}</Error>
              <p className={bodyLargeStrong()}>
                Wiadomość do autora (opcjonalnie)
              </p>
              <Textarea
                cols={4}
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                isDarkMode={isDarkMode}
              />
              <p className={bodyLargeStrong()}>Adres e-mail</p>
              <Input
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isDarkMode={isDarkMode}
              />
              <Error>{errors.email && touched.email && errors.email}</Error>
              <CheckboxWrapper>
                <Input
                  type="checkbox"
                  className={bodyLarge()}
                  name="privacyPolicy"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.privacyPolicy}
                />
                <span>
                  Akceptuje&nbsp;
                  <Link to="/info/regulamin">regulamin</Link> i{" "}
                  <Link to="/info/polityka-prywatnosci">
                    politykę prywatności
                  </Link>
                </span>
              </CheckboxWrapper>
              <Error>
                {errors.privacyPolicy &&
                  touched.privacyPolicy &&
                  errors.privacyPolicy}
              </Error>
              <PayButton type="submit" className={bodyLarge()}>
                Zostaw napiwek przez przelewy24
              </PayButton>
            </form>
          )}
        </Formik>
      </TipColumn>
    </Wrapper>
  );
};

export default withThemeContext(TipModal);

const Wrapper = styled("div", {
  paddingTop: "78px",
  paddingBottom: "31px",
  paddingLeft: "12px",
  paddingRight: "12px",
  "@bp4": {
    display: "flex",
    justifyContent: "space-between",
    gap: "72px",
    alignItems: "flex-start",
    paddingTop: "78px",
    paddingBottom: "31px",
    paddingLeft: "84px",
    paddingRight: "84px",
  },
});

const TextColumn = styled("div", {
  h3: {
    margin: 0,
  },
  p: {
    fontWeight: "400",
    marginBottom: "32px",
    marginTop: "16px",
    maxWidth: "454px",
  },
});

const TipColumn = styled("div", {
  "p:first-of-type": {
    marginTop: "32px",
  },
  "@bp4": {
    "p:first-of-type": {
      marginTop: 0,
    },
  },
});

const AmountWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
});

const AmountButton = styled("button", {
  fontSize: 16,
  lineHeight: "24px",
  letterSpacing: "0px",
  fontWeight: "600",
  padding: "12px 22px",
  border: "2px solid rgba(0, 0, 0, 0.05)",
  color: "$grayscale0",
  background: "$grayscale100",
  variants: {
    selected: {
      true: {
        border: "2px solid $grayscale90",
        color: "$grayscale100",
        background: "$grayscale0",
      },
    },
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Textarea = styled("textarea", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  resize: "none",
  width: "100%",
  height: "100px",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Input = styled("input", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  width: "100%",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const CheckboxWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  marginTop: "16px",
  gap: 10,
  a: {
    color: "$grayscale0",
  },
  input: {
    width: "auto",
    transform: "translateY(30%)",
  },
});

const PayButton = styled("button", {
  background: "$grayscale0",
  color: "$grayscale100",
  textAlign: "center",
  border: "none",
  width: "100%",
  padding: "12px 0",
  marginTop: "16px",
});

const SuccessTipWrapper = styled("div", {
  paddingLeft: "180px",
  paddingRight: "180px",
  textAlign: "center",
  h3: {
    maxWidth: "528px",
  },
});

const Error = styled("span", {
  color: "$aszdziennikPrimary",
  margin: "0 0 10px 0",
  display: "block",
});
