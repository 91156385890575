import './PodcastPlayerNav.module.scss';

import React, { useState, useEffect, useMemo } from 'react';

import { usePodcastPlayer } from '../../../context/PodcastPlayerContext';

import PodcastPoster from './PodcastPoster';
import PodcastPlayerVolume from './PodcastPlayerVolume';
import PodcastNextEpisodeButton from './PodcastNextEpisodeButton';

import PodcastSkipTime from './PodcastSkipTime';

import { ReactComponent as PauseIcon } from "../../../assets/img/podcastPlayer/podcast_pause.svg";
import { ReactComponent as PlayIcon } from "../../../assets/img/podcastPlayer/podcast_play.svg";
import { ReactComponent as ReplayIcon } from "../../../assets/img/podcastPlayer/podcast_replay.svg";

import { ReactComponent as NextIcon } from "../../../assets/img/podcastPlayer/podcast_next.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/podcastPlayer/podcast_close.svg";

interface Props {
    sid: string;
}

const PodcastPlayerNav: React.FC<Props> = ({ }) => {

    const { sid, isPlay, isCompleted, play, pause, duration, progress, volume, setVolume, setMedia, seek,
        podcastPoster, podcastTitle, podcastSeries, nextEpisode, prevEpisode, setEpisode } = usePodcastPlayer();

    const progressDiff = useMemo(() => duration - progress, [progress, duration]);

    return (
        <div className="podcastPlayerNav" id="podcastPlayerNav">
            <div className="podcastPlayerNav__wrapper">
                <PodcastPoster {...podcastPoster} />
                <div className="podcastPlayerNav__titleWrapper">
                    {podcastSeries && (
                        <p className="podcastPlayerNav__series">
                            {podcastSeries}
                        </p>
                    )}
                    <p className="podcastPlayerNav__title">
                        {podcastTitle}
                    </p>
                </div>
                <div className="podcastPlayerNav__controls">
                    {prevEpisode && <button className="podcastPlayerNav__controls__button podcastPlayerNav__controls__button--prev" onClick={() => setEpisode(prevEpisode)}>
                        <NextIcon />
                    </button>}
                    <PlayButton
                        isPlay={isPlay}
                        isCompleted={isCompleted}
                        duration={duration}
                        progress={progress}
                        onClick={() => (!isPlay || isCompleted) ? play() : pause()}
                    />
                    {nextEpisode && <button className="podcastPlayerNav__controls__button" onClick={() => setEpisode(nextEpisode)}>
                        <NextIcon />
                    </button>}
                </div>
                <PodcastSkipTime
                    duration={duration}
                    progress={progress}
                    sid={sid}
                />
                {((progressDiff > 10 && !isCompleted)) && <PodcastPlayerVolume volume={volume} setVolume={setVolume} />}
                {(progressDiff <= 10 || isCompleted) && nextEpisode && <PodcastNextEpisodeButton onClick={() => setEpisode(nextEpisode)} />}
                <button className="podcastPlayerNav__close" onClick={() => setMedia(undefined)}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    )

};

export default PodcastPlayerNav;

interface PlayButtonProps {
    isPlay: boolean;
    isCompleted: boolean;
    duration: number;
    progress: number;
    onClick: () => void;
}

const PlayButton: React.FC<PlayButtonProps> = ({ isPlay, isCompleted, duration, progress, onClick }) => {

    const [pogressPercent, setProgress] = useState<number>(0);

    useEffect(() => {
        setProgress(progress / duration * 100);
    }, [progress, duration]);

    return (
        <button className={`podcastPlayerNav__controls__play ${isPlay ? 'podcastPlayerNav__controls__play--played' : ''}`} onClick={onClick}>
            <svg viewBox="0 0 36 36" className="podcastPlayerNav__progress">
                <path
                    className="podcastPlayerNav__progress__bg"
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                {pogressPercent && (
                    <path
                        className="podcastPlayerNav__progress__actual" strokeDasharray={`${pogressPercent}, 100`}
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                )}
            </svg>
            {!isPlay && !isCompleted && <PlayIcon style={{ transform: 'translateX(12.5%)' }} />}
            {isPlay && <PauseIcon />}
            {isCompleted && <ReplayIcon />}
        </button>
    )

}