import React, { Component, Suspense, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import { useIndexedDB } from 'react-indexed-db';

import Loader from "../components/Loader";
import Error from "../components/Error";

import axios from "axios";

// podstawowy artykuł, więc bez lazy loadingu
import ArticleNormalBizon from "../components/ArticleNormalBizon";


const ArticleRich = React.lazy(() => import(/* webpackChunkName: "components__ArticleRich" */ "../components/ArticleRich"))
const ArticleBrid = React.lazy(() => import(/* webpackChunkName: "components__ArticleBrid" */ "../components/ArticleBrid"))
const ArticleHardcover = React.lazy(() => import(/* webpackChunkName: "components__ArticleHardcover" */ "../components/ArticleHardcover"))
// const ArticleNormalBizon = React.lazy(() => import(/* webpackChunkName: "components__ArticleNormalBizon" */ "../components/ArticleNormalBizon")) - bez lazy, bo to podstawowy scenariusz
const AdultGate = React.lazy(() => import(/* webpackChunkName: "components__AdultGate" */ '../components/AdultGate'))

const DBWrapper = ({ children, article }) => {
    const [visitedCategories, setVisitedCategories] = useState([]);
    const { add: addCategory, update, getAll } = useIndexedDB("visitedCategories");
    const { add } = useIndexedDB('visit');

    const addArticleToStore = () => {
        add({
            articleId: article.OID,
            categoryId: article.category.OID,
            datetime: new Date()
        }).then(
            event => {
                console.log('ID Generated: ', event?.target?.result);
            },
            error => {
                console.log(error);
            }
        );
    }

    const addCategoryToStore = () => {
        const existedVisit = visitedCategories.find(
            ({ categoryId }) => categoryId === article.category.OID
        );
        if (existedVisit) {
            update({
                id: existedVisit.id,
                categoryId: article.category.OID,
                count: existedVisit.count + 1,
            }).then((event) => {
                console.log('record update')
            });
        } else {
            // console.log('addCategoryToStore', existedVisit, article.category.OID);
            addCategory({
                categoryId: article.category.OID,
                count: 1,
            }).then((event) => {
                console.log("ID Generated: ", event.target.result);
            });
        }
    }



    useEffect(() => {
        try {
            addArticleToStore()
            getAll().then((data) => setVisitedCategories(data));
        } catch (error) {
            console.log('addArticleToStore ERROR', error);
        }
    }, [])

    useEffect(() => {
        try {
            addCategoryToStore()
        } catch (error) {
            console.log('addCategoryToStore ERROR', error);
        }
    }, [visitedCategories]);

    return <>{children}</>
}

// const StaticArticle = () => {
//     const post_serialized = Object.values(window.APP_SETTINGS?.post_serialized)[0];
//     if(!post_serialized) return null;
//     return <HeaderWrapper>
//                 <Header article={{TitlePart: [
//                     {
//                         "type": "post-title",
//                         "data": post_serialized.title
//                     },
//                     {
//                         "type": "lead",
//                         "data": post_serialized.lead
//                     }
//                 ], ...post_serialized}} />
//             </HeaderWrapper>
// }
@withRouter
@inject("ArticleStore", "UIStore", "AdsStore")
@observer
class Article extends Component {
    componentWillMount(_) {
        this.props.ArticleStore.updateSlug(this.props.match.params.slug);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.slug !== this.props.match.params.slug) {
            this.props.ArticleStore.updateSlug(nextProps.match.params.slug);
        }
    }


    componentWillUnmount() {
        const { UIStore } = this.props;
        UIStore.setNav('MAIN');
        this.articleIDs = [];
    }

    render() {
        const { UIStore, ArticleStore, AdsStore } = this.props;
        UIStore.setCurrentAction(['article']);
        
        /*
         * @codereview
         * brzydko, powinno być przeniesione do komponentu, wtedy chyba żadne komponenty nie potrzebują UIStorem tylko go injectujesz do tego komponentu
         *
         * @codereview
         * spłaszyłbym header, content i footer żeby były tutaj - powinno wtedy elegancko wyglądać
         */
        if (UIStore.UIStatus.status === "loading")
            // return <StaticArticle/>
            return <Loader/>
        if (UIStore.UIStatus.status === "error")
            return <Error error={this.props.UIStore.UIStatus.message} />;



        // pilnuje zeby byl prawidlowy adres
        var articleAddress = document.createElement('a');
        articleAddress.href = ArticleStore.article.url;
        if (window.location.pathname != articleAddress.pathname) {
            window.history.replaceState(null, null, articleAddress.pathname);
        }

        if (ArticleStore.article) {
            // RICH ARTICLE / ATAVIST
            if (ArticleStore.article.display_type === 'RICH_ARTICLE') {
                UIStore.setNav('SIMPLE');
                return <DBWrapper article={ArticleStore.article}>
                    <Suspense fallback={null}>
                        {ArticleStore.article.adultGate && <AdultGate />}
                        <ArticleRich article={ArticleStore.article} />
                    </Suspense>
                </DBWrapper>
            }

            //BRID 
            if (ArticleStore.article.display_type === 'BRID') {
                return (
                    <DBWrapper article={ArticleStore.article}>
                        <Suspense fallback={null}>
                            <ArticleBrid />
                        </Suspense>
                    </DBWrapper>)
            }

            if (ArticleStore.article.display_type === 'HARDCOVER') {
                UIStore.setNav('HARDCOVER');
                return (
                    <DBWrapper article={ArticleStore.article}>
                        <Suspense fallback={null}>
                            {ArticleStore.article.adultGate && <AdultGate />}
                            <ArticleHardcover />
                        </Suspense>
                    </DBWrapper>
                )
            }

            if (ArticleStore.article.display_type === 'ARTICLE' && UIStore.nav !== "AUDIO") {
                UIStore.setNav('MAIN');
                /*    UIStore.setNav('ARTICLE'); */
                /*.   nie zmienia się - artykuł dalej zostaje MAIN */
            }
        }

        return (
            <DBWrapper article={ArticleStore.article}>
                <Suspense fallback={null}>
                    <ArticleNormalBizon />
                </Suspense>
            </DBWrapper>
        )
    }
};

export const ArticleWrapper = styled.article`
  background-color: white;
`;

export const ArticleRow = styled.div`
  @media (min-width: 992px) {
    display: table;
    width: 100%;
  }
`;

export const ArticleColumn = styled.div`
  @media (min-width: 992px) {
    float: none;
    display: table-cell;
    vertical-align: top;
    position: relative;
    max-width: 780px;
  }
`;

export default Article;