import React from 'react'
import styled from "@emotion/styled"

import { ImageCaption } from './BackgroundImage';
import { withDrawingBoard } from "../../HOCs/withDrawingBoard"


const Image = ({ blockData, ...props }) => {
    const srcUrl = blockData.url.replace("0,0,0,0", "700,0,0,0")
    return (<BizonImageWrapper>
        <img src={srcUrl} alt="" onLoad={() => props.updateHeight()} />
        {blockData.imageCaption !== "" && <ImageCaption className="image-caption">{blockData.imageCaption}</ImageCaption>}
    </BizonImageWrapper>
    )
}

const BizonImageWrapper = styled.div`
width: 100%;
margin: 40px auto 32px auto;
img{
    width: 100%;
    height: auto;
}
@media (min-width: 996px){
    width: 840px;
    margin: 88px auto 80px auto;
}
.image-caption {
    margin-top: 8px;
    font-style: normal;
    text-align: center;
    color: #8d8d8d;
    font-size: 14px;
    line-height: 18px;
    font-family: "HK Grotesk";
    font-size: bold;
    @media (min-width: 996px){
        margin-top: 12px;
    }
}
`


export default withDrawingBoard(props => props.blockData.hasCanvas)(Image);