import React, { useState } from 'react'
import styled from "@emotion/styled"
import { CSSTransition } from 'react-transition-group'

import buttonSvg from '../../../assets/download_button.svg'

const DownloadButton = ({ blockData }) => {
    const { downloadLink } = blockData;
    const [click, setClick] = useState(false);

    // const sizedUrl = blockData.url.replace("0,0,0,0.jpg", "600,0,0,0.jpg");

    const handleClick = () => {
        setClick(true);

        setTimeout(() => {
            setClick(false)
        }, 5000);
    };

    return (<Wrapper>
        <Download
            onClick={handleClick}
            href={downloadLink}
            download>
            <span>POBIERZ RAPORT</span>
            <img src={buttonSvg} />
        </Download>
        <CSSTransition
            in={click}
            timeout={1000}
            classNames="thankyou"
            mountonEnter
            unmountOnExit
        >
            <div>
                <span>Dziękujemy za pobranie raportu.</span>
                {/*<img src={sizedUrl} />*/}
            </div>
        </CSSTransition>
    </Wrapper>)
}

const Wrapper = styled.div`
    position:relative;
    & > div{
        z-index: 3;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 600px;
        height: 300px;
        background-color: #f60;
        
        /* img{
            max-width: 100%;
            max-height: 100%;
        } */

        span{
            position: absolute;
            top: 40px;
            left: 55px;
            color: #fff;
            font-weight: 600;
            font-size: 5.4vw;
            width: 52%;
        }
        
    &.thankyou-enter {
    	opacity: 0;
        transform: scale(0.9);
  	}
  	&.thankyou-enter-active {
    	opacity: 1;
        transform: scale(1);
        transition: opacity 300ms, transform 300ms;
  	}
  	&.thankyou-exit {
    	opacity: 1;
        transform: scale(1);
  	}
  	&.thankyou-exit-active {
  		opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
  	}
    @media (min-width: 992px){
        span{
           font-size: 44px;
        }
    }
  }
`

const Download = styled.a`
    display: flex;
    flex-direction: column;
    padding: 20px;
    letter-spacing: 2px;
    width: 270px;
    background-color: #f60;
    border-radius: 25px;
    border: 2px solid black;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    &, &:hover, &:active, &:visited, &:focus{
        color: #fff;
        text-decoration: none;
    }
    span{
        text-align: center;
        
    }`

export default DownloadButton