import React from 'react';
import styled from "@emotion/styled";
import { Parallax, Background } from 'react-parallax';
import LazyLoad from "react-lazyload";

import Html from './Html';
import KulkiSvgExtra from '../../common/KulkiSvgExtra';

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"
// bizon
import { useStores } from '../../../helpers';


const BackgroundImage = ({ blockData, type, ...props }) => {
    const { ArticleStore } = useStores();
    const { isParallax, hasOverlay = false, hasTextShadow = false, backgroundTextTop, backgroundTextBottom, backgroundUrl = "", author, category, imageCaption } = blockData;
    const isAuthor = !!author;
    const srcUrl = backgroundUrl.replace("0,0,0,0", '1200,0,0,0')
    const video = (<>
        <video muted loop autoPlay playsInline onLoadedData={() => props.updateHeight()}>
            <source src={backgroundUrl} type="video/mp4" />
        </video>
        <div className="overlay" />
    </>)

    if (type === 'background_video') {
        return <LazyLoad once={true} offset={600} height={500}>
            <Wrapper primary={isAuthor}>
                <BizonBackgroundTextTop hasTextShadow={hasTextShadow}>
                    <Html html={backgroundTextTop} />
                </BizonBackgroundTextTop>
                <BgWrapper primary={isAuthor} parallax={isParallax} hasOverlay={hasOverlay}>
                    <WithParallax parallax={isParallax} strength={window.innerWidth > 996 ? 700 : 100}>
                        {isParallax ? <Background>
                            {video}
                        </Background> : video}
                        <VidWrapper parallax={isParallax}>

                            <BizonBackgroundVideoTextBottom hasTextShadow={hasTextShadow}>
                                <Html html={backgroundTextBottom} />
                            </BizonBackgroundVideoTextBottom>
                        </VidWrapper>
                    </WithParallax>
                </BgWrapper>
            </Wrapper>
        </LazyLoad>
    }
    return <Wrapper primary={isAuthor}>
        <BizonBackgroundTextTop hasTextShadow={hasTextShadow}>
            <Html html={backgroundTextTop} />
        </BizonBackgroundTextTop>
        <BgWrapper primary={isAuthor} hasOverlay={hasOverlay}>
            <WithParallax parallax={isParallax} backgroundUrl={srcUrl} strength={500}>
                <BackgroundImg primary={isAuthor} src={srcUrl} parallax={isParallax} hasOverlay={hasOverlay}>
                    <BackgroundGradient className="rich-article-background-img" primary={isAuthor} />
                    <BizonBackgroundTextBottom hasTextShadow={hasTextShadow}>
                        {backgroundTextBottom && <Category>{ArticleStore.fetchedArticle.article.category.name}</Category>}
                        <Html html={backgroundTextBottom} />
                    </BizonBackgroundTextBottom>
                </BackgroundImg>
            </WithParallax>
            {imageCaption !== "" && <BizonImageCaption>{imageCaption}</BizonImageCaption>}
        </BgWrapper>
    </Wrapper>
}

const WithParallax = ({ parallax, backgroundUrl, strength = 100, children }) => {
    return parallax ? <Parallax
        strength={strength}
        bgImage={backgroundUrl}
    >
        {children}
    </Parallax>
        : <div style={{ overflow: "hidden" }}>
            {children}
        </div>

}

const VidWrapper = styled.div`
    ${props => props.parallax && `min-height: 150px`};
     @media (min-width: 992px) {
          ${props => props.parallax && `min-height: 500px`};
     }
`
const Category = styled.div`
    padding: 2px 4px 2px 18px;
    color: white;
    background-color: ${props => props.theme.mainColor};
    font-family: HK Grotesk !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    line-height: 16px !important;
    letter-spacing: 0.8px !important;
    margin-bottom: 12px !important;
    max-width: fit-content;
    position: relative;
    @media (min-width: 996px) {
        padding: 4px 8px 4px 18px;
        margin-bottom: 16px !important;
    }

    &:after {
        position: absolute;
        left: 7.5px;
        top: 6px;
        background-color: white;
        border-radius: 5px;
        width: 5px;
        height: 5px;
        content: " ";
    }
    &:before {
        position: absolute;
        left: 7.5px;
        top: 13.5px;
        background-color: white;
        border-radius: 5px;
        width: 5px;
        height: 5px;
        content: " ";
    }
`
const BgWrapper = styled.div`
    position: relative;
    video{
        width: 100vw;
        height: auto;
        transform: scale(1.05);
    }
    video ~ .overlay{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            background-color: black;
            opacity: ${({ hasOverlay }) => hasOverlay ? 0.4 : 0};;
    }
    .react-parallax-content{
        ${({ hasOverlay }) => hasOverlay && 'background-color: rgba(0, 0, 0, 0.4)'};
    }
     @media (min-width: 992px) {
        video{
            width: 100vw;
            height: auto;
            transform: scale(1);
            
        }

        video ~ .overlay {
            bottom: 6px;
        }
     }
`
const Wrapper = styled.div`
    position: relative;
    margin: ${props => props.primary ? '0 0 30px 0' : '30px 0'};
    br{
        display: none;
    }
    
    @media (min-width: 992px) {
        margin: ${props => props.primary ? '0 0 75px 0' : '75px 0'};
        br{
            display: inline;
        }
    }
`

export const ImageCaption = styled.p`
    /* position: absolute; */
    margin: 3px 10px;
    font-size: 10px;
    text-align: left;
    font-style: italic;
    color: #8b8b8b;
`
export const BizonImageCaption = styled.p`
    margin-top: 8px;
    font-style: normal;
    text-align: center;
    color: #8d8d8d;
    font-size: 14px;
    line-height: 18px;
    font-family: "HK Grotesk";
    font-size: bold;
    @media (min-width: 996px){
        margin-top: 12px;
    }
`

const BackgroundImg = styled.div`
    position: relative;
    width: 100%;
    background: ${props => props.hasOverlay && `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),`} ${props => !props.parallax && `url(${props.src}) center no-repeat`};
    background-size: cover;
    padding-top: ${props => props.primary ? '92vh' : '55%'};
    
    @media (min-width: 992px){
        padding-top: ${props => props.primary ? '100vh' : '55%'};
    }
`

const BackgroundGradient = styled.div`
    position: absolute;
    width: 100%;
    background: ${props => props.primary && `linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.85))`};
    background-size: cover;
    padding-top: ${props => props.primary ? '92vh' : '55%'};
    top: 0; 

    @media (min-width: 992px){
        padding-top: ${props => props.primary ? '100vh' : '55%'};
    }
`

const BackgroundText = styled.div`
    left: 15%;
    margin: 0;
    z-index: 2;
    letter-spacing: -0.6px;
    text-shadow: ${props => props.hasTextShadow && '-1px -1px #000'};
     a {
        color: rgb(255, 102, 0) !important;
        &:hover{
            text-decoration: none;
            }
    }
    
    span, .cdx-color{
        color: #ffffff;
    }
`

const BizonBackgroundTextTop = styled(BackgroundText)`
    position: absolute;
    left: 26px;
    top: 15%;

    & > div{
        font-weight: 700;
        color: #131212;
        background: white;
        font-family: "HK Grotesk";
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        display: inline-block;
        @media (min-width: 996px) {
        font-size: 54px;
        line-height: 72px;
        letter-spacing: -0.5px;
        }
    }

    span, .cdx-color{
        color: #131212;
        background: white;
    }

    @media (min-width: 996px) {
        left: 86px;
        width: 70%;
    }
`

const BizonBackgroundTextBottom = styled(BackgroundText)`
    position: absolute;
    left: 26px;
    right: 0;
    margin: 0;
    bottom: 24px;

    & > div{
        top: 0;
        width: 100%;
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        font-family: "HK Grotesk";
        font-weight: 700;

        @media (min-width: 996px) {
            font-size: 54px;
            line-height: 72px;
            letter-spacing: -0.5px;
        }
    }
    
    span, .cdx-color{
        color: #131212;
        background: white;
    }

    @media (min-width: 996px) {
        left: 86px;
        bottom: 88px;
        width: 70%;
        min-height: 20%;
    }
`

const BizonBackgroundVideoTextBottom = styled(BackgroundText)`
    margin-top: 24px;
    padding: 0 38px;
    @media (min-width: 996px) {
        margin-top: 0px;
        padding: 0px;
        position: absolute;
        left: 86px;
        bottom: 88px;
        width: 70%;
        min-height: 20%;
    }
    & > div{
        top: 0;
        width: 100%;
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        font-family: "HK Grotesk";
        font-weight: 700;

        @media (min-width: 996px) {
            font-size: 54px;
            line-height: 72px;
            letter-spacing: -0.5px;
        }
    }
    
    span, .cdx-color{
        color: #131212;
        @media (min-width: 996px) {
            background: white;
        }
    }
`

export default withDrawingBoard(props => props.blockData.hasCanvas)(BackgroundImage);