import React from "react"
import styled from "@emotion/styled"
import PhotoOnTop from "../../common/PhotoOnTop"
import TextOnPicture from "../../../redesign/components/TextOnPicture";

const RelatedArticle = ({ article }) => {
    return <>
        <Title>Czytaj także<span>:</span></Title>
        <TextOnPicture article={article} />
    </>
}

const Title = styled.b`
    span {
        color: ${props => props.theme.mainColor}
    }
`

export default RelatedArticle