import React, { Suspense } from "react";
import { styled } from "../../stitches.config";
import { bodyLarge, body } from "../commonStyles/Fonts";
import emotionStyled from "@emotion/styled";
import CopyIcon from "../assets/icons/copy-icon.svg";

interface CopyButtonProps {
    text: string;
    shareButton?: boolean;
}

const CopyButton: React.FC<CopyButtonProps> = ({
    text,
    shareButton = false,
}) => {
    return (
        <WrappedCopyButton className={bodyLarge()}>
            <Suspense fallback={null}>
                {shareButton ?
                    <>
                        <button onClick={() => { navigator.clipboard.writeText(text) }} className={body()}>
                            <svg className="svg-copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 0.666687H0.666626V11.3334H4.66663V15.3334H15.3333V4.66669H11.3333V0.666687ZM9.99996 6.00002V4.66669L9.99996 2.00002H1.99996V10L4.66663 10H5.99996L9.99996 10L9.99996 6.00002ZM5.99996 11.3334H11.3333V6.00002H14V14H5.99996V11.3334Z" />
                            </svg>
                            Kopiuj link
                        </button>
                    </>
                    :
                    <>
                        {text}
                        <button onClick={() => { navigator.clipboard.writeText(text) }}>
                            <svg className="svg-copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 0.666687H0.666626V11.3334H4.66663V15.3334H15.3333V4.66669H11.3333V0.666687ZM9.99996 6.00002V4.66669L9.99996 2.00002H1.99996V10L4.66663 10H5.99996L9.99996 10L9.99996 6.00002ZM5.99996 11.3334H11.3333V6.00002H14V14H5.99996V11.3334Z" />
                            </svg>
                        </button>
                    </>
                }
            </Suspense>
        </WrappedCopyButton>
    );
};

const WrappedCopyButton = styled('div', {
    "button": {
        width: "16px",
        height: "17px",
        borderWidth: "0px",
        padding: 0,
        marginLeft: "8px",
        backgroundColor: "transparent",
        "@bp4": {
            width: "14px",
            height: "14px",
        },
        ".svg-copy-icon": {
            fill: "$grayscale100",
        },
    }
})



export default CopyButton