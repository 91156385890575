import React, { useState, useEffect } from "react";
import { styled } from '../../../stitches.config';

interface Props {
    img?: string;
    img_scheme?: string;
}

const PodcastPoster: React.FC<Props> = ({ img, img_scheme }) => {

    const [isLoaded, setLoad] = useState<boolean>(false);

    const [src, setSrc] = useState<string | undefined>(undefined);

    useEffect(() => {
        console.log(img, img_scheme);
        if (!img_scheme)
            setSrc(img);
        else {
            let schemeSrc = img_scheme.replace('%WDIDTH%', '64').replace('%HEIGHT%,0', '64,1');
            setSrc(schemeSrc);
        }
    }, [img, img_scheme]);

    useEffect(() => {
        if (!src) return;

        setLoad(false);
        let img = new Image();
        img.onload = function () {
            setLoad(true);
        }
        img.src = src;
    }, [src]);

    return (
        <div className="podcastPlayerNav__poster">
            {src && isLoaded && <img src={src} width="64px" height="64px" alt="Podcast poster" />}
        </div>
    );

};

export default PodcastPoster;