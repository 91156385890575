import React from "react";
import { styled } from "../stitches.config";
import { body, bodyLarge } from "./commonStyles/Fonts";
import Avatar from "./atoms/Avatar";
import Link from "../components/Link";

interface AuthorAtavistProps {
    article: ArticleAuthor;
    border?: boolean;
    authorSize?: "extrasmall" | "small" | "normal" | "big";
}

export const AuthorAtavist: React.FC<AuthorAtavistProps> = ({
    article,
    border = false,
    authorSize = "normal",
}) => {
    const { author } = article;
    return (

        <AuthorAtavistWrapper>
            <Link to={article.author?.url}>
                {article.author?.img && (
                    <Avatar
                        src={article.author?.img}
                        url={article.author?.url || ""}
                        border={border}
                        size={authorSize}
                    />
                )}
                <p className={bodyLarge()}>{author?.name} </p>
                <span className={body()}>{author?.profession} </span>
            </Link>
        </AuthorAtavistWrapper>

    );
};



const AuthorAtavistWrapper = styled("div", {
    margin: "10px",
    "@bp4": {
        margin: 0,
    },
    "a": {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    "& .avatar": {
        flexShrink: 0,
        marginRight: "0 !important",
        marginBottom: "16px",
        width: "72px",
        height: "72px",
        borderRadius: "72px",
        "@bp4": {
            width: "96px",
            height: "96px",
            borderRadius: "96px",
        },
    },
    "span": {
        color: "$grayscale75",
        "&:hover": {
            textDecoration: "none",
        }
    },
    "p": {
        color: "$grayscale100Fixed",
    }
});

export default AuthorAtavist;
