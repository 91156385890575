import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import axios from "axios";

import PodcastPlayerNav from '../redesign/components/PodcastPlayer/PodcastPlayerNav';
import { useHistory } from "react-router-dom";

interface IOnnTvData {
    iid: string;
    sender: string;
    comm: 'playerLoaded' | 'movieset' | 'moviestarted' | 'movieresumed' | 'moviestopped' | 'moviepaused' | 'movie_progress' | 'moviecompleted' | 'movieseeking' | 'volumeset' | 'adnotfoundinblock' | 'adblockfplayed' | 'adnotpresent' | 'adprogress';
    subject?: number;
    videoId: number;
    videoDuration: number;
    videoTitle: string;
    videoPoster: string;
    volume: number;
    containerId: string;
};

interface PlayerIid {
    [key: string]: IPodcastPlayerData | null;
}

interface IPodcastPoster {
    img?: string;
    img_scheme?: string;
}

export interface IPodcastPlayerData {
    podcast_article?: string;
    sid?: string;
    iid?: string;
    duration?: number;
    progress?: number;
    podcastPoster?: IPodcastPoster;
    podcastTitle?: string;
    podcastSeries?: string;
    unload?: boolean;
    nextEpisode?: string,
    prevEpisode?: string,
}

export interface IPodcastPlayerContext {
    sid?: string;
    iid?: string;
    isPlay: boolean;
    isCompleted: boolean;
    duration: number;
    progress: number;
    volume: number;
    play: (sid?: string, progress?: number) => void,
    pause: () => void,
    seek: (progress: number, sid?: string) => void,
    setMedia: (sid?: string) => void,
    setVolume: (volume: number) => void,
    podcastPoster: IPodcastPoster;
    podcastTitle: string,
    podcastSeries?: string,
    newMedia?: string;
    nextEpisode?: string,
    prevEpisode?: string,
    setEpisode: (episodeId: string) => void
};

export type PodcastContextVariableType = keyof IPodcastPlayerContext;

const PodcastPlayerContext = createContext<IPodcastPlayerContext>({
    sid: undefined,
    iid: undefined,
    isPlay: false,
    isCompleted: false,
    duration: 0,
    progress: 0,
    volume: parseFloat(window.localStorage.getItem('podcastVolume') || '1'),
    play: () => { },
    pause: () => { },
    seek: (progress: number, sid?: string) => { },
    setMedia: (sid?: string) => { },
    setVolume: (volume: number) => { },
    podcastPoster: {},
    podcastTitle: '',
    newMedia: undefined,
    nextEpisode: undefined,
    prevEpisode: undefined,
    setEpisode: (episodeId: string) => { }
});

// Save state from context variable, and change only for passed sid
export function usePlayerState<T>(variableName: PodcastContextVariableType, podcastContext: IPodcastPlayerContext, sid?: string) {
    const variable = podcastContext[variableName];
    const [state, setState] = useState<typeof variable | null>(null);

    useEffect(() => {
        if (!sid || podcastContext.sid != sid) return;
        setState(variable);
    }, [variable]);

    return state as unknown as T;
}

// Use PodcastPlayerContext
export const usePodcastPlayer = (): IPodcastPlayerContext => {
    const context = useContext(PodcastPlayerContext);
    if (context === undefined) {
        throw new Error("usePodcastPlayer must be used within PodcastPlayerContext");
    }
    return context;
};

// Load Player via sid, and get status of init
export const usePlayerInit = (podcast_article: string): [string | undefined, boolean] => {
    const { newMedia } = useContext(PodcastPlayerContext);

    //Load podcast on component create
    const [sid, setSid] = useState<string | undefined>(undefined);
    useEffect(() => {
        loadPodcastArticle(podcast_article).then(sid => {
            setSid(sid);
        });
        return () => unLoadPodcast(podcast_article);
    }, []);

    const [init, setInit] = useState<boolean>(false);
    //Check load whenever new media appeared
    useEffect(() => {
        if (!sid) return;
        if (newMedia == undefined || !PODCASTS_PLAYERS[sid]?.iid || init) return;
        setInit(true);
    }, [newMedia, sid]);

    return [sid, init];
};

// Map saves informations about players (sid -> playerData)
export const PODCASTS_PLAYERS: PlayerIid = {};
let PODCAST_ACTIVE_SID: string | undefined = undefined;

// Load podcast article data
const loadPodcastArticle = async (podcast_article: string) => {
    for (let sid in PODCASTS_PLAYERS)
        if (PODCASTS_PLAYERS[sid]?.podcast_article == podcast_article) {
            PODCASTS_PLAYERS[sid] = {
                ...PODCASTS_PLAYERS[sid],
                unload: false
            };
            return sid;
        }

    const { data } = await axios.get(`${window.API_HOST}/pwa-podcast/${podcast_article}`);
    const podcastData = data.podcast;

    PODCASTS_PLAYERS[podcastData.sid] = {
        ...PODCASTS_PLAYERS[podcastData.sid],
        iid: undefined,
        podcast_article,
        sid: podcastData.sid,
        podcastTitle: podcastData.episode_title,
        podcastPoster: podcastData.episode_poster,
        podcastSeries: podcastData.episode_series,
        nextEpisode: podcastData.next_episode,
        prevEpisode: podcastData.prev_episode,
    };

    addPlayerScript(podcastData.sid);

    return podcastData.sid;
}

// Unload player hook
const unLoadPodcast = (podcast_article: string) => {
    for (let sid in PODCASTS_PLAYERS)
        if (PODCASTS_PLAYERS[sid]?.podcast_article == podcast_article) {
            PODCASTS_PLAYERS[sid] = {
                ...PODCASTS_PLAYERS[sid],
                unload: true
            };

            if (PODCAST_ACTIVE_SID != sid)
                removePlayerScript(sid);
        }
}

// Add player scripts
const addPlayerScript = (playerSid: string) => {
    const playerContainer: HTMLDivElement = document.createElement('div');
    playerContainer.id = `podcast_player_${playerSid}`;
    playerContainer.style.cssText = 'display: none;';
    document.body.appendChild(playerContainer);

    const script: HTMLScriptElement = document.createElement('script');
    script.id = `podcast_script_${playerSid}`
    script.async = true;
    script.src = `https://video.onnetwork.tv/embed.php?sid=${playerSid}&cId=podcast_player_${playerSid}&mplon=${1}&autoplay=0`;
    document.body.appendChild(script);
};

// Remove player scripts
const removePlayerScript = (playerSid: string) => {
    const playerScript = document.getElementById(`podcast_script_${playerSid}`);
    if (playerScript)
        document.body.removeChild(playerScript);

    const playerContainer = document.getElementById(`podcast_player_${playerSid}`);
    if (playerContainer)
        document.body.removeChild(playerContainer);

    delete PODCASTS_PLAYERS[playerSid];
};

// PodcastPlayerContext provider
const PodcastPlayerProvider: React.FC<React.ReactNode> = ({ children }) => {

    const history = useHistory();

    const [sid, setSid] = useState<string | undefined>(undefined);
    const iid = useRef<string | undefined>(undefined);

    const [newMedia, setNewMedia] = useState<string | undefined>(undefined);

    const [isPlay, setPlay] = useState<boolean>(false);
    const [isCompleted, setCompleted] = useState<boolean>(false);

    const [progress, setAudioProgress] = useState<number>(0);
    const [duration, setAudioDuration] = useState<number>(0);
    const [volume, setCurrentVolume] = useState<number>(parseFloat(window.localStorage.getItem('podcastVolume') || '1'));

    const [nextEpisode, setNextEpisode] = useState<string | undefined>(undefined);
    const [prevEpisode, setPrevEpisode] = useState<string | undefined>(undefined);

    //Dynamic update podcastValue in localStorage
    useEffect(() => window.localStorage.setItem('podcastVolume', String(volume)), [volume]);

    const [podcastPoster, setPodcastPoster] = useState<IPodcastPoster>({});
    const [podcastTitle, setPodcastTitle] = useState<string>('');
    const [podcastSeries, setPodcastSeries] = useState<string>('');

    //Update player volume on play or volume change
    useEffect(() => {
        if (!isPlay) return;
        sendMediaCommand('setvolume', String(Math.min(Math.max(volume, 0), 1)));
    }, [isPlay, volume]);

    // Ingore cause we dont know ONTVsendCommand type
    // @ts-ignore
    const sendMediaCommand = (message: string, value?: string) => iid && window.ONTVsendCommand(message, iid.current, value);

    // Get metadata from player
    const receiveMediaInfo = (e: MessageEvent<any>) => {

        //Check if message is from onnetwork player
        if (e.origin !== 'https://video.onnetwork.tv')
            return;
        if (typeof e.data !== 'string')
            return;
        if (!e.data.startsWith('onntv://'))
            return;

        //Parse onnetwork message
        try {
            const data: IOnnTvData = JSON.parse(e.data.substr(8));

            console.log("PODCAST-PLAYER", data);

            if (data.comm == 'movieset') {
                let movieSsid = data.containerId.split('podcast_player_')[1] || document.getElementById(data.containerId)?.parentElement?.id.split('podcast_player_')[1] || null;
                if (movieSsid) {
                    PODCASTS_PLAYERS[movieSsid] = {
                        ...PODCASTS_PLAYERS[movieSsid],
                        iid: data.iid,
                        duration: data.videoDuration,
                        progress: 0,
                        //Currently get from axios response
                        //podcastPoster: data.videoPoster,
                        //podcastTitle: data.videoTitle,
                        unload: false
                    };
                    setNewMedia(data.iid);
                }
            }

            if (data.iid !== iid.current) return;

            switch (data.comm) {
                case 'moviestarted':
                case 'movieresumed':
                    setCompleted(false);
                    setPlay(true);
                    break;

                case 'moviestopped':
                case 'moviepaused':
                    setPlay(false);
                    break;

                case 'movie_progress':
                    setAudioProgress(data.subject || 0);
                    break;

                case 'moviecompleted':
                    setPlay(false);
                    setCompleted(true);
                    break;

                case 'adnotfoundinblock': //Próba odworzenia - nie ma rekalmy
                case 'adblockfplayed': //Poszedł cały blok reklamowy
                case 'adnotpresent': //Poszło x reklam
                    console.log('Po reklamie');
                    break;

                case 'adprogress': //Leci reklama
                    console.log('Ad Play');
                    break;
            }

        } catch (error) {
            console.log('PodcastPlayer: ' + error);
        }
    };

    useEffect(() => { setNewMedia(undefined) }, [newMedia]);

    // Wait for messages from player
    useEffect(() => {
        window.addEventListener('message', receiveMediaInfo);
        return () => window.removeEventListener('message', receiveMediaInfo);
    }, []);

    useEffect(() => {
        PODCAST_ACTIVE_SID = sid;
        if (!sid) {
            setNewMedia(undefined);
            setPlay(false);
            setCompleted(false);
            setAudioProgress(0);
            setAudioDuration(0);
            setPodcastPoster({});
            setPodcastTitle('');
            setPodcastSeries('');
            setNextEpisode(undefined);
            setPrevEpisode(undefined);
            return;
        }

        const playerData = PODCASTS_PLAYERS[sid];
        if (playerData) {
            iid.current = playerData.iid || undefined;
            if (iid.current) {
                setAudioProgress(0);
                setAudioDuration(playerData.duration || 0);
                setCompleted(false);
                setPodcastPoster(playerData.podcastPoster || {});
                setPodcastTitle(playerData.podcastTitle || '');
                setPodcastSeries(playerData.podcastSeries || '');
                setNextEpisode(playerData.nextEpisode);
                setPrevEpisode(playerData.prevEpisode);
            }
        }
    }, [sid]);

    // Autoplay on init (player loaded and iid set), and remove when uloaded
    useEffect(() => {
        if (!iid.current) {
            if (!sid) return;
            if (PODCASTS_PLAYERS[sid]?.unload)
                return removePlayerScript(sid);
        }
        play();
    }, [iid.current]);

    //Play podcast
    const play = (playerSid?: string, playerProgress?: number) => {
        if (playerSid && playerSid != sid) {
            pause();
            setSid(playerSid);
        } else
            sendMediaCommand('play');
        sendMediaCommand('setvolume', String(Math.min(Math.max(volume, 0), 1)));
    }

    //Pause podcast
    const pause = () => {
        sendMediaCommand('pause');
    }

    //Go to ..
    const seek = (playerProgress: number, playerSid?: string,) => {
        if (playerSid == sid) {
            sendMediaCommand('seek', String(playerProgress))
        } else {
            pause();
            play(playerSid);
            seek(playerProgress);
        }
    }

    //Set podcast
    const setMedia = (sid?: string) => {
        pause();
        iid.current = undefined;

        setPlay(false);
        setCompleted(false);
        //Wait for state apply
        setTimeout(() => setSid(sid), 100);
    }

    //Set volume
    const setVolume = (volume: number) => {
        setCurrentVolume(volume);
    }

    //Change episode
    const setEpisode = (episodeId?: string) => {
        if (episodeId)
            history.push(`/${episodeId}`);
    }

    return (
        <PodcastPlayerContext.Provider value={{
            sid, iid: iid.current, isPlay, isCompleted, progress, duration, volume,
            play, pause, seek, setMedia, setVolume,
            podcastPoster, podcastTitle, podcastSeries,
            newMedia,
            nextEpisode, prevEpisode, setEpisode
        }}>
            {children}
            {sid && <PodcastPlayerNav sid={sid} />}
        </PodcastPlayerContext.Provider>
    );

};

export default PodcastPlayerProvider;

export const formatTime = (time: number, showHours?: boolean): string => {

    let parts: number[] | string[] = [time, 0, 0];

    for (let i = 1; i < parts.length; i++) {
        parts[i] += parts[i - 1] / 60;
        parts[i - 1] %= 60;
    }

    parts = parts.reverse().map(p => String(Math.floor(p)).padStart(2, '0'));

    if (!showHours && parts[0])
        parts.shift();

    return parts.join(':');

}