import React from 'react';

import { usePodcastPlayer } from "../../../context/PodcastPlayerContext";

import { ReactComponent as Next15Icon } from "../../../assets/img/podcastPlayer/podcast_next_15.svg";
import { ReactComponent as Prev15Icon } from "../../../assets/img/podcastPlayer/podcast_prev_15.svg";

interface Props {
    duration: number;
    progress: number;
    sid?: string;
};

const PodcastSkipTime: React.FC<Props> = ({ duration, progress, sid }) => {

    const { seek } = usePodcastPlayer();

    if (!sid) return <></>;

    return (
        <div className="podcastPlayerNav__skipTime">
            <button onClick={() => seek(Math.max((progress - 15), 0), sid)}>
                <Prev15Icon />
            </button>
            <button onClick={() => seek(Math.min((progress + 15), duration), sid)}>
                <Next15Icon />
            </button>
        </div>
    );

};

export default PodcastSkipTime;