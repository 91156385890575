import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "../../stitches.config";
import * as Yup from "yup";

import zenBackground from "../assets/images/zen-form-background.png";
import zenBackgroundPhone from "../assets/images/Twoje-naTemat.jpeg";
import CVVImage from "../assets/images/CVV.png";
import CloseIcon from "../assets/icons/close-cross-black-icon.svg";
import SuccessIcon from "../assets/icons/success-icon.svg";
import InfoIcon from "../assets/icons/info.svg";

import Logo from "../atoms/Logo";
import { body, bodyLarge, h3 } from "../commonStyles/Fonts";
import Button from "../atoms/Button";
import { Field, Form, Formik } from "formik";
import Link from "../Link";
import { useAuth } from "../../context/AuthContext";

export enum ZenModalStep {
  WELCOME = "WELCOME",
  CARD_DETAILS = "CARD_DETAILS",
  SUCCESS = "SUCCESS",
}

interface PaymentValue {
  cardNumber: number | string;
  exDate: string;
  code: number | string;
}

const ZenModal: React.FC<{ step: ZenModalStep, onCloseModal?: any }> = ({ step, onCloseModal }) => {
  const history = useHistory();
  const [{ user }] = useAuth();
  // const [step, setStep] = useState<Steps>(Steps.WELCOME);
  // const [isCVVinfo, setCVVinfo] = useState(false);

  // const initialValues: PaymentValue = {
  //   cardNumber: "",
  //   exDate: "",
  //   code: "",
  // };
  // const validationSchema = Yup.object().shape({
  //   cardNumber: Yup.number().required("Pole wymagane"),
  //   exDate: Yup.string().required("Pole wymagane"),
  //   code: Yup.string()
  //     .required("Pole wymagane")
  //     .matches(/^[0-9]+$/, "Musi zawierać tylko cyfry")
  //     .min(3, "Musi mieć dokładnie 3 cyfry")
  //     .max(3, "Musi mieć dokładnie 3 cyfry"),
  // });

  // const handleSubmit = ({ cardNumber, exDate, code }: PaymentValue) => {
  //   alert(JSON.stringify({ cardNumber, exDate, code }, null, 2));
  //   setStep(Steps.SUCCESS);
  // };
  const closeModal = (e: any) => {
    e.stopPropagation();
    if (onCloseModal) {
      onCloseModal();
    } else if (user && user.valid) {
      history.push('/dashboard')
    } else {
      history.push('/')
    }
  };

  const redirectToPayment = () => {
    if (window?.location) {
      window.location.href = "/zen-mode-payment/go";
    }
  };

  React.useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  const getWelcome = () => (
    <Card>
      <img className="background-img" src={zenBackgroundPhone} />
      <Details>
        <FlexBetween>
          <Logo
            css={{
              width: 112,
              height: 19,
            }}
          />
          <CloseButton onClick={closeModal}>
            <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7525 10.4142L19.5455 2.62119L17.707 0.782715L9.91401 8.57568L2.12119 0.782868L0.282715 2.62135L8.07553 10.4142L0.282715 18.207L2.12119 20.0455L9.91401 12.2526L17.707 20.0456L19.5455 18.2071L11.7525 10.4142ZM9.91401 8.99995L17.707 1.20698L17.707 1.20703L9.91406 9L9.91401 8.99995ZM2.12119 1.20713L2.12124 1.20718L0.707031 2.6214L0.706979 2.62135L2.12119 1.20713ZM8.4998 10.4142L0.706979 18.207L0.707031 18.207L8.49985 10.4142L8.4998 10.4142Z" />
            </svg>
          </CloseButton>
        </FlexBetween>
        <h3 className={h3()}>
          Opłać swoje konto <span>Twoje naTemat</span>,
          by pozbyć się reklam
        </h3>
        <p className={bodyLarge()}>
          Twoje naTemat pozwala korzystać z naTemat bez reklam - koszt wyłączenia reklam oraz korzystania z dodatkowych funkcjonalności, jak personalizacja serwisu, to tylko <strong>4,99 zł/miesięcznie</strong>.
          Możesz z niego zrezygnować w każdej chwili.
        </p>
        <Button
          size="big"
          text="Dalej"
          starPosition="none"
          onClick={redirectToPayment}
        />
      </Details>
    </Card>
  );

  // const getCardDetails = () => (
  //   <Card>
  //     <CardDetails>
  //       <FlexBetween>
  //         <h3 className={h3()}>Wprowadź dane karty, aby włączyć Twoje na:Temat</h3>
  //         <CloseButton onClick={closeModal}>
  //           <img src={CloseIcon} />
  //         </CloseButton>
  //       </FlexBetween>

  //       <p className={bodyLarge()}>
  //         Oglądaj treści bez reklam przez <strong>6 miesięcy za darmo.</strong>{" "}
  //         Po tym okresie koszt subskrypcji będzie wynosił tylko{" "}
  //         <strong>4,99 zł/miesięcznie.</strong> Możesz zrezygnować w każdej
  //         chwili.
  //       </p>
  //       <FormWrapper>
  //         <Formik
  //           initialValues={initialValues}
  //           validationSchema={validationSchema}
  //           onSubmit={handleSubmit}
  //         >
  //           {({ errors, touched }) => (
  //             <Form className={body()}>
  //               <label
  //                 className={`${
  //                   errors.cardNumber &&
  //                   touched.cardNumber &&
  //                   "show-error-input-text"
  //                 }`}
  //                 htmlFor="number"
  //               >
  //                 Wpisz numer karty
  //               </label>
  //               <Field
  //                 type="tel"
  //                 inputmode="numeric"
  //                 pattern="[0-9\s]{13,19}"
  //                 autocomplete="cc-number"
  //                 maxlength="19"
  //                 name="cardNumber"
  //                 className={`${bodyLarge()} ${
  //                   errors.cardNumber &&
  //                   touched.cardNumber &&
  //                   "show-error-input-border"
  //                 }`}
  //                 placeholder="np. 1234 5678 9000 0012"
  //               />
  //               {errors.cardNumber && touched.cardNumber && (
  //                 <span className="validationError">{errors.cardNumber}</span>
  //               )}
  //               <FlexBetween>
  //                 <FieldWrapper>
  //                   <label
  //                     className={`${
  //                       errors.exDate &&
  //                       touched.exDate &&
  //                       "show-error-input-text"
  //                     }`}
  //                     htmlFor="exDate"
  //                   >
  //                     Data ważności
  //                   </label>
  //                   <Field
  //                     type="text"
  //                     name="exDate"
  //                     className={`${bodyLarge()} ${
  //                       errors.exDate &&
  //                       touched.exDate &&
  //                       "show-error-input-border"
  //                     }`}
  //                     placeholder="MM/RR"
  //                     rows={5}
  //                   />
  //                   {errors.exDate && touched.exDate && (
  //                     <span className="validationError">{errors.exDate}</span>
  //                   )}
  //                 </FieldWrapper>
  //                 <FieldWrapper>
  //                   <label
  //                     className={`${
  //                       errors.code && touched.code && "show-error-input-text"
  //                     }`}
  //                     htmlFor="code"
  //                   >
  //                     Kod CVV2/CVC2
  //                     <img
  //                       className="info-icon"
  //                       src={InfoIcon}
  //                       onClick={() => setCVVinfo(true)}
  //                     />
  //                     {isCVVinfo && (
  //                       <InfoModal>
  //                         <FlexBetween>
  //                           <img src={CVVImage} />
  //                           <CloseButton onClick={() => setCVVinfo(false)}>
  //                             <img src={CloseIcon} />
  //                           </CloseButton>
  //                         </FlexBetween>
  //                         <p className={body()}>
  //                           Kod CVV2/CVC2 znajdziesz na odwrocie karty
  //                           płatniczej. Są to 3 cyfry.
  //                         </p>
  //                       </InfoModal>
  //                     )}
  //                   </label>
  //                   <Field
  //                     type="text"
  //                     name="code"
  //                     className={`${bodyLarge()} ${
  //                       errors.code && touched.code && "show-error-input-border"
  //                     }`}
  //                     placeholder="np. 123"
  //                     rows={5}
  //                   />
  //                   {errors.code && touched.code && (
  //                     <span className="validationError">{errors.code}</span>
  //                   )}
  //                 </FieldWrapper>
  //               </FlexBetween>
  //               <p className={bodyLarge()}>
  //                 Zapisując dane karty, akceptujesz{" "}
  //                 <Link url="/" text="Regulamin" />. Kartę możesz w każdej
  //                 chwili usunąć w Moim Koncie.
  //               </p>
  //               <Field
  //                 className={bodyLarge()}
  //                 type="submit"
  //                 value="Zapisz dane karty"
  //               />
  //             </Form>
  //           )}
  //         </Formik>
  //       </FormWrapper>
  //     </CardDetails>
  //   </Card>
  // );

  const getSuccessStep = () => (
    <Card>
      <SuccessDetails>
        <FlexBetween>
          <Logo
            css={{
              width: 112,
              height: 19,
            }}
          />
          <CloseButton onClick={closeModal}>
            <img src={CloseIcon} />
          </CloseButton>
        </FlexBetween>
        <CenterFlex>
          <SuccessImg src={SuccessIcon} />
        </CenterFlex>
        <h3 className={h3()}>
          Twoje na:Temat<span> zostało włączone!</span>
        </h3>
        <p className={bodyLarge()}>
          Możesz już korzystać z naTemat bez reklam - poczuj wolność, swobodę i
          święty spokój!
        </p>
        <Button
          size="big"
          text="Zamknij"
          starPosition="none"
          onClick={() => history.push('/dashboard')} // change to dashboard
        />
      </SuccessDetails>
    </Card>
  );

  return (
    <Wrapper>
      <>
        {step === ZenModalStep.WELCOME && getWelcome()}
        {step === ZenModalStep.SUCCESS && getSuccessStep()}
      </>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  position: "fixed",
  zIndex: "2000000",
  background: "rgba(0,0,0,0.75)",
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  left: 0,
  color: "$grayscale100",
});

const Card = styled("div", {
  background: "$grayscale0",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  opacity: "1",
  display: "flex",
  // height: "484px",
  fontStyle: "normal",
  img: {
    width: "408px",
    height: "454px",
    objectFit: "cover",
  },
  "@bp4": {
    top: "40%",
  },
  ".background-img": {
    display: "none",
    "@bp4": {
      display: "block",
    }
  }
});

const Details = styled("div", {
  padding: "32px",
  width: "408px",
  h3: {
    marginTop: "65px",
    span: {
      color: "$natematPrimary",
    },
  },
  p: {
    fontWeight: 500,
    strong: {
      fontWeight: 800,
    },
    paddingBottom: "14px",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const FlexBetween = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const CloseButton = styled("button", {
  border: "none",
  background: "none",
  width: "16px !important",
  height: "16px",
  padding: "0",
  lineHeight: 0,
  img: {
    width: "100%",
    height: "100%",
  },
  "svg": {
    fill: "$grayscale100",
    width: "16px",
    height: "16px",
  }
});

const CardDetails = styled("div", {
  padding: "32px",
  width: "408px",
  h3: {
    margin: 0,
    width: "85%",
  },
  p: {
    margin: "16px 0 0 0",
    fontWeight: 500,
    strong: {
      fontWeight: 800,
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const FormWrapper = styled("div", {
  width: "100%",
  "& form": {
    display: "flex",
    flexDirection: "column",
    "& label": {
      margin: "24px 0 4px 0",
    },
    "& input, & textarea": {
      resize: "none",
      border: "1px solid $grayscale25",
      fontWeight: "500",
      padding: "12px 14px",
      transition: "0.2s",
      caretColor: "$primary",
      "&::placeholder": {
        color: "$grayscale50",
      },
      "&:hover": {
        border: "1px solid $grayscale50",
      },
      "&:focus": {
        border: "1px solid $grayscale50",
        outline: "none",
      },
    },
    "& input[type = submit]": {
      color: "$grayscale0",
      borderWidth: "0px",
      background: "$natematPrimary",
      marginBottom: "20px",
      "@bp4": {
        marginTop: "28px",
      },
    },
  },
  "& .validationError": {
    color: "$aszdziennikPrimary",
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "8px",
  },
  ".show-error-input-border": {
    border: "1px solid $aszdziennikPrimary",
    "&:hover": {
      border: "1px solid $aszdziennikPrimary",
    },
    "&:focus": {
      border: "1px solid $aszdziennikPrimary",
    },
  },
  ".show-error-input-text": {
    color: "$aszdziennikPrimary",
  },
  ".show-error-checkbox-border": {
    "&::before": {
      border: "1px solid $aszdziennikPrimary !important",
    },
  },
});

const FieldWrapper = styled("div", {
  position: "relative",
  width: "160px",
  marginTop: "18px",
  input: {
    maxWidth: "100%",
    marginTop: "4px",
  },
  ".info-icon": {
    width: "16px",
    height: "16px",
    marginLeft: "4px",
    cursor: "pointer",
  },
});

const SuccessDetails = styled("div", {
  padding: "32px",
  width: "408px",
  h3: {
    textAlign: "center",
    span: {
      color: "#459B2B",
    },
  },
  p: {
    margin: "16px 0 32px 0",
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const SuccessImg = styled("img", {
  maxWidth: "96px",
  maxHeight: "96px",
});

const CenterFlex = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "32px 0 16px 0",
});

const InfoModal = styled("div", {
  position: "absolute",
  padding: "16px",
  background: "$grayscale0",
  width: "330px",
  bottom: "100%",
  right: 0,
  border: "1px solid $grayscale25",
  img: {
    width: "auto",
  },
});

export default ZenModal;
