import React from 'react';
import styled from "@emotion/styled";
import Html from './Html';

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"

// bizon
import quoteIcon from '../../../redesign/assets/icons/quote-icon.svg';
import quoteDotsIcon from "../../../redesign/assets/icons/quote-dots-icon.svg";

const Quote = ({ quoteData }) => {
    const { author, about, description } = quoteData;
    return (
        <QuoteBizonWrapper>
            <QuoteBizonBox>
                <StyledQuoteIcon></StyledQuoteIcon>
                <div className='quote'>
                    {author !== "" && <div className='quote-cite'><Html html={author} /></div>}
                    {about !== "" && <div className='quote-author'><Html html={about} /></div>}
                    {description !== "" && <div className='quote-about'><Html html={description} /></div>}
                </div>
            </QuoteBizonBox>
            <StyledQuoteDotsIcon></StyledQuoteDotsIcon>
        </QuoteBizonWrapper>
    );
}

const QuoteBizonBox = styled.div`
    max-width: 840px;
    margin: 0 auto;
    color: #FFF !important;
`

const QuoteBizonWrapper = styled.div`
    background: #131212;
    width: 100%;
    padding: 45px 64px 48px 24px;
    position: relative;
    margin: 40px 0 32px 0;

    @media (min-width: 992px) {
        padding: 105px 0px 120px 0px;
        margin: 88px 0 80px 0;
    }
    
    .quote{
        height: auto;
        display: flex;
        flex-direction: column;
        .quote-author{
            font-family: HK Grotesk;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            div {
                margin: 0;
                color: #FFFFFF !important;
            }
        }

        .quote-about{
            font-family: HK Grotesk;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: #8D8D8D;
            div {
                margin: 0;
                color: #FFFFFF !important;
            }
        }

        .quote-cite{
            font-family: HK Grotesk;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: #FFFFFF;
            @media (min-width: 992px) {
                font-size: 38px;
                line-height: 42px;
                font-weight: 700;
            }
            div {
                margin: 0 0 24px 0;
                color: #FFFFFF !important;
                @media (min-width: 992px) {
                    margin: 0 0 40px 0;
                }
            }
        }  
    }  
`

const StyledQuoteIcon = styled.div`
    width: 64px;
    height: 58px;
    background-image: url(${quoteIcon});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 30px 0;
     @media (min-width: 992px) {
        width: 88px;
        height: 82px;
        margin: 0 0 50px 0;
        background-size: 82px;
    }
`

const StyledQuoteDotsIcon = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 137px;
    flex-shrink: 0;
    background-image:  url(${quoteDotsIcon});
    background-size: cover;
    @media (min-width: 992px) {
        width: 80px;
        height: 278px;
    }
`

export default withDrawingBoard(props => props.quoteData.hasCanvas)(Quote);