import React, { Component, useEffect, useState } from "react";
import styled from "@emotion/styled";

import WithLazyLoad, { withlazyload } from "../../../HOCs/WithLazyLoad";
import AnimateHeightWrapper, { AnimateWrapperButton } from "../../Parts/Brid/common/components/AnimateHeightWrapper";

const TiktokEmbed = (props) => {

  const [visibility, setVisibility] = useState(false)
  const { profile, videoId } = props;
  const link = "https://www.tiktok.com/" + profile + "/video/" + videoId;

  useEffect(() => {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.setAttribute("src", "https://www.tiktok.com/embed.js");

    document.getElementsByTagName("body")[0].appendChild(s);
  }, [])

  const ref = (node) => {
    if (node !== null) {

      let interlvalId = setInterval(() => {
        let height = node.getBoundingClientRect().height
        console.log('[NN]', height)
        if (height != undefined && height > 400) {
          if (height > 450) {
            setVisibility(true);
          }

          clearInterval(interlvalId);
        }
      }, 1000);
    }
  };


  return (
    <WithLazyLoad options={{
      height: 300,
      once: true,
      offset: 500
    }}>
      <AnimateHeightWrapper type={AnimateWrapperButton.ShowMore} height={450} visibility={visibility}>
        <CenteredEmbed>
          <blockquote
            className="tiktok-embed"
            cite={link}
            data-video-id={videoId}
            style={{ "max-width": "605px", "min-width": "325px;" }}
            ref={ref}>
            <section>
              <a target="_blank" title={profile} href={"https:www.tiktok.com/" + profile}>{profile}</a>
            </section>
          </blockquote>
        </CenteredEmbed>
      </AnimateHeightWrapper>
    </WithLazyLoad>
  );
}

export default TiktokEmbed;




const CenteredEmbed = styled.div`
  text-align: center;
  margin: 20px 0px;
`;