import React from "react";
import styled from "@emotion/styled";

import TitleBizon from "../../../redesign/ArticleTitle"

export default class Title extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <TitleBizon children={title} />
    );
  }
}
