import React from "react";
import styled from "@emotion/styled";

import goldmedal from "../../../assets/img/Zloty_medal.svg"
import silvermedal from "../../../assets/img/Srebrny_medal.svg"
import bronzemedal from "../../../assets/img/Bronzowy_medal.svg"
import allmedals from "../../../assets/img/Wszystkie_medale.png"

export default class RankingIo extends React.Component {
  render() {
    const { medals } = this.props;
    console.log('medals', medals.items);
    return (
      <div>
        <Table>
          <tr>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th><img src={goldmedal} />złoto</th>
            <th><img src={silvermedal} />srebro</th>
            <th><img src={bronzemedal} />brąz</th>
            <th><img src={allmedals} />razem</th>
          </tr>
          {medals.items.map((elt, index) => {
            return <tr className={(index % 2 == 0 ? 'odd' : 'even')}>
              <td className="center">{elt.rank}. </td>
              <td className="padding">{elt.country_name}</td>
              <td className="center">{elt.gold}</td>
              <td className="center">{elt.silver}</td>
              <td className="center">{elt.brown}</td>
              <td className="center"><strong>{elt.total}</strong></td>
            </tr>;
          })}
        </Table>
      </div>
    );
  }
}

const Table = styled.table`
  width: 100%;
  th {
    padding-right: 3px;
  }
  img {
    max-width: 20px;
    margin-right: 3px;
  }
  tr.odd {
    td {
      background-color: #eee;
    }
  }
  tr {
    td {
      padding: 10px 0;
    }
  }
  .center {
    text-align: center;
  }
  .padding{ 
    @media (max-width: 992px) {
      padding-left: 4px;
    }
  }
  font-size: 18px;
  font-weight: lighter;
  line-height: 28px;
  color: #222222;
  position: relative;
  margin-bottom: 1em;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;
