import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { startPayment } from "../../../api/payment";
import useModal from "../../../helpers/useModal";
import { styled } from "../../../stitches.config";
import { PaymentParams, PaymentStatus, PaymentType } from "../../types/Payment";
import TipModal from "./TipModal";

const PaymentModal = () => {
  const { modalData } = useModal();
  const history = useHistory();

  const changeStatus = (newStatus: PaymentStatus) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(PaymentParams.PAYMENT_STATUS);
    searchParams.set(PaymentParams.PAYMENT_STATUS, newStatus);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  const onPayButtonClick = (
    amount: number,
    email: string,
    message?: string
  ) => {
    const samplePayload = {
      amount: 1000,
      termsAndCondition: true,
      message: "Test payment",
      email: "envkt@example.com",
      User_OID: 9
    };
    if (modalData?.paymentType) {
      startPayment(samplePayload, PaymentType[modalData.paymentType])
        .then(() => {
          changeStatus(PaymentStatus.SUCCESS);
        })
        .catch(() => {
          changeStatus(PaymentStatus.SUCCESS);
        });
    }

    //to jest mock zapytania do api
  };

  const getModalContentByType = () => {
    switch (modalData?.paymentType) {
      case PaymentType.TIP: {
        return <TipModal onPayButtonClick={onPayButtonClick} />;
      }
    }
  };

  return <div>{getModalContentByType()}</div>;
};

export default PaymentModal;
