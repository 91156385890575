import React from 'react';
import { observer } from 'mobx-react'

import { styled } from "../../stitches.config"
import AuthorAtavist from '../AuthorAtavist';
import { useStores, pushDataLayer } from "../../helpers"
import { h3 } from '../commonStyles/Fonts';


interface AtavistAuthorsProps {
    article: ArticleAuthor;
}

const AtavistAuthors: React.FC<AtavistAuthorsProps> = observer(({
    article
}) => {
    const { ArticleStore } = useStores();
    const url = ArticleStore.article.fb_url;

    return (
        <WrapperAll>
            <StyledAtavistAuthors id="autorzy-artykulu">
                <h3 className={h3()}>Autorzy artykułu:</h3>
                <Wrapper>
                    {/* zrobcić mapowanie */}
                    <AuthorAtavist article={article} border={false} />
                </Wrapper>
            </StyledAtavistAuthors>
        </WrapperAll>
    );
});

const StyledAtavistAuthors = styled('div', {
    padding: "32px 16px 40px 16px",
    background: "$grayscale0Fixed",
    "@bp4": {
        padding: "32px",
        borderBottom: "0px solid $grayscale25",
    },
    "h3": {
        textAlign: "center",
    }
})

const Wrapper = styled('div', {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "& .avatar": {
        width: "72px",
        height: "72px",
        borderRadius: "72px",
        marginRight: "20px !important",
    },
    "& p": {
        margin: "0 0 4px 0 !important",
    },
})

const WrapperAll = styled('div', {
    marginTop: "20px",
    marginBottom: "38px",
    width: "100%",
    maxWidth: "840px",
    "@bp4": {
        margin: "0 auto 38px auto",
    },
})

export default AtavistAuthors