import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://0c92c21400c34cc3abb1b0324425fb29@o400959.ingest.sentry.io/5271684",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    whitelistUrls: [
        /https?:\/\/((m|s|)\.)?(natemat|aszdziennik|mamadu|innpoland|dadhero)\.pl/
    ],
    // Based on sentry recommendation: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Avast extension error
        "_avast_submit"
    ],
    // Based on sentry recommendation: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
    ignoreUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
});