import React, { useRef, useEffect } from 'react';
import styled from "@emotion/styled"
import { Stage, Layer, Line } from 'react-konva';

import eraser from "../../../assets/img/gumka.svg"
import pen from "../../../assets/img/olowek.svg"
import eraserOn from "../../../assets/img/on_gumka.svg"
import penOn from "../../../assets/img/on_olowek.svg"

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const DrawingBoard = ({ height, updateHeight = () => { } }) => {
    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = React.useState([]);
    const [lock, setLock] = React.useState(false);
    const isDrawing = React.useRef(false);
    const ref = useRef(null)

    const handleMouseDown = (e) => {
        if (!lock) return;
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleReset = () => {
        if (isDrawing.current) return;
        setLines([]);
    }

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    const toggleBodyScrollLock = () => {
        setLock(!lock);
        updateHeight();
    }

    useEffect(() => {
        if (lock && window.innerWidth < 992) {
            disableBodyScroll(ref.current)
        } else {
            enableBodyScroll(ref.current)
        }
    }, [lock])

    return (
        <BoardWrap ref={ref} lock={lock} tool={tool === "pen" ? pen : eraser} toolActive={tool === "pen" ? penOn : eraserOn}>
            <Stage
                width={window.innerWidth}
                height={height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUp}>
                <Layer>
                    {lines.map((line, i) => (
                        <Line
                            key={i}
                            points={line.points}
                            stroke="#df4b26"
                            strokeWidth={line.tool === 'eraser' ? 20 : 5}
                            tension={0.5}
                            lineCap="round"
                            globalCompositeOperation={
                                line.tool === 'eraser' ? 'destination-out' : 'source-over'
                            } />
                    ))}
                </Layer>
            </Stage>
            {lock && <Options>
                <select
                    value={tool}
                    onChange={(e) => {
                        setTool(e.target.value);
                    }}>
                    <option value="pen">Długopis</option>
                    <option value="eraser">Gumka</option>
                </select>
                <button onClick={handleReset}>Wyczyść</button>
            </Options>}
            <Button lock={lock} onClick={toggleBodyScrollLock}><img src={lock ? pen : penOn} /></Button>
        </BoardWrap>
    );
};

const BoardWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: url(${({ lock, tool }) => lock ? tool : 'default'}), auto;
    &:active{
        cursor: url(${({ lock, toolActive }) => lock ? toolActive : 'default'}), auto;
    }
`

const Options = styled.div`
    position: absolute;
    top: 5px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > select{
        width: 140px;
        height: 30px;
        padding-left: 5px;
        margin-left: 10px;
        outline: none;
    }
    & > button {
        background-color: ${props => props.theme.mainColor};
        border-radius: 20px;
        color: white;
        outline: 0;
        border: 0;
        padding: 5px 10px;
        margin: 0 10px;
    }
`

const Button = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: ${props => props.lock ? props.theme.mainColor : 'white'};
    color: ${props => props.lock ? 'white' : props.theme.mainColor};
    padding: 11px 11px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    & > img{
        width: 38px;
        cursor: pointer;
    }
`

export default DrawingBoard