import React from "react";
import styled from "@emotion/styled";

const WrapperBalls = styled.div`
  position: absolute;
  top: 50%;
  left: 5.6px;
  transform: translateY(-50%);
`;

const Ball = styled.div`
  width: 4.8px;
  height: 4.8px;
  background-color: #fff;
  border-radius: 50%;
  &:first-child {
    margin-bottom: 2.8px;
  }
`;

const Balls = () => (
  <WrapperBalls>
    <Ball />
    <Ball />
  </WrapperBalls>
);

export default Balls;
