import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import parse, { domToReact } from "html-react-parser";
// import Safe from "react-safe"

//eslint-disable-next-line
import ArticleBodyStyles from "./styles/art.scss";
import Link from "../../Link";
import Adslot from "../../Adslot";
import TrudatVoteButtons from "./body/TrudatVoteButtons";
import TrudatVoteDescription from "./body/TrudatVoteDescription";
import FacebookPost from "./body/FacebookPost";
import TwitterPost from "./body/TwitterPost";
import InstagramPost from "./body/InstagramPost";
import YoutubeVideo from "./body/YoutubeVideo";
import Gallery from "./body/Gallery";
import Quote from "./body/Quote";
import OnnetworkVideo from "./body/OnnetworkVideo";
import TiktokEmbed from "./body/TiktokEmbed";

@inject("ArticleStore")
@observer
class Body extends Component {
  parserOptions = {
    replace: domNode => {
      var className =
        domNode.attribs && domNode.attribs.class ? domNode.attribs.class : null;

      if (domNode.name === "reactgallery") {
        var settings = [];
        try {
          settings = JSON.parse(domNode.children[0].data);
        } catch (err) {
          settings = [];
        }
        return <Gallery settings={settings} />;
      } else if (className === "adslotplace" && !this.props.hideAdslots) {
        // console.log(domNode.attribs["name"], "adslotmounted")
        return <Adslot name={domNode.attribs["name"]} />;
      } else if (domNode.name === "reactfacebookpost") {
        return <FacebookPost href={domNode.attribs["data-href"]} />;
      } else if (domNode.name === "reacttiktok") {
        return <TiktokEmbed profile={domNode.attribs["data-profile"]} videoId={domNode.attribs["data-videoid"]} />;
      } else if (domNode.name === "reactyoutube") {
        return <YoutubeVideo dataId={domNode.attribs["data-videoid"]} />;
      } else if (domNode.name === "reacttwitter") {
        return <TwitterPost dataId={domNode.attribs["data-tweetid"]} />;
      } else if (domNode.name === "reactinstagram") {
        return <InstagramPost dataId={domNode.attribs["data-shortcode"]} />;
      } else if (className === "art__vote__buttons") {
        return <TrudatVoteButtons url={this.props.ArticleStore.article.url} />;
      } else if (className === "onnetwork-video" && domNode.attribs["data-onnetwork-mid"]) {
        return (
          <OnnetworkVideo mid={domNode.attribs["data-onnetwork-mid"]} />
        );
      } else if (domNode.name === "a") {
        return (
          <Link to={domNode.attribs.href} className={className}>
            {domToReact(domNode.children, this.parserOptions)}
          </Link>
        );
      } else if (domNode.name === "reactquote") {
        return (
          <Quote> {domToReact(domNode.children, this.parserOptions)} </Quote>
        );
      } else if (className === "art__conclusion__vote-description") {
        return (
          <TrudatVoteDescription context={domNode.attribs["data-context"]} />
        );
      } else if (domNode.name === "script" && domNode.attribs) {
        if (domNode.attribs.src && domNode.attribs.src.length > 1) {
          var scriptSrc = domNode.attribs.src;
          setTimeout(function () {
            var s = document.createElement("script");
            s.type = "text/javascript"; s.async = true;
            s.setAttribute("src", scriptSrc);
            document.getElementsByTagName("body")[0].appendChild(s);
          }, 300);
          return <Fragment />;

        } else if (domNode.attribs.type === 'text/javascript') {
          var script = (domNode.children[0] ? domNode.children[0].data : null);
          setTimeout(function () { window.eval(script) }, 1000);
          return <Fragment />;
          // powinno być tak jak poniżej, zamiast tych 2 linijek powyżej, ale nie działa
          // return <Safe.script>{script}</Safe.script>;
        }
      }
    }
  };

  render() {
    var articleBody = parse(
      this.props.ArticleStore.article.body,
      this.parserOptions
    );
    return <div className="art">{articleBody}</div>;
  }
}

Body.defaultProps = {
  hideAdslots: false
}

export default withRouter(Body);