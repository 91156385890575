import * as React from "react"
import styled from "@emotion/styled"
import AnimateHeight from 'react-animate-height';

import { ReactComponent as ExpandBar } from "../../../../../../assets/img/natemat/pasek_rozwin.svg";
import ExpandBarBizon from "./ExpandBarBizon"


type TArrow = {
    direction: number | string;
    bottomPosition?: string;
}

export enum AnimateWrapperButton {
    StandardArrow,
    ShowMore
}

interface IAnimateHeightHOC {
    condition: boolean;
    children: React.ReactElement;
}

interface IProps {
    height?: number | string;
    buttonType?: AnimateWrapperButton;
    type?: AnimateWrapperButton
    visibility?: boolean;
    childrenRef?: any,
    onChange?: () => void;
}


const setButton = (type: AnimateWrapperButton, params: any) => {
    let { wrapHeight, height, setHeight, setButtonVisibility } = params;
    switch (+type) {
        case AnimateWrapperButton.StandardArrow:
            return <ArrowButton
                direction={wrapHeight}
                onClick={() => setHeight(wrapHeight === height ? "auto" : height)}>
                <span></span>
            </ArrowButton>
        case AnimateWrapperButton.ShowMore:
            return <ArrowButton2
                direction={wrapHeight}
                onClick={() => {
                    setHeight("auto")
                    setTimeout(() => setButtonVisibility(false), 500);
                }}>
                <ExpandBarBizon />
                {/* <button><span>:</span> Rozwiń</button> */}
            </ArrowButton2>
    }
}

const AnimateHeightWrapper: React.FC<IProps> = ({ children, height = 100, type = AnimateWrapperButton.StandardArrow, visibility = false, ...props }) => {
    const [wrapHeight, setHeight] = React.useState<number | string>(height);
    const [buttonVisibility, setButtonVisibility] = React.useState<boolean>(visibility);

    React.useEffect(() => {
        setButtonVisibility(visibility)
    }, [visibility])

    return (<Wrap>
        <AnimateHeight className={visibility ? "" : "auto-height"}
            duration={500}
            height={wrapHeight}>
            <div ref={props.childrenRef}>
                {children}
            </div>
            {buttonVisibility && <ArrowOverlay className="animate-height-wrapper__overlay" direction={wrapHeight} bottomPosition={type == AnimateWrapperButton.StandardArrow ? '30px' : '60px'} />}
        </AnimateHeight>
        {buttonVisibility && setButton(type, { wrapHeight, height, setHeight, setButtonVisibility })}
    </Wrap >)
}

const Wrap = styled.div`
 position: relative;
 padding-bottom: 30px;
 overflow: hidden;
 .auto-height {
     height: auto !important;
 }
`

const ArrowOverlay = styled.div<TArrow>`
height: 50px;
position: absolute;
bottom: ${props => props.direction === "auto" ? '-13px' : props.bottomPosition};
left: 0;
right: 0;
/* background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 100%);*/
/* opacity: ${props => props.direction === "auto" ? "0" : "1"}; */
transition: bottom 500ms;
`

const ArrowButton = styled.button<TArrow>`
    position: absolute;
    z-index: 3;
    padding: 0;
    width: 24px;
    height: 24px;
    left: 50%;
    right: 50%;
    bottom: 3px;
    border-radius: 50%;
    border: 1px solid #979797;
    background-color: transparent;
    outline: 0;
    transform: translateX(-50%) rotate(${props => props.direction === "auto" ? 180 : 0}deg);
    transition: all 500ms;
    &:hover{
        border-color: ${((props: any) => props.theme.mainColor)};
    }
    span{
        position: absolute;
        content: '';
        top: 4px;
        left: 6.5px;
        width: 0.625em;
        height: 0.625em;
        border-top: 0.025em solid #979797;
        border-right: 0.025em solid #979797;
        transform: rotate(135deg);
    }
`

const ArrowButton2 = styled.div<TArrow>`
width: 99%;
height: 25px;
z-index: 55;
margin-bottom: 5px;
margin-left: 5px;
cursor: pointer;


span {
    font-weight: bold;
    font-size: 1.5em;
}
button{
    width: 100px;
    height: 25px;
    position: absolute;
    color: #ffffff;
    font-weight: 700;
    background: #ff6400;
    border: none;
    font-size: 0.8em;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    margin-left: -5px;
    text-align: left;
    line-height: 22px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    display: none;
}
`


export const WithAnimateHeight: React.FC<IAnimateHeightHOC> = ({ condition, children }) => (condition ? <AnimateHeightWrapper>{children}</AnimateHeightWrapper> : children)

export default AnimateHeightWrapper