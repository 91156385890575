import React from "react"
import styled from "@emotion/styled"

import HtmlParser from "../../Content/HtmlParser"

const HardcoverFactFrame = ({ blockData }) => {
    return <Wrap>
        <HtmlParser data={blockData.text} />
    </Wrap>
}

const Wrap = styled.div`
    width: 358px;
    margin: 0 auto;
    min-height: 50px;
    text-align: center;
    background-color: #fff;
    margin: 20px auto;
    position: relative;
    font-size: 35px;
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
    max-width: calc(100vw - 40px);
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 42px;
        height: 48px;
        border-top: 2px solid ${props => props.theme.hardcoverColor};
        border-left: 2px solid ${props => props.theme.hardcoverColor};
        top: -2px;
        left: -2px;
    }
    &:after{
        display: block;
        content: "";
        position: absolute;
        width: 42px;
        height: 48px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        bottom: -2px;
        right: -2px;
    }
    font{
        font-size: 45px;
    }
    div{
        margin: 0 !important;
    }

    @media (min-width: 992px) {
        width: 520px;
    }
`

export default HardcoverFactFrame