import React, { Fragment } from "react";
import styled from "@emotion/styled";

import QuoteIcon from "../../../../assets/img/natemat/quote.svg";
// bizon
import quoteIcon from '../../../../redesign/assets/icons/quote-icon.svg';
import quoteDotsIcon from "../../../../redesign/assets/icons/quote-dots-icon.svg";

export default class Quote extends React.Component {
  render() {
    const html = this.props.content;
    if (html) {
      return (

        <QuoteBizonWrapper>
          <QuoteBizonBox>
            <StyledQuoteIcon></StyledQuoteIcon>
            <div className='quote' dangerouslySetInnerHTML={{ __html: html }}>

            </div>
          </QuoteBizonBox>
          <StyledQuoteDotsIcon></StyledQuoteDotsIcon>
        </QuoteBizonWrapper>
      );
    } else {
      return (
        <Fragment>
          <QuoteWrapper>{this.props.children}</QuoteWrapper>
        </Fragment>
      );
    }
  }
}

const QuoteWrapper = styled.div`
  border-top: 1px solid #B2B2B2;
  border-bottom: 1px solid #B2B2B2;
  margin: 17px 0px 17px 52px;
  padding: 10px 20px 10px 0;
  position: relative;
  &:before {
    display: block;
    position: absolute;
    top: 9px;
    left: -45px;
    content: '';
    width: 34px;
    height: 24px;
    background: url(${QuoteIcon}) no-repeat center center;
    background-size: 32px auto;

    /* background: ${props => props.theme.secondColor}; */
  }
  @media (min-width: 996px) {
    margin: 17px 0px 17px 80px;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #B2B2B2;
    padding: 10px 20px;
    &:before{
      left: -80px;
      width: 50px;
      height: 27px;
      background-size: 50px auto;

    }
  }

  p {
    margin: 0;

    em {
      color: #232323;
      font-family: "Playfair Display";
      font-size: 19px;
      line-height: 23px; 
    }
  }
  .author-header {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    .avatar {
      display: none;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 19px;
      padding-bottom: 6px;
    }
    .about {
      color: #B2B2B2;
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 16px;
  }
  .more {
    /* font-size: 14px; */
    /* text-decoration: none; */
    display: none;
    /* margin-top: 18px; */
    }
  }
`;

const QuoteBizonBox = styled.div`
    max-width: 840px;
    margin: 0 auto;
    color: #FFF !important;
`

const QuoteBizonWrapper = styled.div`
    background: #131212;
    width: 100%;
    padding: 45px 64px 48px 24px;
    position: relative;
    margin: 40px 0px 32px -10px;

    @media (min-width: 996px) {
        padding: 72px 168px 72px 64px;
        margin: 88px 0px 80px -20px;
    }
    
    .quote{
        height: auto;
        display: flex;
        flex-direction: column-reverse;

        p{
            font-family: HK Grotesk;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: #FFFFFF;
            @media (min-width: 996px) {
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
            }
            em {
               font-style: normal !important;
            }
        }  
    }  
  .author-header {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    @media (min-width: 996px) {
      margin-top: 32px;
    }
    .avatar {
      display: none;
    }
    .name {
      font-family: HK Grotesk;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 19px;
      padding-bottom: 6px;
      @media (min-width: 996px) {
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
    .about {
      color: #B2B2B2;
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 16px;
  }
  .more {
    /* font-size: 14px; */
    /* text-decoration: none; */
    display: none;
    /* margin-top: 18px; */
    }
  }
`

const StyledQuoteIcon = styled.div`
    width: 64px;
    height: 58px;
    background-image: url(${quoteIcon});
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 30px 0;
     @media (min-width: 996px) {
        width: 88px;
        height: 82px;
        margin: 0 0 40px 0;
        background-size: 82px;
    }
`

const StyledQuoteDotsIcon = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 137px;
    flex-shrink: 0;
    background-image:  url(${quoteDotsIcon});
    background-size: cover;
    @media (min-width: 996px) {
        width: 80px;
        height: 278px;
    }
`
