import React from 'react';
import { styled } from "../stitches.config"
import { p } from "./commonStyles/Fonts";

import RenderSlateChildren from "../components/Article/Parts/Slate/RenderSlateChildren"
import { SlateArticleType } from "../stores/ArticleStore"
import { UnorderedListElement } from "../components/Article/Parts/Slate/types"

interface ListProps {
    element: UnorderedListElement;
    article: SlateArticleType;
}

const List: React.FC<ListProps> = ({
    element,
    article,
    ...props
}) => {
    return (
        <StyledList className={p()}>
            {element.children.map((li, index) => (<li key={index}>
                {li.children.map(lic => (
                    <span><RenderSlateChildren element={lic.children} article={article} /></span>
                ))}
            </li>))}
        </StyledList>
    );
};

const StyledList = styled('div', {
    "& li": {
        listStyle: "none",
        marginBottom: "8px",
        display: "flex",
        "&:before": {
            width: "6px",
            height: "6px",
            marginRight: "8px",
            marginBottom: "2px",
            borderRadius: "50%",
            content: "",
            display: "inline-block",
            backgroundColor: "$primary",
            position: "relative",
            flexShrink: 0,
            marginTop: "10px",
            "@bp4": {
                marginTop: "13px",
            }
        },
        "& span": {
            display: "inline",
            "span": {
                display: "inline",
            }
        },
    }
})

export default List