import * as React from "react"
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

const PrivateRoute: React.FC<RouteProps> = (props) => {
    const [{ isAuthed }] = useAuth();
    const location = useLocation();

    if (isAuthed === null) {
        return null;
    } else if (isAuthed) {
        return <Route {...props} />
    } else {
        return <Redirect to={{
            pathname: '/login',
            state: {
                from: location
            }
        }} />
    }
}

export default PrivateRoute