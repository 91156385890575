import React, { Component } from "react";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Global } from "@emotion/core";
import { mainColor } from "./stitches.config";

import { withThemeContext } from "./redesign/components/StitchesThemeProvider";

import BridLogo from "./assets/img/by_naTemat_logo_Brid.svg";
import BridLogoOrange from "./assets/img/logo_pomaranczowe_brid.svg";

class Styles extends Component {
  render() {
    const { isDarkMode } = this.props;

    return (
      <Global styles={css` 
  * {
    box-sizing: border-box;
  }

  body {
    font-family: "HK Grotesk", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    /* font-weight: 400; */
    /*
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;
    transition: background-position 0.1s ease-in-out;
    */

    /* Dodanie koloru do dark mode w slide body - stary element strony */

    .parts-slideshow__slide-body {
      div {
       color: ${isDarkMode ? "white !important" : "#131212 !important"};
      }
    }

     .parts-slideshow__slide-title {
       color: ${isDarkMode ? "white" : "#131212"};
    }

    /* kolor tła ostatniego blocku w dark mode - B1d5m */

    .b1d5m__box-shadow {
      .shadowEl {
        background: ${isDarkMode ? "linear-gradient(rgb(19, 18, 18, 0), rgb(19, 18, 18, 1)) !important" : null};
        color: ${isDarkMode ? "#131212 !important" : null};
        box-shadow: ${isDarkMode ? "0px 0px 0px" : null};
      }
    }

    /* kolor tła szarej wstawki tesktu w dark mode */

    .style_gray {
        background: ${isDarkMode ? "#1B1B1B !important" : "#E9E9E9 !important"};
    }

    /* pomarańczowe logo w dark mode - modal brid payment */

    .payment-brid-logo {
       background-image: ${isDarkMode ? `url(${BridLogoOrange});` : `url(${BridLogo});`};
    }
    
    /* reklama w tle strony */
    &.belka-top--active{
      padding-top: 60px;
      transition: padding-top 500ms ease-in-out;
      .nav-fixed{
          transition: top 500ms ease-in-out;
          top: 60px;
          @media (min-width: 996px) {
            top: 150px;
          }
      }
      &.belka-top--full{
        padding-top: 265px;
        transition: padding-top 500ms ease-in-out;
        .nav-fixed{
          top: 265px;
        }
      }
    }

    .app-content {
      min-height: 90vh;
    }
    
    @media (min-width: 996px) {
      background-position: center 118px !important;
      
      &.belka-top--active{
        padding-top: 150px;
        background-position: center 268px !important;
        transition: padding-top 500ms ease-in-out;
        
        &.belka-top--full{
          padding-top: 300px;
          transition: padding-top 500ms ease-in-out;
        }
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  input, button {
    &:focus {
      outline: 0;
    }
  }

  .body-v2 {
	overflow-x: hidden;
  overflow-x: clip
  }
  .text-center {
    text-align: center;
  }

  .overflow-hidden{
    overflow: hidden;
  }

  /* widget world cup 2022 - dark mode */

  .wc-2022-scores {
      .slick-arrow{
        &:before{
          color: ${isDarkMode ? "white !important" : "#131212 !important"};
        }
    }
  }
  
  .wc-2022-match-body, .wc-2022-match-info-container, .wc-2022-match-group {
    color: ${isDarkMode ? "white !important" : "#131212 !important"};
  }

  /* ukrywanie główki autora w category mobile */

    .photo-on-top__author {
      @media (max-width: 995px) {
        display: none;
      }
    }

  /* wrapper animowanej wysokości - dark mode */

  .animate-height-wrapper__overlay {
    background: ${isDarkMode ? "linear-gradient(to bottom, rgba(19, 18, 18, 0), #131212 100%" : "linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 100%"});
  }

  /* brid dark mode */

  .brid-background-wrapper {
    background-color: ${isDarkMode ? "#131212" : "#e5e5e5"};
  }

  .brid-comments-wrapper {
    background-color: ${isDarkMode ? "#131212" : "white"};
    color: ${isDarkMode ? "white" : "#131212"};
  }

  .brid-header-wrap {
    background-color: ${isDarkMode ? "#131212" : "white"};
    color: ${isDarkMode ? "white" : "#131212"};
  }

  .brid-paragraph-wrapper {
    padding: 16px 0;
    color: ${isDarkMode ? "white" : "#131212"};
    background-color: ${isDarkMode ? "#131212" : "white"};
    @media (min-width: 996px) {
        padding: 28px 0;
    }
  }

  /* artykuł konkursowy - rich artylce #4056, #4061 */

  .special-rich-article-background {
    section {
      background: #e62e2e !important;
      a {
        color: #f4e8d4 !important;
        &:hover {
          color: #f4e8d4 !important;
        }
      }
    }
    .rich-article-background-img {
      background: none !important;
    }
  }

  .special-rich-article-content {
    .rich-article-background-img {
      background: none !important;
    }
    .article-info__picsInfo {
      &::before {
        content: "Ilustracje: " !important;
      }
    }
  }

  /* głosowanie brid - dark mode */

  .brid-side-info-title {
    color: ${isDarkMode ? "white" : "#131212"};
  }

  .brid-side-info-timer {
      color: ${isDarkMode ? "white !important" : "#131212 !important"};
}

/* białe tło pod sekcjami gdy jest reklama - tapeta na body + klikalne body */
body.adslot--tapeta,
  body.screening_wallpaper {
    
    .gnt-screening {
			display: none;
			position: absolute;
			top: 118px;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
      overflow: hidden;
			width: 100%;
			min-height: 1080px;
    	will-change: top; /* Przydatne przy dynamicznej zmianie top */
      
      @media (min-width: 996px){
        &.gnt-screening--clickable{
          display: block;
          cursor: pointer;
        }
      }
		}
    &.scrolling-down{
      .gnt-screening{
        position: fixed;
        top: 0;
      }
    }
    &.scrolling-up{
      .gnt-screening{
        transition: top 0.6s ease;
        position: fixed;
        top: 60px;
      }
    }
    #root {
    isolation: isolate;
    margin: 0 auto;
    max-width: 100%;
      .hp{
      background-color: ${isDarkMode ? "#131212" : "white"};
    }
    /* breakpoint */
    @media(min-width: 375px) {
      max-width: 343px;
    }
    @media(min-width: 768px) {
      max-width: 774px;
    }
    @media(min-width: 996px) {
      max-width: 972px;
    }
    @media(min-width: 1310px) {
      max-width: 1296px;
    }
  }

  /**
    * czasami app-content dostaje jakieś tło, jak w artykule sa jakieś cuda. ale nie może mieć tła, jak jest tapeta
    */
  .app-content {
    background-color: transparent;
  }
}
  
  #pushAdUpBanner{
  z-index: 5000001 !important;
}
/* poprawki quantcast */
@media(max-width: 768px) {
    #qc-cmp2-ui {
    height: auto!important;
    max-height: 100% !important;
    
      .qc-cmp2-summary-info {
      max-height: 110px !important;
    }
      .qc-cmp2-footer {
      height: auto !important;
    }
  }
}

.bell-animation {
  @keyframes hang { 
        0% { 
            transform: rotate(-25deg); 
        } 5% { 
            transform: rotate(50deg); 
        } 10% {
            transform: rotate(-25deg); 
        } 12% {
          transform: rotate(0);
        }
    }

  animation: hang 20s ease-out;
  animation-iteration-count: 3;
}
/*
@media (max-width: 360px) and (min-height: 420px){
  .qc-cmp2-summary-section {
    height: 320px !important;
  }
}
*/

`} />
    )
  }
}

export default withThemeContext(Styles);