import { observable, action, reaction, computed, autorun, toJS } from 'mobx'
import { RootModel } from "./RootStore"
import { pushDataLayer } from "../helpers";

type TRoutingCallback = () => any //zostawiam tak, bo na razie potrzebujemy tylko w jednym przypadku
type TRoutingAction = TRoutingCallback | undefined

interface IAdConfig {
    [index: string]: {
        lazy?: {
            enabled: boolean;
            offset: number;
        },
        smart_lazy?: {
            enabled: boolean;
            offset: number;
        }
        interval?: {
            enabled: boolean;
            delay: number;
        },
        capping?: {
            enabled: boolean;
            time: number;
        },
        sra?: {
            enabled: boolean;
            groups: Array<string>;
        }
    }
}

type SlotType = {
    id: string;
    path: string;
    sizeMappings: any; //do otypowania
    prebid: any; //do otypowania
    sizeMappingName: string;
    sizes: Array<Array<[number, number]>>
}

interface ISraGroups {
    [index: string]: Set<string>
}

export default class AdsStore {
    RootStore: RootModel;
    constructor(RootStore: RootModel) {
        this.RootStore = RootStore;
        window.addEventListener("tcfapiLoaded", () => this.tcfapiLoaded = true)
        reaction(
            () => this.tcfapiLoaded,
            tcfapi => {
                if (tcfapi) {
                    window.__tcfapi("addEventListener", 2, (tcData: any, success: boolean): void => {
                        if (success) {
                            if (tcData.eventStatus === "tcloaded" || tcData.eventStatus === "useractioncomplete") {
                                console.log("tcdata is loaded")
                                this.tcDataLoaded = true
                                window.dispatchEvent(new CustomEvent("tcDataLoaded"))
                                pushDataLayer({
                                    event: "tcDataLoaded",
                                });
                            }
                        }
                    });
                }
            })

        window.addEventListener("scroll", () => this.isBelowTheFold = window.pageYOffset > window.innerHeight)
        autorun(() => this.isBelowTheFold && window.dispatchEvent(new CustomEvent("stickybtf")))

        autorun(() => {
            const appHistory = toJS(this.RootStore.UIStore.appHistory);
            !this.isFirstClick && appHistory[0].pathname == '/' && window.dispatchEvent(new CustomEvent("redirectFinish"))
        })
    }

    /* comercial break */

    @observable
    isFirstClick: boolean = true;

    @observable
    routingCallback: TRoutingAction = undefined

    @observable
    isBelowTheFold = false;

    @observable
    tcfapiLoaded: boolean = false;

    @observable
    tcDataLoaded: boolean = false;

    @observable
    isAdConfigLoaded: boolean = false;

    @observable
    prebidConfig: any = {}

    @observable
    adConfig: IAdConfig = {}

    @observable
    slotsConfigSG: SlotType | [] = [];

    @observable
    slotsConfigCategory: SlotType | [] = [];

    @observable
    slotsConfigArticle: SlotType | [] = [];

    @observable
    sraGroups: ISraGroups = {}

    @observable
    adslotGroups: ISraGroups = {}

    @observable
    availGroups = new Set();

    @observable
    loadConfig: boolean = false;

    @action
    checkAdlibConfig = (): void => {
        const { ADLIB_CONFIG } = window;
        const requiredConfigObjectKeys = ["PREBID_CONFIG", "AD_CONTAINER_CONFIG", "SRA_CONFIG"]
        const passed = requiredConfigObjectKeys.every(key => typeof ADLIB_CONFIG?.[key] !== "undefined")
        if (passed) {
            let sraSets: ISraGroups = {}
            Object.entries(ADLIB_CONFIG.SRA_CONFIG as { [key: string]: string[] }).forEach(([key, value]: [string, string[]]) => {
                sraSets[key] = new Set([...value])
            })
            // prebidConfig - prebid configa i gpt
            this.prebidConfig = ADLIB_CONFIG.PREBID_CONFIG;
            // this.prebidConfig.prebid.ix = {};
            // this.prebidConfig.prebid.ix.detectMissingSizes = false;
            // adConfig - nasz config dotyczący lazy, sra, typu reklamy
            this.adConfig = ADLIB_CONFIG.AD_CONTAINER_CONFIG
            // sraGroups - aktualnie wyswietlające się reklamy dla danej grupy sra
            this.sraGroups = sraSets
            this.isAdConfigLoaded = true;
        }
    }

    @action
    pushAdslot = (group: string, id: string): void => {
        if (typeof this.adslotGroups[group] === "undefined") {
            this.adslotGroups[group] = new Set()
        }
        this.adslotGroups[group].add(id);
    }

    @action
    removeAdslot = (group: string, id: string): void => {
        if (typeof this.adslotGroups[group] === "undefined") {
            this.adslotGroups[group] = new Set()
        }
        this.adslotGroups[group].delete(id);
    }

    @action
    compareGroups = (): void => {
        // console.log("groups compared")
        for (const group of Object.keys(this.sraGroups)) {
            if (this.sraGroups[group].size === this.adslotGroups[group]?.size && [...Array.from(this.sraGroups[group])].every(value => this.adslotGroups[group].has(value))) {
                this.availGroups.add(group)
            } else {
                this.availGroups.delete(group)
            };
        }
    }

    @action
    setLoadConfig = (load: boolean): void => {
        this.loadConfig = load
    }

    @action
    callRoutingCallback = (cb: TRoutingAction) => cb instanceof Function && cb()

    @action
    handleRedirectFinish = (cb: TRoutingAction) => {
        // this.handleFirstClick()
        this.callRoutingCallback(cb)
    }
}