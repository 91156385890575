import React from "react";
import { inject, observer } from "mobx-react";

const KulkiSvgExtra = inject("UIStore")(
    observer(({ UIStore }) => {
        const color = (UIStore.appTheme.mainColor ? UIStore.appTheme.mainColor : '#f60');

        return (
            <svg width="27px" height="61px" viewBox="0 0 27 61" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Desktop:Artykuł-premium-edit" transform="translate(-153.000000, -459.000000)" fill={color} fill-rule="nonzero">
                        <path d="M179.9992,472.35333 C180.058733,477.839837 176.791636,482.816594 171.733932,484.943783 C166.676228,487.070973 160.834169,485.92538 156.954395,482.045605 C153.07462,478.165831 151.929027,472.323772 154.056217,467.266068 C156.183406,462.208364 161.160163,458.941267 166.64667,459.0008 C173.944815,459.181722 179.818278,465.055185 179.9992,472.35333 Z M179.9992,506.35333 C180.058733,511.839837 176.791636,516.816594 171.733932,518.943783 C166.676228,521.070973 160.834169,519.92538 156.954395,516.045605 C153.07462,512.165831 151.929027,506.323772 154.056217,501.266068 C156.183406,496.208364 161.160163,492.941267 166.64667,493.0008 C173.944815,493.181722 179.818278,499.055185 179.9992,506.35333 Z" id="Shape-2"></path>
                    </g>
                </g>
            </svg>
        );
    }
    )
);

/*<svg width="24" height="48" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <circle cx="6" cy="6" r="3" stroke={color} strokeWidth="1" fill={color} />
    <circle cx="6" cy="15" r="3" stroke={color} strokeWidth="1" fill={color} />
</svg>*/
export default KulkiSvgExtra;
