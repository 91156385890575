import React, { Component } from "react";
import Lazyload from "react-lazyload";
import styled from "@emotion/styled";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import VizSensor from 'react-visibility-sensor';
import throttle from 'lodash.throttle'

import OnnetworkPlayer from "../../../OnnetworkPlayer";

@observer
class OnnetworkVideo extends Component {
  ref = React.createRef()
  scroll = this.handleScroll.bind(this);
  throttledHandleScroll = throttle(this.scroll, 300)
  @observable
  closed = false;

  @observable
  fixed = true;

  @observable
  offset = 0;

  handleScroll() {
    this.offset = this.ref.current?.getBoundingClientRect().top
  }

  componentDidMount() {
    window.addEventListener("scroll", this.throttledHandleScroll)
    if (this.props.hookPlayer === false) {
      this.closed = true;
    }
  }

  onChangeVisibility(isVisible) {
    this.fixed = !isVisible;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll)
  }

  render() {
    return (
      <div ref={this.ref}>
        <Lazyload height={200} offset={200} once={true}>
          <VizSensor onChange={(isVisible) => this.onChangeVisibility(isVisible)} partialVisibility={true} active={this.offset < 0} delayedCall={true}>
            <Container>
              <FloatingContainer className={(this.fixed && !this.closed && this.offset < 0) ? "onnetwork-video--fixed" : null}>
                <button className="close" onClick={(e) => (this.closed = true)}>&times;</button>
                <OnnetworkPlayer mid={this.props.mid} />
              </FloatingContainer>
            </Container>
          </VizSensor>
        </Lazyload>
      </div>
    );
  }
}

const Container = styled.div`
  width: 100%;
  /* padding-bottom: 56%; */
  height: auto;
  /* position: relative; */
  /* margin-top: 23px; */
`;

const FloatingContainer = styled.div`
  margin: 0;
  position: relative;

  .close {
    display: none;
    background: rgb(255, 255, 255, 0.8);
    border: none;
    color: red;
    font-size: 30px;
    font-weight: 30px;
    position: absolute; 
    right: 0;
    bottom: -30px;

    @media(min-width: 996px) {
      top: -10px;
      bottom: auto;
    }
  }

  &.onnetwork-video--fixed {
    position: fixed;
    left: -1px;
    right: -1px; 
    top: 50px;
    z-index: 9999999; /** zawsze na wierzchu, później reklama i reszta */
    
    .close {
      display: inline-block;
    }

    /* desktop */
    @media(min-width: 996px) {
      width: 360px;
      right: 20px;
      bottom: 20px;

      left: auto;
      top: auto;
    }
  }
`;

export default OnnetworkVideo;
