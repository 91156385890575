// import Cookies from "cookies-js";

const IS_PRIVILEGED_USER = false; // !!(Cookies.get("IS_PRIVILEGED_USER"));

/* timeout używany do refreshowania strony, jeśli tab nie jest sfocusowany*/
export const APP_REFRESH_TIME = 120000;

/* regex do łapania parametrów zdjęć z cdna, <szerokość>,<wysokość>,<nie zachowuj aspect ratio>,<czarno-biały> */
export const IMG_SRC_PARAMS_REGEX = /,\d{1,3},\d{1,3},[0-1],[0-1]/;
export const MEDIA_SRC_PARAMS_REGEX = /,\%WIDTH\%,\%HEIGHT\%,\%CROP_FLAG\%,\%GRAYSCALE_FLAG\%\.[\w+]*/;

export const GOOGLE_MAPS_REGEX = /^https?:\/\/(?:www\.|maps\.)?google\.[a-z]+\/maps\/embed\?pb=(.*)/
export const YOUTUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/
export const FB_COMMENT_REGEX = /^https?:\/\/www\.facebook\.com\/.*\/posts\/\d{1,}\?comment_id=\d{1,}$/
export const FB_POST_REGEX = /^https?:\/\/www\.facebook\.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^/]+\/(activity|posts))[/?].*$/
export const INSTAGRAM_POST_REGEX = /^https?:\/\/www\.instagram\.com\/p\/([^/?&]+)\/?/
export const TWITTER_REGEX = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/
export const TIKTOK_REGEX = /^https?:\/\/(?:www.)?tiktok\.com\/(@.{1,24})?\/video\/(\d+)?(?:\?.*)?/

/** feature flags */
export const IS_BILLBOARD_1_POD_LEADEM = !!window.APP_SETTINGS?.feature_flags?.billboard1PodLeadem
export const IS_FEATURE_mundial2022 = !!window.APP_SETTINGS?.feature_flags?.mundial2022
export const IS_FEATURE_adunityPartner = !!window.APP_SETTINGS?.feature_flags?.adunityPartner
export const IS_FEATURE_TIP = !!window.APP_SETTINGS?.feature_flags?.flagTIP

/** feature flags redesign */

export const DESKTOP_BREAKPOINT = 992;

// feature flags redesign bez premium
export const DISABLE_PREMIUM_FLAG_ACTIVE = IS_PRIVILEGED_USER || !!window.APP_SETTINGS?.feature_flags?.disablePremium


export { APP_REFRESH_TIME as RELOAD_TIME }