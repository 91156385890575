import React, { Component } from "react"
const { serializeError } = require("serialize-error")

class ErrorBoundary extends Component {
    state = {
        hasError: false
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        const serializedError = serializeError(error)
        fetch(window.API_HOST + "/pwa-error/", {
            method: "POST",
            body: JSON.stringify({
                context: 'partsComponent-ErrorBoundary',
                error: {
                    error: serializedError,
                    info: errorInfo
                }
            })
        })
        // Sentry.captureException(error, { info: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return null
        }
        return this.props.children
    }
}

export default ErrorBoundary