import React, { Component } from "react";

import MarginWrapper from "./MarginWrapper"

import AuthorDate from "./AuthorDate";
import Title from "./Title";
import Picture from "./Picture";
import Lead from "./Lead";
import OnnetworkVideo from "../Content/body/OnnetworkVideo";
import BackgroundImage from "./BackgroundImage";
import ImgWithDesc from "./ImgWithDesc";
import Paragraph from "./Paragraph";
import Header from "./Header";
import ImageCompare from "./ImageCompare";
import LastElement from "./LastElement";
import Collage from "./Collage";
import Image from "./Image";
import DownloadButton from "./DownloadButton";
import Block from "./Block";
import TwitterPost from "../Content/body/TwitterPost";
import Adslot from "../../Adslot";
import RichQuote from "./Quote"
import BridInfo from './Brid/BridInfo'
import BridAbout from './Brid/BridAbout'
import BridAuthors from './Brid/BridAuthors/BridAuthors'
import BridShoppingList from './Brid/BridShoppingList'
import BridImageBanner from './Brid/BridImageBanner'
import HardcoverFactFrame from "./Hardcover/HardcoverFactFrame"
import AdslotSticky from "../../AdslotSticky"
import LinkChanger from '../LinkChanger'
import RankingIo from './RankingIo'


const OmniCalculator = React.lazy(()=> import(/* webpackChunkName: "parts__OmniCalculator" */"./OmniCalculator"));
const ErrorBoundary = React.lazy(()=> import(/* webpackChunkName: "parts__ErrorBoundary" */"./ErrorBoundary"));
const AtavistAuthors = React.lazy(()=> import(/* webpackChunkName: "redesign__components__AtavistAuthors" */"../../../redesign/components/AtavistAuthors"));
const VideoReportageAtavist = React.lazy(()=> import(/* webpackChunkName: "redesign__VideoReportageAtavist" */"../../../redesign/VideoReportageAtavist"));
const AtavistBridInfo = React.lazy(()=> import(/* webpackChunkName: "redesign__components__AtavistBridInfo" */"../../../redesign/components/AtavistBridInfo"));
const AtavistNextBrid = React.lazy(()=> import(/* webpackChunkName: "redesign__components__AtavistNextBrid" */"../../../redesign/components/AtavistNextBrid"));
const BreakLine = React.lazy(()=> import(/* webpackChunkName: "redesign__components__BreakLine" */"../../../redesign/components/BreakLine"));
const FootnotesAtavist = React.lazy(()=> import(/* webpackChunkName: "redesign__FootnotesAtavist" */"../../../redesign/FootnotesAtavist"));
const AtavistTestingHeader = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Mamadu__AtavistTestingHeader" */"../../../redesign/components/Atavist-Mamadu/AtavistTestingHeader"));
const AtavistTestingFooter = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Mamadu__AtavistTestingFooter"" */"../../../redesign/components/Atavist-Mamadu/AtavistTestingFooter"));
const AtavistTestingInfo = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Mamadu__AtavistTestingInfo" */"../../../redesign/components/Atavist-Mamadu/AtavistTestingInfo"));
const AtavistTestingQuote = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Mamadu__AtavistTestingQuote" */"../../../redesign/components/Atavist-Mamadu/AtavistTestingQuote"));
const AtavistTestingSlider = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Mamadu__AtavistTestingSlider" */"../../../redesign/components/Atavist-Mamadu/AtavistTestingSlider"));

// animowane elementy
const ParagraphIssue4282 = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Storyline__ParagraphIssue4282" */"../../../redesign/components/Atavist-Storyline/ParagraphIssue4282"));
const ImageAnimated = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Storyline__ImageAnimated" */"../../../redesign/components/Atavist-Storyline/ImageAnimated"));
const ParagraphAnimated = React.lazy(()=> import(/* webpackChunkName: ".redesign__components__Atavist-Storyline__ParagraphAnimated" */"../../../redesign/components/Atavist-Storyline/ParagraphAnimated"));
const ParagraphWithImageAnimated = React.lazy(()=> import(/* webpackChunkName: "redesign__components__Atavist-Storyline__ParagraphWithImageAnimated" */"../../../redesign/components/Atavist-Storyline/ParagraphWithImageAnimated"));

const Youtube = React.lazy(()=> import(/* webpackChunkName: "parts__Youtube" */"./Youtube"));
const Html = React.lazy(()=> import(/* webpackChunkName: "parts__Html" */"./Html"));
const Slideshow = React.lazy(()=> import(/* webpackChunkName: "parts__Slideshow" */"./Slideshow"));
const Carousel = React.lazy(()=> import(/* webpackChunkName: "parts__Carousel" */"./Carousel"));
const Embed = React.lazy(()=> import(/* webpackChunkName: "parts__Embed" */"./Embed"));
const Quote = React.lazy(()=> import(/* webpackChunkName: "Content__body__Quote" */'../Content/body/Quote'));
const RelatedArticle = React.lazy(()=> import(/* webpackChunkName: "parts__RelatedArticle" */"./RelatedArticle"));
const Gallery = React.lazy(()=> import(/* webpackChunkName: "Content__body__Gallery" */"../Content/body/Gallery"));
const LiveItem = React.lazy(()=> import(/* webpackChunkName: "parts__LiveItem" */"./LiveItem"));
const HtmlParser = React.lazy(()=> import(/* webpackChunkName: "Content__HtmlParser" */'../Content/HtmlParser'));

const Calculator = React.lazy(() => import(/* webpackChunkName: "pages__FertilityCalc" */'../../../pages/FertilityCalc'));

class Parts extends Component {
  componentDidCatch(error, info) {
    console.log(error, info);
    return <div>Wystąpił błąd podczas wczytywania artykułu. Spróbuj ponownie za chwilę</div>
  }

  render() {
    const { article, parts, hideAdslots } = this.props;
    const isDesktop = window.innerWidth >= 992; //po zmergowaniu do staginga bedzie zastapione zmienna ze uistorea

    return (
      <div>
        {parts.map((elt, index) => {
          switch (elt?.type) {
            case 'post-title':
              return <ErrorBoundary>
                <Title title={elt.data} key={index} />
              </ErrorBoundary>
            case 'author-date':
              return <ErrorBoundary>
                <AuthorDate article={article} key={index} />
              </ErrorBoundary>
            case 'picture':
              return <ErrorBoundary>
                <MarginWrapper>
                  <Picture picture={elt.data} key={index} titlePart={this.props.titlePart} />
                </MarginWrapper>
              </ErrorBoundary>
            case 'youtube':
              return <ErrorBoundary>
                <Youtube videoId={elt.data} key={index} />
              </ErrorBoundary>
            case 'adslot':
              if (hideAdslots)
                return null;

              const isSticky = window.APP_SETTINGS.ads[elt.data.name]?.isSticky || false;
              const height = window.APP_SETTINGS.ads[elt.data.name]?.height || 0;

              return <ErrorBoundary>
                <MarginWrapper>
                  {window.APP_SETTINGS.ads[elt.data.name]?.firework && !elt.data.only && <Adslot name={`${elt.data.name}_firework`} />}
                  {isSticky ?
                    <AdslotSticky {...elt.data} name={elt.data.name} data={elt.data} disableFirework={article.disableFirework} disableAdditionalOnnetworkPlayer={article.disableAdditionalOnnetworkPlayer} initialHeight={height} topOffset={0} isStickyPaddingTop={0} />
                    : <Adslot {...elt.data} disableFirework={article.disableFirework} disableAdditionalOnnetworkPlayer={article.disableAdditionalOnnetworkPlayer} />
                  }
                </MarginWrapper>
              </ErrorBoundary>
            case 'block':
              return <ErrorBoundary>
                <Block settings={elt.data} article={article} hideAdslots={hideAdslots} />
              </ErrorBoundary>
            case 'live-item':
              return <ErrorBoundary>
                <LiveItem settings={elt.data} article={article} hideAdslots={hideAdslots} />
              </ErrorBoundary>
            case 'body-react-twitter':
              return <ErrorBoundary>
                <TwitterPost dataId={elt.data} />
              </ErrorBoundary>
            case 'body-react-gallery':
              return <ErrorBoundary>
                <Gallery settings={elt.data} />
              </ErrorBoundary>
            case 'body-quote':
              return <ErrorBoundary>
                <Quote content={elt.data} key={index} />
              </ErrorBoundary>
            case 'body-paragraph':
              return <ErrorBoundary>
                <HtmlParser data={elt.data} key={index} disableNofollow={this.props.article.disableNofollowExtra} />
              </ErrorBoundary>
            case 'html':
              return <ErrorBoundary>
                <HtmlParser data={elt.data} key={index} />
              </ErrorBoundary>
            case 'slideshow':
              return <ErrorBoundary>
                <Slideshow slides={elt.data.slides} key={index} />
              </ErrorBoundary>
            case 'lead':
              return <ErrorBoundary>
                {!article.disableLead && <Lead text={elt.data} hideAdslots={hideAdslots} key={index} />}
              </ErrorBoundary>
            case 'onnetwork':
              if (hideAdslots)
                return null;

              return <ErrorBoundary>
                <OnnetworkVideo mid={elt.data.id} hookPlayer={elt.data.hookOnNetworkPlayer} />
              </ErrorBoundary>
            case 'omni-calc':
              return <OmniCalculator />
            case 'related-article':
              return <ErrorBoundary>
                <RelatedArticle article={elt.data} />
              </ErrorBoundary>
            case 'quote':
              return <ErrorBoundary>
                <RichQuote quoteData={elt.data} key={index} />
              </ErrorBoundary>
            case 'background':
            case 'background_video':
              return <>
                <BackgroundImage blockData={elt.data}
                  articleDate={article.date}
                  articleStats={article.stats}
                  type={elt.type}
                  key={index} />
              </>
            case 'atavist_authors':
              return <AtavistAuthors article={article} blockData={elt.data} />
            case 'atavist_footnotes':
              return <FootnotesAtavist footnotes={elt.data.footnotes} />
            case 'video_reportage_atavist':
              return <VideoReportageAtavist blockData={elt.data} />
            case 'atavist_brid_info':
              return <AtavistBridInfo href={elt.data.href} />
            case 'atavist_breakline':
              return <BreakLine />
            case 'vertical':
              return <ImgWithDesc blockData={elt.data} key={index} />
            case 'image':
              return <Image blockData={elt.data} key={index} />
            case 'paragraph':
              return <Paragraph paragraph={elt.data} key={index} />
            case 'header':
              return <Header header={elt.data} key={index} />
            case 'carousel':
              return <Carousel blockData={elt.data} key={index} />
            case 'embed':
              return <ErrorBoundary>
                <Embed blockData={elt.data} key={index} />
              </ErrorBoundary>
            case 'compare':
              return <ImageCompare blockData={elt.data} key={index} />
            case 'collage':
              return <Collage collage={elt.data} key={index} />
            case 'download_button':
              return <DownloadButton blockData={elt.data} key={index} />
            case 'last_element':
              return <LastElement blockData={elt.data}
                articleStats={article.stats}
                key={index} />
            case 'brid_info':
              return <BridInfo blockData={elt.data} key={index} />
            case 'brid_about':
              return <BridAbout blockData={elt.data} key={index} />
            case 'brid_authors':
              return <BridAuthors blockData={elt.data} key={index} />
            case 'brid_checkbox_list':
              return <BridShoppingList blockData={elt.data} key={index} />
            case 'hardcover_fact_frame':
              return <HardcoverFactFrame blockData={elt.data} key={index} />
            case 'calculator':
              return <Calculator />
            case 'atavist_testing_header':
              return <AtavistTestingHeader link={elt.data.href} key={index} />;
            case 'atavist_testing_info':
              return <AtavistTestingInfo title={elt.data.title} text={elt.data.text} src={elt.data.backgroundSrc} link={elt.data.href} key={index} />;
            case 'atavist_testing_quote':
              return <AtavistTestingQuote author={elt.data.author} text={elt.data.text} img={elt.data.img} key={index} />;
            case 'atavist_testing_slider':
              return <AtavistTestingSlider blockData={elt.data} key={index} />;
            case 'atavist_testing_footer':
              return <AtavistTestingFooter link={elt.data.href} key={index} />;
            case 'link_changer':
              return <LinkChanger msg="article changer" url={article.url} data={elt.data}>
                <div style={{ width: 1, height: 1, marginBottom: 0 }} />
              </LinkChanger>
            case 'ranking_io':
              return <RankingIo medals={elt.data.medal_table} key={index} />
            // atavist storyline/animate
            case 'paragraph-animated':
              return <ParagraphAnimated paragraph={elt.data} key={index} />
            case 'paragraph-animated-reversed':
              return <ParagraphAnimated reserved={true} paragraph={elt.data} key={index} />
            case 'paragraph-with-image-animated':
              return <ParagraphWithImageAnimated blockData={elt.data} key={index} />
            case 'paragraph-issue-4282':
              return <ParagraphIssue4282 paragraph={elt.data} key={index} />
            case 'image-animated':
              return <ImageAnimated blockData={elt.data} key={index} />
            default:
              // return <div>PART NOT RECOGNIZED: {elt.type}</div>;
              return null;
          }
        })}
      </div>
    );
  }
}

export default Parts;
