import React, { useState, useRef, useEffect } from 'react';
import styled from "@emotion/styled"
import { EmbeddedVideo } from "react-facebook";
import OriDomi from "oridomi"

import Link from "../../components/Link";
import ArticleLinks from "../../components/common/ArticleLinks";

import PlayIcon from "../assets/icons/player-icon.svg";
import { ReactComponent as PlayIconWithoutColor } from "../assets/icons/player-icon-active-without-color.svg";
import PlayIconActive from "../assets/icons/player-icon-active.svg";
import Media from "../../components/common/Media"
import Balls from '../../redesign/components/Balls';
import IconMamadu from "../assets/icons/jakosc_mamaDu_etykieta.svg";
import HotNews from "../assets/icons/hot-news.svg";

import UniversalEmbedBox from "./UniversalEmbedBox";

const TextOnPicture = props => {

  const [progress, setProgress] = useState(0)
  const ref = useRef()
  const fold = useRef();
  const { article, aspectRatio: { mobile = 1.78, desktop = 1.67 } = {} } = props;
  const isDesktop = window.innerWidth > 996;
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const label = (article?.category && article.category?.name ? <Label><Balls /> {article.category.name}</Label> : null);
  const player = (parseInt(article.player) === 1 ? <Player><PlayIconWithoutColor /></Player> : null);
  const iconTesting = (article.icon === "TESTUJEMY" ? <IconTesting></IconTesting> : null);

  var imageHeight = (props.imageHeight ? props.imageHeight : 350);
  var imageWidth = 0;

  // if (props.preserveImageRatio && window.innerWidth > 991) {
  //   imageHeight = 0;
  //   imageWidth = 1170;
  // }
  var imgUrl = article.picture.img || '/';
  imgUrl = imgUrl.replace("780,0,0,0", `${imageWidth},${imageHeight},0,0`);

  var partnerLogo = null;
  if (article.partner_logo) {
    var partnerLogoImg = window.MEDIA_DOMAIN + '/' + article.partner_logo.replace('.', ',90,90,0,0.');
    partnerLogo = (
      <ArticlePartner>
        Partnerem materiału jest
        <img src={partnerLogoImg} alt="" />
      </ArticlePartner>
    );
  }


  const aspectRatio = isDesktop ? desktop : mobile;

  const onLoadFinished = () => setTimeout(() => {
    if (props.samsung) {
      fold.current = new OriDomi(ref.current, {
        vPanels: props.samsung.fold ? 2 : 1,
        hPanels: props.samsung.flip ? 2 : 1,
        shading: false,
        maxAngle: 70,
        touchEnabled: window.innerWidth > 996 ? true : false,
        speed: 0
      });
      fold.current.accordion(window.innerWidth > 996 ? 25 : 0, props.samsung.flip ? 'top' : 'left')
    }
  }, 0)

  const handleScroll = () => {
    if (window.innerWidth > 996) return;
    const { bottom } = ref.current.getBoundingClientRect()
    if (bottom > 0 && bottom < window.innerHeight) {
      let prog = Math.round(Math.abs(bottom - window.innerHeight) / window.innerHeight * 100)
      if (progress != prog) {
        setProgress(prog)
      }
      fold.current?.accordion && fold.current.accordion(progress)
    }
  }

  useEffect(() => {
    if (props.samsung) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (props.samsung) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [progress])

  return (
    <>
      {article.embed && article.embed.type ?
        <UniversalEmbedBox article={article} style={props.style} isEmbedTextOnPhoto={true} isOneSuper={props.isOneSuper} />
        :
        <BoxWrapper ref={ref} className={"text-on-picture style--" + props.style} bg={imgUrl} imageHeight={imageHeight} preserveImageRatio={props.preserveImageRatio}>
          <Link to={article.url}>
            <ImageWrapper>
              <Media
                media={props.media}
                src={article.picture.img}
                preserveRatio={props.preserveImageRatio ? true : false}
                aspectRatio={props.preserveImageRatio ? undefined : aspectRatio}
                onLoadFinished={onLoadFinished}
              />
              {player}
              {partner}
              {iconTesting}
              {partnerLogo}
              {article?.iconHotNews && <IconHotNews />}
            </ImageWrapper>
          </Link>

          <Link className="link-footer" to={article.url}>
            <Footer className={article.show_links && article.show_links !== 'off' ? 'show-links top-link-footer' : 'top-link-footer'} size={props.size} >
              <div className={props.size == 'lg' ? 'container' : ''}>
                <FooterSpacer size={props.size}>
                  <Title className="text-on-picture__title" size={props.size}>
                    <span className={"embedLabel" + " embedLabel--" + article.categoryColor}>{label}</span>
                    {article.pile_title || article.title}
                  </Title>
                  <ArticleLinks links={article.links} show={article.show_links} />
                </FooterSpacer>
              </div>
            </Footer>
          </Link>

        </BoxWrapper>
      }
    </>
  );
};

const FooterSpacer = styled.div`
  @media (min-width: 996px) {
    width: ${({ size }) => size === 'lg' ? '50%' : '75%'};
  }
`;

const Title = styled.span`
  display: inline;
  font-weight: 700;
  color: #131212;
  background-color: #FFFFFF;
  font-size: 24px;
  letter-spacing: 0.2px;
  /*letter-spacing: -0.32px;*/
  line-height: 32px;
  /* display: block; */
  position: relative; 
  pointer-events: none; 
  width: 100%;
  @media (min-width: 768px) {
    font-size: ${({ size }) => size === 'lg' ? '38px' : '24px'};
    line-height: ${({ size }) => size === 'lg' ? '52px' : '32px'};
  }
  @media (min-width: 996px) {
    padding-left: 2px;
    padding-right: 2px;
    box-shadow: 2px 0 0 #fff, -2px 0 0 #fff;
  }
  > svg {
    display: block;
    position: absolute;
    top: -11px;
    left: -39px;
    height: 48px;
    width: 22px;
    @media (max-width: 996px) {
      display: none;
    }
  }  

  .embedLabel {
    &--mamadu {
      span {
        background: #62CACB !important; 
      }
    }
    &--dadhero {
      span {
        background: #3376F6 !important;
      }
    }
    &--natemat {
      span {
        background: #FF6400 !important;
      }
    }
    &--innpoland {
      span {
        background: #459B2B !important;
      }
    }
    &--aszdziennik {
      span {
        background: #EA3A3E !important;
      }
    }
    &--red {
      span {
        background: #EA3A3E !important;
      }
    }
    &--black {
      span {
        background: #131212 !important;
      }
    }
    &--primary {
      span {
        background: ${props => props.theme.mainColor} !important;
      }
    }
  }

  /* opcja z małym tekstem, wykorzystywana w special 1d/1m */
  @media (min-width: 996px) {
  ${props => props.size === 'sm' && ({
    fontSize: '15px',
    lineHeight: '18px',
    '> svg': {
      display: 'none'
    }
  })};
  }
`;

const Label = styled.span`
  display: flex;
  align-self: center;
  position: absolute;
  top: -34px;
  white-space: nowrap;
  padding: 2px 4px 2px 16px;
  background: ${props => props.theme.mainColor};
  color: white;
  line-height: 16px;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.8px;
  z-index: 4;
  @media (min-width: 996px) {
    left: -2px;
    top: -30px;
  }
`;

const IconTesting = styled.div`
  position:  absolute;
  right: 10px;
  top: 0px;
  width: 60px;
  height: 90px;
  background-image: url(${IconMamadu});
  background-size: cover;
  z-index: 10;
`;


const BoxWrapper = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 150px; */
  margin-bottom: 24px;
  @media (min-width: 768px) {
    overflow: hidden;
    margin-bottom: 32px;
    /**margin-bottom: 88px;*//
  }

  @media (min-width: 996px) {
    min-height: ${({ imageHeight }) => imageHeight}px;
  }

  /* background: url(${props => props.bg}) no-repeat center center; */
  /* background-size: cover; */
  /* background-blend-mode: multiply; */

  > a {
    display: block;
    text-decoration: none;
    z-index: 1;
    left: 0; right: 0;
    top: 0; bottom: 0;
    min-width: 100%;
    position: ${({ size }) => size === 'lg' ? 'absolute' : 'static'};
    height: ${({ imageHeight }) => imageHeight + 'px'};
    @media (min-width: 996px) {
      height: auto;
      position: absolute;
      /* position: ${props => (props.preserveImageRatio ? 'static' : 'absolute')}; */
    }
  }

  .link-footer {
    height: auto;
    position: static;
  }

  circle {
    transition: all .2s linear;
  }

  &:hover {
    ${Title} {
      color: ${props => props.theme.mainColor};
      text-decoration: none;
      cursor: pointer;
    }
    circle {
      fill: #000;
      stroke: #000;
    }
  }
  @media (min-width: 996px) {
    padding-bottom: 0;
  }

  // modyfikatory z BO
  &.style--red {
    ${Title} {
      color: white;
      background: #EA3A3E;
      box-shadow: 2px 0 0 red, -2px 0 0 #EA3A3E;
    }
    ${Label} {
      color: white;
      background: #EA3A3E;
    }
    &:hover {
      ${Title} {
        color: #ffeeee;
      }
    }
  }

  &.style--black {
    ${Title} {
      color: white;
      background: #131212;
      box-shadow: 2px 0 0 black, -2px 0 0 #131212;
    }
    ${Label} {
      color: white;
      background: #131212;
    }
    &:hover {
      ${Title} {
        color: #ffeeee;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
 img {
    width: 100%;
    height: 350px;
    min-height: 100%;
    display: block;
    object-fit: cover;
  }

  > .preserveImageRatioImage {
    display: none;
  }
  
  @media (min-width: 996px) {
  	display: block;

    > .preserveImageRatioImage {
      width: 100%;
      display: ${props => (props.preserveImageRatio ? 'block' : 'none')};
      min-height: ${props => (props.preserveImageRatio ? 150 : props.imageHeight)}px;
    }
  }

`;

const Partner = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0px 8px;
  background-color: #D8D8D8;
	color: #8B8B8B;
  line-height: 16px;
  font-size: 11px;
  z-index: 4;
`;

const Player = styled.span`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  /*background: transparent url(${PlayIcon}) no-repeat center center;*/
  background-size: 64px auto;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    .color-border-path {
      fill: #fff;
    }
  }

  &:hover {
      /*background: transparent url(${PlayIconActive}) no-repeat center center;*/
    svg {
      .color-path {
        fill: ${props => props.theme.mainColor};
      }
      .color-border-path {
        fill: ${props => props.theme.mainColor};
      }
    }
  }
`;

const ArticlePartner = styled.span`
  font-size: 10px;
  color: #eee;
  position: absolute;
  right: 10px;
  top: 10px;
  img {
    width: 40px;
    margin-left: 6px;
  }
`;

const IconHotNews = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 36px;
  height: 36px;
  background-image: url(${HotNews});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #EA3A3E;
  z-index: 3;
`;

const Footer = styled.span`
  display: block;
  margin-top: 16px;
  /* align-self: flex-end; */
  /* padding: 24px; */
  /* position: absolute; */
  z-index: 2;
  pointer-events: none;
  /* width: ${({ blockType }) => blockType === 'b-one' ? '50%' : '100%'}; */

  @media (max-width: 996px) {
     .embedLabel {
      span {
        top: -56px;
        left: 16px;
      }
    }

    ${({ size }) => `
    ${size === 'lg' && `
      position: absolute;
      left: 0; right: 0;
      bottom: 24px;

      .embedLabel {
        span {
          top: -34px;
          left: unset;
        }
       }
    `}
  `}
}
  @media (min-width: 996px) {
    position: absolute;
    left: 0; 
    right: 0;
    bottom: 0;
    padding-left: ${props => props.size === "lg" ? '0' : '26px'} ;
    padding-right: ${props => props.size === "lg" ? '0' : '26px'} ;
    padding-bottom: ${props => props.size === "lg" ? '80px' : '24px'};

    /* padding: 50px 30px 48px 65px; */
  	
	  /*na potrzeby art. powiązanych desktop */
  	&.show-links {
		  padding-left: 250px; 
	  }

	  /*na potrzeby art. powiązanych desktop na prawej / Marek Belka*/
    &.show-links--on-right{
      display: flex;
      align-items: flex-end;
      padding: 50px 30px 48px 65px;
    }

    /* opcja z małym tekstem, wykorzystywana w special 1d/1m */
    ${props => props.size === 'sm' && ({
    padding: '0 15px 35px 15px;',
  })}
    
  }
  
  /* right: 10px; */
`;


export default TextOnPicture