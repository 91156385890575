import React, { Fragment } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
/** @jsx jsx */ import { jsx, css, Global } from "@emotion/core";
import { observer } from "mobx-react";
import YoutubeVideo from "./YoutubeVideo";
import closeIcon from "../../../../assets/img/close-white.svg";
import playIcon from "../../../../assets/img/play-button.svg";

import { withlazyload } from "../../../HOCs/WithLazyLoad";

@withlazyload({
  height: 350,
  once: true,
  offset: 500
})
@observer
export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryVisible: false
    };
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        <YoutubeVideo dataId={item.embedUrl} description={item.description} />
      </div>
    );
  }
  componentDidMount() {
    this.props.settings.forEach(galleryObj => {
      if (galleryObj.embedUrl) {
        galleryObj.renderItem = this._renderVideo.bind(this);
      }
    });
  }

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({ galleryVisible: false });
    }
  };

  render() {
    const { settings } = this.props;
    if (!settings || settings.length === 0) {
      return null;
    }

    if (settings.length === 1 && settings[0].embedUrl) {
      return <YoutubeVideo dataId={settings[0].embedUrl} description={settings[0].description} />
    }

    return (
      <Fragment>
        <Global styles={css`
      .image-gallery.fullscreen-modal {
        z-index: 11;
        background: #151515f7;
      }
      .image-gallery-slides {
        overflow: visible;
      }
      .image-gallery-description {
        font-weight: bold;
        width: 100%;
        transform: translateY(100%);
        bottom: 0!important;
        background: none!important;
      }
      .image-gallery-index {
        font-weight: bold;
        left: 0;
        right: auto;
      }`} />
        <div
          onClick={() => this.setState({ galleryVisible: true })}
          css={css`
            position: relative;
            cursor: pointer;
          `}
        >
          <img
            css={css`
              width: 100%;
            `}
            alt=""
            src={
              settings[0].original
                ? settings[0].original
                : `https://img.youtube.com/vi/${settings[0].embedUrl
                }/sddefault.jpg`
            }
          />
          {settings[0].embedUrl && (
            <div
              css={css`
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-image: url(${playIcon});
                background-size: 80px;
                background-repeat: no-repeat;
                background-position: center;
                background-color: rgba(10, 10, 10, 0.1);
                cursor: pointer;
              `}
            />
          )}

          {settings.length > 1 && (
            <div
              css={css`
                position: absolute;
                bottom: 0;
                right: 0;
                color: #fff;
                letter-spacing: 0.5px;
                padding: 5px 15px;
                width: 100%;
                text-align: right;
                background: rgba(10, 10, 10, 0.6);
              `}
            >
              {`Zobacz pełną galerię (${settings.length})`}
            </div>
          )}
        </div>
        <div
          css={css`
            font-size: 14px;
            padding: 12px 0px 0px 0px;
            font-weight: 600;
            line-height: 18px;
            color: #8D8D8D;
            text-align: center;
          `}
        >
          {settings[0].description || ""}
        </div>
        {this.state.galleryVisible && (
          <GalleryWrapper
            images={settings}
            hideGallery={() => this.setState({ galleryVisible: false })}
          />
        )}
      </Fragment>
    );
  }
}

class GalleryWrapper extends React.Component {
  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.props.hideGallery();
    }
  };
  componentDidMount() {
    document.body.style.overflow = "hidden";
    window.addEventListener("keydown", this.handleKeyDown);
    this.refs.Gallery._toggleFullScreen();
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <Fragment>
        <ImageGallery
          lazyLoad={true}
          showFullscreenButton={false}
          items={this.props.images}
          showThumbnails={false}
          showPlayButton={false}
          preventDefaultTouchmoveEvent={true}
          useBrowserFullscreen={false}
          showIndex={true}
          ref="Gallery"
        />
        <img
          src={closeIcon}
          onClick={this.props.hideGallery}
          alt=""
          css={css`
            position: fixed;
            top: 23%;
            right: 20px;
            width: 25px;
            height: 25px;
            display: block;
            cursor: pointer;
            z-index: 12;
          `}
        />
      </Fragment>
    );
  }
}
