import React, { useState } from "react";
import { EmbeddedPost } from "react-facebook";
import styled from "@emotion/styled";

import WithLazyLoad from "../../../HOCs/WithLazyLoad"
import AnimateHeightWrapper, { AnimateWrapperButton } from "../../Parts/Brid/common/components/AnimateHeightWrapper";

const FacebookPost = (props) => {
  const [visibility, setVisibility] = useState(false)
  const ref = (node) => {
    if (node !== null) {
      const interlvalId = setInterval(() => {

        const height = node.children[0]?.children[0]?.children[0]?.getBoundingClientRect().height;
        if (typeof height !== "undefined" && height > 100) {
          console.log({ fb_height: height })
          if (height > 300) {
            setVisibility(true);
          }

          clearInterval(interlvalId);
        }
      }, 1000);
    }
  };


  return (
    <WithLazyLoad options={{
      height: 480,
      once: true,
      offset: 500
    }}>
      <AnimateHeightWrapper type={AnimateWrapperButton.ShowMore} height={300} visibility={visibility}>
        <CenteredEmbed ref={ref}>
          <EmbeddedPost href={props.href} />
        </CenteredEmbed>
      </AnimateHeightWrapper>
    </WithLazyLoad>
  );
}

export default FacebookPost;

const CenteredEmbed = styled.div`
  text-align: center;
  .fb-post > span {
    max-width: 100%;
  }
  .fb-post iframe {
    border: 0;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    background: #fff;
  }
`;