import React, { useRef, useState } from "react";
import TweetEmbed from "react-tweet-embed";
import styled from "@emotion/styled";

import WithLazyLoad from "../../../HOCs/WithLazyLoad";
import AnimateHeightWrapper, { AnimateWrapperButton } from "../../Parts/Brid/common/components/AnimateHeightWrapper";

const TwitterPost = (props) => {

  const [visibility, setVisibility] = useState(false)

  const ref = (node) => {
    if (node !== null) {

      let interlvalId = setInterval(() => {
        let height = node._div.children[0]?.getBoundingClientRect().height;
        if (height != undefined && height > 0) {
          if (height > 450) {
            setVisibility(true);
          }

          clearInterval(interlvalId);
        }
      }, 1000);
    }
  };


  return (
    <WithLazyLoad options={{
      height: 480,
      once: true,
      offset: 500
    }}>
      <AnimateHeightWrapper type={AnimateWrapperButton.ShowMore} height={450} visibility={visibility}>
        <CenteredEmbed>
          <TweetEmbed options={{ lang: "pl" }} id={props.dataId} ref={ref} />
        </CenteredEmbed>
      </AnimateHeightWrapper>

    </WithLazyLoad>
  );
}

export default TwitterPost;


const CenteredEmbed = styled.div`
  & > div > .twitter-tweet {
    margin: 10px auto !important;
  }
`;