import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

//eslint-disable-next-line
import AdStyles from "./styles/ad.scss";

import { AuthContext } from '../context/AuthContext';

// reklamy
// import Adform from "./Adslots/Adform";
// import Google from "./Adslots/Google";
import PlistaOutstream from "./Adslots/PlistaOutstream";
import FeatureFlagAdContainer from "./FeatureFlagAdContainer"

import ConditionalWrapper from "../helpers/ConditionalWrapper";

import { pushDataLayer, adSlotEnum, isEqualToKeyOrValue } from "../helpers"
import OnnetworkPlayer from "./OnnetworkPlayer";
import Lazyload from "react-lazyload";

const FireworkWrapper = React.lazy(() => import(/* webpackChunkName: "Adslots__FireworkWrapper" */"./Adslots/FireworkWrapper"));
const IkeaWrapper = React.lazy(() => import(/* webpackChunkName: "Adslots__IkeaWrapper" */"./Adslots/IkeaWrapper"))
const ACountdownSamsung = React.lazy(() => import(/* webpackChunkName: "composition-components__block__ACountdownSamsung" */"./composition-components/block/ACountdownSamsung"));

/**
 * id slotów reklamowych, z index.html
 */
const slot_mid = window.APP_SETTINGS.ads;

@inject("UIStore")
@observer
class Adslot extends Component {
  static contextType = AuthContext;
  static slotCounter = 0;

  wallpaperInterval = null;
  testCounter = 0;
  slotNumber = 0;
  @observable
  emptyAdform = false;
  resizeObserver = null;
  prevAdHeight = 0;
  checkWallpaper = () => {
    if (this.testCounter > 10) {
      clearInterval(this.wallpaperInterval)
      return;
    }

    const wallpaperRegex = /(adform)/gi;
    //sprawdza czy do tagu <html> została dodana klasa ze skryptu reklamowego
    let isWallpaper = Array.from(document.documentElement.classList).filter(val => wallpaperRegex.test(val)).length > 0;
    isWallpaper |= document.body.classList.contains('screening_wallpaper');
    if (isWallpaper) {
      this.testBg();
      clearInterval(this.wallpaperInterval)
    } else {
      return null;
    }
  }

  pushEvent = async (event) => {
    const { name } = this.props
    // console.log(`pushing ${name}:${event}`)
    await window._gaq.push(["_trackEvent", "ADSLOT", name, event])
  }

  componentDidMount() {
    const { name } = this.props;
    const { googletag } = window;
    const isDesktop = window.innerWidth > 996;
    const slot = (slot_mid[name] || {});

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        const gptSlotId = event.slot.getSlotElementId()
        if (isEqualToKeyOrValue(gptSlotId, name, adSlotEnum)) {
          pushDataLayer({
            event: "analyticsEvent",
            eventCategory: "Adslots",
            eventAction: name,
            eventLabel: window.location.pathname,
            eventValue: undefined
          })
        }
      });
    })

    // ustawienia szarej zaślepki
    if (this?.adElement?.style) {
      const currentTopics = this.props.UIStore?.googleAdKeyWords?.topics || [];
      const isMaterialPartnera = (currentTopics.indexOf('material-partnera') >= 0);
      // console.log('Adslot googleAdKeyWords', isMaterialPartnera, name, slot, currentTopics);

      let placeholderSize = null;
      if (isMaterialPartnera) {
        placeholderSize = [0, 0];
        this.wrapperElement.classList.remove('adslot__ad-wrapper--fixed');
      } else if (isDesktop && slot.placeholder_desktop) {
        placeholderSize = slot.placeholder_desktop.split('x');
      } else if (!isDesktop && slot.placeholder_mobile) {
        placeholderSize = slot.placeholder_mobile.split('x');
      }

      // console.log('placeholderSize', name, placeholderSize);
      if (placeholderSize && placeholderSize.length > 1) {
        this.adElement.style.minWidth = placeholderSize[0] + "px";
        this.adElement.style.minHeight = placeholderSize[1] + "px";
      }
    }

    if ("ResizeObserver" in window) {
      this.resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length || !entries[0].borderBoxSize || !entries[0].borderBoxSize.length) {
            return;
          }

          const { borderBoxSize } = entries[0];
          const height = borderBoxSize[0].blockSize;

          if (this.prevAdHeight === height) {
            // console.log('same height, skipping', name)
            return;
          }

          if (this.prevAdHeight <= 30 && height > 30) {
            // console.log({ name, height })
            this.pushEvent("over30px");
            this.wrapperElement?.classList?.add("adslot--active");

            // #5266 monitorujemy czy coś nam nie wycina reklam, po ich pojawieniu się na ekranie
            setTimeout(() => {
              if (this.wrapperElement) {
                const isActive = this.wrapperElement.classList.contains("adslot--active");
                // console.log('ten seconds passed', name, isActive, this.wrapperElement);

                if (!isActive) {
                  this.pushEvent("disappeared")
                }
              }
            }, 10000)

            // console.log(`observed height is over 30, ${this.props.name}, ${height}`)
            if (name?.indexOf('_Billboard_1') >= 0 && (window.innerWidth > 996)) {
              setTimeout(() => this.testBg(), 500)
            }
          } else if (this.prevAdHeight > 30 && height <= 30) {
            this.wrapperElement?.classList?.remove("adslot--active");
            this.pushEvent("under30px");
          }

          this.prevAdHeight = height;
        })
      })

      this.adElement && this.resizeObserver.observe(this.adElement)
    }

    if (window.innerWidth > 996 && this.props.name?.indexOf('_Billboard_1')) {
      this.wallpaperInterval = setInterval(() => this.checkWallpaper(), 1000);
    }
  }

  componentWillUnmount() {
    this.resizeObserver?.disconnect(this.adElement)
    clearInterval(this.wallpaperInterval);
    document.body.classList.remove("adslot--tapeta");
    window.removeEventListener("scroll", this.recalculateBgPosition);
  }

  componentDidCatch(error, info) {
    console.log('[ES] error w reklamie', error, info);
  }

  /**
   * sprawdza czy jest reklama "tapeta"
   */
  testBg() {
    var hasBG = false,
      body = document.body,
      bgi = window.getComputedStyle(body).backgroundImage;
    if (bgi && bgi !== 'none') {
      // obrazek
      hasBG = true;
      /*
      var imageSrc = bgi
        .replace(/url\((['"])?(.*?)\1\)/gi, '$2')
        .split(',')[0];

      var image = new Image();
      image.src = imageSrc;

      hasBG = (image.height > 199);
      */
    }

    if (hasBG && this.wrapperElement) {
      if (this.props.name.includes("_Billboard_1")) {
        this.wrapperElement.classList.add("adslot--tapeta");
        body.classList.add("adslot--tapeta");
      }

      // obliczanie onScroll, bo nav moze zmienić wysokosc onscroll
      var firstSlot = null;
      const nav = document.getElementsByClassName('nav-fixed');

      const recalculateBgPosition = () => {
        firstSlot = document.getElementsByClassName("adslot__ad-container");
        const navHeight = nav[0].offsetHeight;
        const belkaTopHeight = 75;
        const offsetTop = window.scrollY;
        if (firstSlot[0] && nav[0]) {
          body.style.transition = "all 0s";
          if (offsetTop >= (navHeight + belkaTopHeight) && body.classList.contains("belka-top--active")) {
            body.style.backgroundAttachment = '';
            body.style.setProperty("background-position", `center ${belkaTopHeight}px`, "important"); // belka top
          } else if (offsetTop >= navHeight && !body.classList.contains("belka-top--active")) {
            body.style.backgroundAttachment = '';
            body.style.setProperty("background-position", "center 0px", "important"); // 90px (zwykły offset) - 50px (offsetTop) = 40px
          } else {
            body.style.backgroundAttachment = 'scroll';
            body.style.backgroundPosition = '';
          }
        }
      }

      if (window.getComputedStyle(body).backgroundAttachment == 'fixed') {
        recalculateBgPosition();
        window.addEventListener("scroll", recalculateBgPosition);
      }
    }
  }

  /**
   * monitoruje, czy slot się zapełni czy nie
   * @deprecated
   */
  monitorAdformSlot(mid) {
    const adformtag = window.adformtag || [];
    const adslot = this;

    // console.log('NT:', 'monitorAdformSlot', mid);

    adformtag.push(function () {
      adformtag.onLoaded(mid, function (adItem) {
        // console.log('NT:', 'aditem', adItem);
        if (adItem.isEmpty) {
          // console.log('NT:', mid, 'no ad');
          adslot.emptyAdform = true;
        } else {
          // console.log('NT:', mid, 'has ad, add label');
        }
      });
    });
  }

  render() {
    const isDesktop = window.innerWidth > 996;
    // const [{ user }] = useAuth();
    const authContext = this.context;
    const user = ((Array.isArray(authContext) && authContext[0]) ? authContext[0]?.user : undefined);

    this.slotNumber = (++Adslot.slotCounter);

    if (window.location.hash && window.location.hash === "#SHOW-ADSLOTS") {
      var style = {
        background: "red",
        textAlign: "center",
        padding: "10px",
        // marginTop: "10px",
        // marginBottom: "10px",
      };

      if (this.props.only === 'desktop') {
        style.background = '#FF0055';
      } else if (this.props.only === 'mobile') {
        style.background = '#FF5500';
      }
      return (
        <>
          {this.props.only && window.APP_SETTINGS.ads[this.props.name]?.firework && <Adslot name={`${this.props.name}_firework`} />}
          <div className="adslot__placeholder" style={style}>
            {this.props.only ? this.props.only + ' | ' : null}
            ADSLOT {this.slotNumber}: {this.props.name}: {(slot_mid[this.props.name] ? JSON.stringify(slot_mid[this.props.name]) : 'SLOT_NOT_FOUND')}
          </div>
        </>
      );
    }

    var that = this;
    var slot = (slot_mid[this.props.name] || {});
    // console.log('ADSLOT', this.props.name, this.emptyAdform, slot);
    // console.log(this.props.name)
    var ad = [];
    if (user && user.valid) {
      return null;
    } else if (slot.disabled) {
      // slot is disabled
    } else if (slot.samsung) {
      ad.push(<ACountdownSamsung />)
    } else if (slot.ikea) {
      ad.push(<IkeaWrapper />)
    } else if (this.props.only) {
      if ((isDesktop && this.props.only === "desktop") || (!isDesktop && this.props.only === "mobile")) {
        // slot do liczenia odsłon fireworka w adserwerze
        if (window.APP_SETTINGS.ads[this.props.name]?.firework) {
          ad.push(<Adslot name={`${this.props.name}_firework`} />)
        }
        if (slot.firework && !this.props.disableFirework) {
          ad.push(<FireworkWrapper name={this.props.name} slot={slot} />)
        } else if (slot.type === "onnetwork" && !this.props.disableAdditionalOnnetworkPlayer) {
          ad.push(<OnnetworkPlayer mid={slot.sid} />)
        } else {
          ad.push(<FeatureFlagAdContainer key={`adlib_${isDesktop ? 'desktop' : 'mobile'}_${this.props.name}`} name={this.props.name} />)
        }
      }
    } else if (slot.type === "onnetwork" && !this.props.disableAdditionalOnnetworkPlayer) {
      ad.push(<OnnetworkPlayer mid={slot.sid} />)
    } else if (slot.firework && !this.props.disableFirework) {
      ad.push(<FireworkWrapper name={this.props.name} slot={slot} />)
    } else if (slot.plista_outstream) {
      // console.log("plista defined here:", this.props.name)
      ad.push(<PlistaOutstream publicKey={slot.plista_outstream} />);
    } else if (isDesktop) {
      /* na razie zostawiamy adlib event*/
      // desktop
      ad.push(<FeatureFlagAdContainer key={this.props.name} name={this.props.name} />)
    } else {
      // mobile
      ad.push(<FeatureFlagAdContainer key={this.props.name} name={this.props.name} />)
    }

    if (ad.length == 0) {
      return null;
    }

    const result = (
      <ConditionalWrapper
        condition={this.props.name?.startsWith("ART_intext_")}
        wrapper={children => <aside>{children}</aside>}
      >
        <div className="adslot__ad-container" data-name={this.props.name}>
          <div
            ref={element => { this.wrapperElement = element; }}
            className={
              "adslot__ad-wrapper" +
              ((slot.fixed === true || (isDesktop && slot.placeholder_desktop) || (!isDesktop && slot.placeholder_mobile)) ? " adslot__ad-wrapper--fixed" : "") +
              (slot.placeholder === true ? " adslot__ad-wrapper--fixed" : "") +
              (slot.gray === true ? " adslot__ad-wrapper--gray" : "") +
              (slot.block === true ? " adslot__ad-wrapper--block" : "")
            }
          >
            {slot.block || <div className="adslot__ad-label">
              Reklama. <span>{this.props.description}</span>
            </div>}
            <div
              className={"adslot__ad-container adslot__ad-container--" + window.BRAND}
              ref={el => this.adElement = el}
            >
              {ad}
            </div>
          </div>
        </div>
      </ConditionalWrapper>)

    if (slot.lazy === true) {
      return <LazyLoad height={300} offset={300}>{result}</LazyLoad>;
    } else {
      return result;
    }

  }
}
export default Adslot;
