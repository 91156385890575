import React from 'react';
import { styled } from "../stitches.config";
import Link from "../components/Link";
import { bodyLarge } from "./commonStyles/Fonts";
import AccountIcon from "./assets/icons/myaccount.svg";


const CtaAccountButton = () => (
    <StyledCtaAccountButton className={bodyLarge()}>
        <Link data-analytics-name="article-cta-account-btn" to='https://natemat.pl/423181,twoje-natemat-to-portal-bez-reklam-rewolucyjna-zmian-w-natemat-pl?BT='>
            <StyledHomeIcon></StyledHomeIcon>
            Chcesz czytać naTemat.pl bez reklam i personalizować portal? Załóż konto "Twoje naTemat"
        </Link>
    </StyledCtaAccountButton>
);



const StyledCtaAccountButton = styled('div', {
    "a": {
        width: "100%",
        margin: "0 auto 18px auto",
        color: "$grayscale0Fixed !important",
        backgroundColor: "$primary",
        border: "2px solid $primary",
        cursor: "pointer",
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "$primary120",
            border: "2px solid $primary120",
        },
    }
})

const StyledHomeIcon = styled('div', {
    display: "flex",
    marginRight: "16px",
    width: "22px",
    height: "22px",
    backgroundImage: `url(${AccountIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexShrink: 0,
})

export default CtaAccountButton