import React from 'react';
import styled from "@emotion/styled";
import { observer } from 'mobx-react'
import HtmlParser from '../Content/HtmlParser'

import { useStores } from '../../../helpers/useStores'


const Paragraph = observer(({ paragraph }) => {
    const { text } = paragraph;
    const { ArticleStore } = useStores();

    if (ArticleStore.article.display_type === "BRID") {
        return (
            <BridWrapper>
                <HtmlParser data={text} />
            </BridWrapper>
        )
    }

    if (ArticleStore.article.display_type === "HARDCOVER") {
        return (<HardcoverWrapper>
            <HtmlParser data={text} />
        </HardcoverWrapper>)
    }

    return (
        <BizonWrapper>
            <HtmlParser data={text} disableNofollow={ArticleStore.article.disableNofollowExtra} />
        </BizonWrapper>
    )
})

const Wrapper = styled.div`
    margin: 20px 38px;
    color: #262626;	
    font-size: 17px;	
    font-weight: lighter;	
    letter-spacing: -0.34px;	
    line-height: 23px;

    a {
        color: rgb(255, 102, 0) !important;
        &:hover{
            text-decoration: none;
            }
    }

    @media (min-width: 992px) {
        max-width: 660px;
        margin: 20px auto;
        color: #222222;
        font-size: 18px;
        font-weight: 400;	
        letter-spacing: -0.3px;	
        line-height: 28px;
    }
`

const BizonWrapper = styled.div`
    margin: 20px 38px;
    /*$grayscale100*/
    color: #131212;	
    font-size: 16px;	
    font-weight: 400;	
    letter-spacing: 0.1px;	
    line-height: 26px;
    font-family: "CharisSIL";
    a {
        color: rgb(255, 102, 0) !important;
        &:hover{
            text-decoration: none;
            }
    }

    @media (min-width: 996px) {
        max-width: 840px;
        margin: 20px auto;
        font-size: 21px;
        letter-spacing: 0px;
        line-height: 32px;
    }
`


const BridWrapper = styled.div`
    margin: 0 auto;
    letter-spacing: normal;
 
    div {
        font-family: HK Grotesk;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-left: 0;
        margin-right: 0;
        padding: 10px 16px;

        @media (min-width: 996px) {
            padding: 0px 48px;
        }
    }

    a{
        color: rgb(255, 102, 0) !important;
        &:hover{
            text-decoration: none;
        }
    }
`

const HardcoverWrapper = styled.div`
    margin: 19px 35px;
    font-size: 18px;
     font-family: "CharisSIL";
    color: #1e202e;
    max-width: calc(100vw - 70px);
    @media (min-width: 992px) {
        margin: 19px 115px;
    }
`

export default Paragraph;