import React, { useEffect, useState } from 'react';
import { Comments, LoginButton } from 'react-facebook';
import { styled } from "../../stitches.config";
import { bodyLarge } from '../commonStyles/Fonts';

import { ReactComponent as FBIcon } from "../assets/icons/social/facebook-white.svg";

interface IFacebookComments {
    href: string;
    numPosts?: number;
    children?: (fbComments: any) => React.ReactNode;
}

const FacebookComments: React.FC<IFacebookComments> = ({ href, numPosts = 2, children }) => {

    //Widget loaded flag
    const [isWidgetLoad, setWidgetLoad] = useState<boolean>(false);
    const [isWidgetEnable, setWidgetEnable] = useState<boolean>(false);

    //Store commentsBox element
    const [commentsBox, setCommentsBox] = useState<Element | null>(null);

    //Update commentsBox on Comment ref change
    const onCommentsBoxUpdate = () => {
        const commentsBox = document.querySelector('#comments .fb-comments');
        if (commentsBox != null)
            setCommentsBox(commentsBox);
    };

    //On commentsBox wait for iframe rendered and check comments load
    useEffect(() => {
        if (commentsBox == null) return;

        var observer = new MutationObserver((m) => m.forEach((mutation) => {
            //Wait for fb-xfbml-state set for rendered (FB comment widget load)
            if (mutation.type !== "attributes" || commentsBox.getAttribute('fb-xfbml-state') != 'rendered')
                return;

            setWidgetLoad(true);
            //When element is ready check overflow when is not hidden comments are enabled
            if (commentsBox.querySelector('span')?.style['overflow'] != 'hidden')
                setWidgetEnable(true);
        }));
        observer.observe(commentsBox, { attributes: true });

        return () => {
            observer.disconnect();
        }
    }, [commentsBox]);

    const fbComments = <Comments ref={onCommentsBoxUpdate} width="100%" numPosts={numPosts} href={href} />;
    //Return wrapper
    return (
        <div id={'comments'}>
            <div style={{ display: isWidgetEnable ? 'block' : 'none' }}>
                {!children ? fbComments : children(fbComments)}
            </div>
            {/* Show fb login when widget is not enabled */}
            {isWidgetLoad && !isWidgetEnable && (
                <FacebookCommentLogin className={`${bodyLarge()} col-lg-8`}>
                    <a href="https://www.facebook.com/login" target="_blank"><FBIcon /> Aby wyświetlić komentarze, musisz być zalogowany do Facebooka.</a>
                </FacebookCommentLogin>
            )}
        </div>
    );

};

export default FacebookComments;

const FacebookCommentLogin = styled('div', {
    width: '100%',
    margin: '1.5rem 0',
    'a': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        margin: "0 auto 18px auto",
        color: "$grayscale0Fixed !important",
        backgroundColor: "$primary",
        border: "2px solid $primary",
        cursor: "pointer",
        padding: "9px 16px",
        textAlign: "center",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "$primary120",
            border: "2px solid $primary120",
        },
        "svg": {
            minWidth: "20px",
            marginRight: "1rem"
        }
    }
})