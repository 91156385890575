import React from 'react';
import { styled } from "./../stitches.config"
import { h1 } from "./commonStyles/Fonts";

interface ArticleTitleProps {
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({
    children,
    ...props
}) => {
    return (
        <StyledArticleTitle className={h1()}>
            {children}
        </StyledArticleTitle>
    );
};

const StyledArticleTitle = styled('h1', {
})

export default ArticleTitle