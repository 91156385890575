import { observable, action, computed } from "mobx";
import { RootModel } from "./RootStore";
import { IPodcast } from '../redesign/types/Podcast';
import axios from "axios";

interface IAuthor {
    name: string;
    description: string;
    img: string;
    mail: string;
};

export interface IPodcastSerie {
    id: number; //PodcastStore id
    title: string;
    author: IAuthor | string;
    lead: string;
    poster: string;
    url: string;
    label?: string;
};

export interface IPodcastSeriesBox {
    name: string;
    poster: string;
    lead: string;
    author: IAuthor | string;
    url: string;
    label?: string;
}

interface IPodcastStore {
    status: string;
    message: string;
    podcastSerie?: IPodcastSerie;
    podcastSerieEpisodes: IPodcast[];
    latestEpisodes: IPodcast[];
    recentSeries: IPodcastSerie[];
};

export default class PodcastStore implements IPodcastStore {

    private RootStore: RootModel;

    currentSlug?: string = undefined;

    @observable
    status = 'valid';
    @observable
    message = '';

    @observable
    podcastSerie: IPodcastSerie | undefined = undefined;

    @observable
    podcastSerieEpisodes: IPodcast[] = [];

    @observable
    latestEpisodes: IPodcast[] = [];

    @observable
    recentSeries: IPodcastSerie[] = [];

    constructor(rootStore: RootModel) {
        this.RootStore = rootStore;
    }

    @action("Get podcast page")
    async fetchPodcasts() {
        this.status = 'loading';

        const { data } = await axios(`${window.API_HOST}/pwa-podcast-series/`);

        this.recentSeries = data.podcast_series.map((serie: any) => ({
            title: serie.name,
            poster: serie.poster.img_scheme,
            lead: serie.description,
            author: serie.author.name,
            url: serie.url
        }));

        this.latestEpisodes = data.last_added.map((podcast: any) => ({
            OID: podcast.OID,
            seriesName: podcast.podcast_series?.name,
            title: podcast.title,
            author: podcast.author.name,
            lead: podcast.lead,
            url: podcast.url,
            date: podcast.date,
            poster: podcast.podcast_series?.poster.img_scheme
        }));

        this.status = 'valid';
    }


    @action("Get podcast series based on current URL")
    updateSlug = async (slug: string) => {
        this.status = 'loading';

        const { data } = await axios(`${window.API_HOST}/pwa-podcast-series/${slug}`);
        if (!data.podcast_series || data.podcast_series.length <= 0) {
            //TODO something
        }

        const podcastData = data.podcast_series[0];

        this.podcastSerie = {
            id: podcastData.oid,
            title: podcastData.name,
            author: {
                name: podcastData.author.name,
                description: podcastData.author.articleDescription || "",
                img: podcastData.author.img,
                mail: podcastData.author.email
            },
            url: '#',
            poster: podcastData.poster.img,
            lead: podcastData.description,

        }

        this.podcastSerieEpisodes = podcastData.episodes.map((e: any) => ({
            OID: e.OID,
            seriesName: podcastData.name,
            title: e.title,
            author: e.author.name,
            lead: e.lead,
            url: e.url,
            date: e.date,
            poster: podcastData.poster.img_scheme,
        }));

        this.status = 'valid';
    }

};