import React from 'react';
import { styled } from "../stitches.config"


interface CategoryBadgeProps {
    sponsored?: boolean;
    showMark?: boolean;
    text: string;
    markText?: string;
    categoryColor?: "default" | "primary" | "black" | "red" | "natemat" | "innpoland" | "aszdziennik" | "dadhero" | "mamadu";
}

const CategoryBadge: React.FC<CategoryBadgeProps> = ({
    sponsored = false,
    showMark = false,
    text,
    markText,
    categoryColor
}) => {
    return (
        <StyledCategoryBadge categoryColor={categoryColor} sponsored={sponsored}>
            <div>{text}</div> {showMark && markText && markText.length > 1 && <span>{markText}</span>}
        </StyledCategoryBadge>
    );
};

const StyledCategoryBadge = styled('div', {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    '& div,& span': {
        borderRadius: "0px",
        backgroundColor: "$primary",
        color: "$grayscale0",
        fontFamily: "$grotesk",
        fontWeight: "$8",
        fontSize: "12px",
        lineHeight: "16px",
        padding: "2px 4px 2px 6px",
        whiteSpace: "nowrap"
    },
    '& div': {
        paddingLeft: "16px",
        position: "relative",
        '&:after,&:before': {
            content: "",
            position: "absolute",
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            backgroundColor: "$grayscale0",
        },
        '&:after': {
            left: "6px",
            top: "4px"
        },
        '&:before': {
            left: "6px",
            top: "11px"
        }

    },
    '& span': {
        marginLeft: "6px",
    },
    variants: {
        sponsored: {
            true: {
                '& div,& span': {
                    backgroundColor: "$grayscale100",
                }
            },
        },
        categoryColor: {
            default: {
            },
            primary: {
                '& div,& span': {
                    backgroundColor: "$primary",
                }
            },
            black: {
                '& div,& span': {
                    backgroundColor: "$grayscale100",
                }
            },
            red: {
                '& div,& span': {
                    backgroundColor: "#EA3A3E",
                }
            },
            innpoland: {
                '& div,& span': {
                    backgroundColor: "#459B2B",
                }
            },
            dadhero: {
                '& div,& span': {
                    backgroundColor: "#3376F6",
                }
            },
            mamadu: {
                '& div,& span': {
                    backgroundColor: "#62CACB",
                }
            },
            natemat: {
                '& div,& span': {
                    backgroundColor: "#FF6400",
                }
            },
            aszdziennik: {
                '& div,& span': {
                    backgroundColor: "#EA3A3E",
                }
            }
        }
    },
})


export default CategoryBadge