import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { observer } from "mobx-react"

import { useStores } from '../../helpers/useStores'
import { pushDataLayer } from '../../helpers/pushDataLayer'

import { IMG_SRC_PARAMS_REGEX } from './constants/constants'

/*komponent otypowanny w ProgressiveMedia.tsx, ale w BO nie ma typescripta */
const ProgressiveMedia = observer(({
    src = '',
    preserveRatio = true,
    aspectRatio = 1.78,
    width = 'auto',
    height = 'auto',
    alt = "",
    title = "",
    centerX,
    mediaProperties = {},
    onLoadFinished = () => { },
}) => {
    const [isFullsizeLoaded, setIsFullsizeLoaded] = useState(false);
    const [containerDimensions, setContainerDimensions] = useState({ width: null, height });
    const [isWidthCalculated, setIsWidthCalculated] = useState(false);
    const [visibilityTimeout, setVisibilityTimeout] = useState(false);
    const { UIStore } = useStores();
    const ref = useRef(null);
    const timeout = useRef(null);

    const { APP_SETTINGS, MEDIA_DOMAIN, devicePixelRatio } = window;
    const isPlaceholder = !!APP_SETTINGS?.placeholder && !APP_SETTINGS.placeholder_disabled_pathes?.includes(window.location.pathname);
    const hasMediaProperties = Object.keys(mediaProperties).length !== 0 && mediaProperties.constructor === Object

    const handleFullsizeLoad = () => {
        setIsFullsizeLoaded(true)
        onLoadFinished()
    }

    useEffect(() => {
        function setSize(size){
            if (ref.current !== null) {
                const refWidth = ref.current.offsetWidth;
                if (preserveRatio && ![height, width].includes("auto")) {
                    const containerHeight = height / (width) * refWidth;
                    setContainerDimensions({ width: refWidth, height: containerHeight })
                } else if (!preserveRatio && ![height, width].includes("auto")) {
                    setContainerDimensions({ width, height })
                } else {
                    setContainerDimensions({ width: refWidth, height: "auto" })
                }
            }
        }

        const resizeObserver = new ResizeObserver(e=>{
            e.forEach(entry =>{
                setSize(entry.contentRect.width);
            });
        });
        if (ref.current !== null) {
            resizeObserver.observe(ref.current);
        }

        if (isPlaceholder) {
            timeout.current = setTimeout(() => {
                setVisibilityTimeout(true);
            }, 1500)
            pushDataLayer({
                'event': 'analyticsEvent',
                'eventCategory': 'Preload',
                eventAction: 'Preload loaded',
                eventLabel: window.location.pathname,
                eventValue: undefined
            })
        } else {
            setVisibilityTimeout(true)
        }

        return () => {
            if (timeout.current !== undefined) {
                clearTimeout(timeout.current)
            }
            //resizeObserver.disconnect();
        }
    }, [])

    useEffect(() => {
        if (containerDimensions.width > 0) { //może być null
            setIsWidthCalculated(true);
        }
    }, [containerDimensions])

    const nonEmptyStringSrc = src === "" ? '/' : src;

    // optymalizacja wydajnośćiowa, nie generujemy obrazków turbo ogromnych
    const imageWidth = (containerDimensions.width < 1600 ? containerDimensions.width * devicePixelRatio : containerDimensions.width);

    const placeholder = nonEmptyStringSrc.replace(IMG_SRC_PARAMS_REGEX, `,50,${preserveRatio ? 0 : Math.floor(50 / aspectRatio)},${preserveRatio ? 0 : 1},0`);
    const legacyFullsize = nonEmptyStringSrc.replace(IMG_SRC_PARAMS_REGEX, `,${Math.ceil(imageWidth)},${preserveRatio ? 0 : Math.ceil(imageWidth / aspectRatio)},${preserveRatio ? 0 : 1},0`);
    const sourceUrl = hasMediaProperties
        ? `${MEDIA_DOMAIN}/${mediaProperties.hash},${Math.ceil(imageWidth)},${preserveRatio ? 0 : Math.ceil(imageWidth / aspectRatio)},${preserveRatio || ["gif", "mp4"].includes(mediaProperties.media?.format) ? 0 : 1},0.${mediaProperties.media?.format}`
        : legacyFullsize
    const sourceUrlFallback = sourceUrl.replace("webp", "jpg")

    // console.log('width', imageWidth);
    // console.log('mediaProperties', mediaProperties);
    // console.log('sourceUrlFallback', sourceUrlFallback);

    return <Wrap ref={ref} width={containerDimensions.width} height={preserveRatio ? containerDimensions.height : height}>
        {isFullsizeLoaded || <PlaceholderLogo isCustomPlaceholder={isPlaceholder} src={isPlaceholder ? APP_SETTINGS.placeholder : UIStore.appTheme.logoSimple} isFullsizeLoaded={isFullsizeLoaded} alt="logo" />}
        {visibilityTimeout && <React.Fragment>
            {!isPlaceholder || isFullsizeLoaded && <Placeholder alt={alt} title={title} isFullsizeLoaded={isFullsizeLoaded} src={placeholder} />}
            {isWidthCalculated &&
                <React.Fragment>
                    {mediaProperties.media?.type === "video"
                        ? <FullsizeVideo
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            isFullsizeLoaded={isFullsizeLoaded}
                            onPlay={() => setIsFullsizeLoaded(true)}>
                            <source type={`video/${mediaProperties.media.format}`} src={sourceUrl}></source>
                        </FullsizeVideo>
                        : <picture>
                            <source srcSet={sourceUrl} type="image/webp" />
                            <source srcSet={sourceUrlFallback} type="image/jpeg" />
                            <FullsizeImg
                                src={sourceUrlFallback}
                                alt={alt}
                                title={title}
                                isFullsizeLoaded={isFullsizeLoaded}
                                width={`${ref.current.offsetWidth}px`}
                                height={`${ref.current.offsetHeight}px`}
                                onLoad={handleFullsizeLoad}
                                centerX={centerX}
                            />
                        </picture>

                    }
                </React.Fragment>}
        </React.Fragment>}
    </Wrap>
})

const Wrap = styled.div`
    /* z-index: 0; */
    position: relative;
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    min-width: ${({ width }) => width + (width === 'auto' ? '' : 'px')};
    min-height: ${({ height }) => height + (height === 'auto' ? '' : 'px')};
`

const PlaceholderLogo = styled.img`
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    transform: ${(props) => !props.isCustomPlaceholder && 'scale(0.5)'};
    height: ${(props) => props.isCustomPlaceholder && '100% !important'};
    opacity: 0.6;
    object-fit: ${(props) => props.isCustomPlaceholder && 'cover'};
`

const Placeholder = styled.img`
    filter: blur(8px);
    opacity: ${(props) => props.isFullsizeLoaded ? 0 : 1};
    object-fit: cover;
    min-height: 100%;
    transition: opacity 800ms;
    z-index: 2;
`

const FullsizeImg = styled.img`
    opacity: ${(props) => props.isFullsizeLoaded ? 1 : 0};
    position: absolute;
    top: 0;
    left: ${(props) => props.centerX ? '-50%' : 0};
    height: ${(props) => props.centerX ? '100%' : 'initial'};
    right: 0;
    bottom: 0;
    min-height: 100%;
    transition: opacity 300ms;
    z-index: 3;
`

const FullsizeExtendedWidth = styled(FullsizeImg)`
    width: 100%;
`
const FullsizeVideo = FullsizeExtendedWidth.withComponent('video')

export default ProgressiveMedia