import React from 'react';
import styled from "@emotion/styled";
import Html from './Html';

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"

const Collage = ({ collage }) => {
    const { url1, url2, url3, description } = collage;
    return (
        <CollageWrapper>
            <div>
                <Img src={url1} />
                <Img src={url2} />
                <Img src={url3} />
            </div>
            <Html html={description} />
        </CollageWrapper>
    )
}

const CollageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &>div:first-of-type{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 350px;
        
        &>div{    
            &:nth-of-type(1){
                align-self: center;
            }

            &:nth-of-type(2){
                align-self: flex-start;
            }

            &:nth-of-type(3){
                align-self: flex-end;
            }
        }
    }

    &>div:nth-of-type(2){
        margin: 20px 38px;		
        line-height: 28px;
        color: #262626;
        font-size: 17px;
        font-weight: 300;
        letter-spacing: -0.34px;
        line-height: 23px;
    }

    @media (min-width: 992px) {
        margin: 85px 6%;
        flex-direction: row;
        &>div:first-of-type{
            height: 500px;
            min-width: 700px;
        }

        &>div:nth-of-type(2){
            align-self: center;
            width: 469px;
            color: #222222;
            font-size: 18px;
            font-weight: 400;	
            letter-spacing: -0.3px;	
            line-height: 28px;
        }
    }
`

const Img = styled.div`
    height: 250px;
    width: 30%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-repeat: no-reapeat;
    background-position: 50% 50%;
    /* object-fit: cover; */
    @media (min-width: 992px) {
        height: 402px;
        width: 222px; 
    }

`

export default withDrawingBoard(props => props.collage.hasCanvas)(Collage);