import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Cookies from "cookies-js";

@inject("ArticleStore")
@observer
class TrudatVoteButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "no_vote",
      positive: null,
      negative: null
    };
  }

  componentDidMount() {
    const voteCookies = Cookies.get("trudat_votes") || "[]";
    JSON.parse(voteCookies).forEach(vote => {
      if (vote.url === this.props.url) {
        this.setState({
          state: "result",
          positive: vote.positive,
          negative: vote.negative
        });
        this.props.ArticleStore.updateTrudatVote(vote.value);
      }
    });
  }

  vote(val) {
    this.props.ArticleStore.updateTrudatVote(val);
    this.setState({ ...this.state, state: "voting" });

    const cookiesJSON = Cookies.get("trudat_votes") || "[]";

    fetch(window.API_HOST + "/trudat-vote/", {
      method: "POST",
      body: JSON.stringify({
        url: this.props.url,
        value: val
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          state: "result",
          positive: res.positive,
          negative: res.negative
        });

        const cookiesVotes = JSON.stringify([
          ...JSON.parse(cookiesJSON),
          {
            url: this.props.url,
            value: val,
            positive: res.positive,
            negative: res.negative
          }
        ]);

        Cookies.set("trudat_votes", cookiesVotes);
        console.log(JSON.parse(cookiesJSON));
      });
  }

  render() {
    if (this.state.state === "result") {
      return (
        <div className="art__vote__result">
          <span className="trudat__result--true">{this.state.positive}%</span> :{" "}
          <span className="trudat__result--false">{this.state.negative}%</span>
        </div>
      );
    } else if (this.state.state === "voting") {
      return <div className="art__vote__buttons">...</div>;
    } else {
      return (
        <div className="art__vote__buttons">
          <button onClick={() => this.vote(true)} className="btn trudat_true">
            PRAWDA
          </button>
          czy
          <button onClick={() => this.vote(false)} className="btn trudat_false">
            FAŁSZ
          </button>
        </div>
      );
    }
  }
}

export default TrudatVoteButtons;
