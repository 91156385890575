import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { styled } from "../stitches.config";
import { useStores } from "../helpers";
import { ReactComponent as StarFull } from "./assets/icons/star-subscribe-icon.svg";
import { ReactComponent as StarEmpty } from "./assets/icons/subscribe-star-empty.svg";
import { withThemeContext } from "./components/StitchesThemeProvider";
import ToggleSwitch from "./ToggleSwitch";

interface SubscribeButtonProps {
  subscribed: boolean;
  size?: "small" | "big" | "extraBig";
  onToggle?: () => void;
  authorId?: number;
  isDarkMode?: boolean;
  subscribeSection?: string;
  isToggleIcon?: boolean;
  forceActive?: boolean;
  text?: string;
}

const SubscribeButton: React.FC<SubscribeButtonProps> = ({
  subscribed = false,
  size = "big",
  onToggle,
  authorId,
  isDarkMode,
  subscribeSection,
  isToggleIcon,
  forceActive,
  text,
  ...props
}) => {
  const history = useHistory();
  const [isSubscribed, setSubscribed] = useState(subscribed);
  const [{ user }, dispatch] = useAuth();
  const { UIStore } = useStores();

  useEffect(() => {
    if (authorId && user?.settings.followed_authors.includes(authorId)) {
      setSubscribed(true);
    } else {
      subscribed ? setSubscribed(true) : setSubscribed(false);
    }
  }, [user, authorId]);

  const onToggleFollow = (id: number) => {
    const followedIds = Object.values(user?.settings.followed_authors ?? {});

    const addOrRemove = (array: number[], value: number) => {
      var index = array.indexOf(value);

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
      return array.map((str) => +str);
    };

    dispatch({
      type: "update_user",
      // @ts-ignore
      payload: {
        ...user,
        settings: {
          ...user!.settings,
          followed_authors: addOrRemove(followedIds, id),
        },
      },
    });
  };

  const onToggleSection = (id: string) => {
    const addOrRemove = (array: string[], value: string) => {
      var index = array.indexOf(value);

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
      return array.map((str) => str);
    };

    dispatch({
      type: "update_user",
      // @ts-ignore
      payload: {
        ...user,
        settings: {
          ...user!.settings,
          ignored_categories: addOrRemove(
            user?.settings.ignored_categories ?? [],
            id
          ),
        },
      },
    });
  };

  const toggleClass = () => {
    // setActive(!isActive);
    onToggle && onToggle();
    if (!user || !user.valid) {
      UIStore.setShowLoginModal(true);
    } else if (subscribeSection) {
      onToggleSection(subscribeSection);
    } else if (authorId) {
      onToggleFollow(authorId);
    }
  };

  const isActive = forceActive ? subscribed : isSubscribed;

  const getType = () => {
    if (text) return "borderButton";
    if (!isDarkMode && !isActive) return "light";
    return undefined;
  };

  return (
    <>
      {isToggleIcon ? (
        <ToggleSwitch active={isActive} onToggle={toggleClass} />
      ) : (
        <StyledSubscribeButton
          size={size}
          type={getType()}
          onClick={toggleClass}
        >
          {text}
          {size === "small" && <>{isActive ? <StarFull /> : <StarEmpty />}</>}
          {size === "big" && <>{isActive ? <StarFull /> : <StarEmpty />}</>}
          {size === "extraBig" && (
            <>{isActive ? <StarFull /> : <StarEmpty />}</>
          )}
        </StyledSubscribeButton>
      )}
    </>
  );
};

const StyledSubscribeButton = styled("button", {
  background: "none",
  borderWidth: "0px",
  cursor: "pointer",
  pointerEvents: "auto",
  "& svg": {
    fill: "$primary",
  },
  variants: {
    size: {
      small: {
        "& > svg": {
          width: "15px",
          height: "14px",
        },
      },
      big: {
        "& > svg": {
          width: "22px",
          height: "22px",
        },
      },
      extraBig: {
        "& > svg": {
          width: "30px",
          height: "30px",
        },
      },
    },
    type: {
      light: {

      },
      borderButton: {
        display: 'flex',
        alignItems: 'center',
        color: "$grayscale100",
        padding: "5px 12px 7px 12px",
        backgroundColor: "$grayscale0",
        border: "2px solid $grayscale25",
        "&:hover": {
          backgroundColor: "$grayscale10",
        },
        "&:focus": {
          border: "2px solid $primary",
          backgroundColor: "$grayscale0",
        },
        "&:active": {
          backgroundColor: "$grayscale10",
        },
        "& > svg": {
          marginLeft: "7px",
        },
      },
    },
  },
});

export default withThemeContext(SubscribeButton);
