import { createStitches } from '@stitches/react';
import * as Stitches from '@stitches/react';
import hkGroteskBlack from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Black.ttf"
import hkGrotesk from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Regular.ttf"
import hkGroteskBold from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Bold.ttf"
import hkGroteskBoldItalic from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-BoldItalic.ttf"
import hkGroteskExtraBold from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-ExtraBold.ttf"
import hkGroteskItalic from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Italic.ttf"
import hkGroteskLight from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Light.ttf"
import hkGroteskLightItalic from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-LightItalic.ttf"
import hkGroteskMedium from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-Medium.ttf"
import hkGroteskMediumItalic from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-MediumItalic.ttf"
import hkGroteskSemiBold from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-SemiBold.ttf"
import hkGroteskSemiBoldItalic from "./redesign/assets/fonts/HKGrotesk/HKGrotesk-SemiBoldItalic.ttf"
import charisSIL from "./redesign/assets/fonts/CharisSIL/CharisSIL-Regular.ttf"
import charisSILBold from "./redesign/assets/fonts/CharisSIL/CharisSIL-Bold.ttf"
import charisSILBoldItalic from "./redesign/assets/fonts/CharisSIL/CharisSIL-BoldItalic.ttf"
import charisSILItalic from "./redesign/assets/fonts/CharisSIL/CharisSIL-Italic.ttf"

type BrandColors = {
    [type in Config.Brand]: {
        primary120: string;
        primary: string;
        primary60: string;
    }
}

export const mainColor: BrandColors = {
    natemat: {
        primary120: "#E45A00",
        primary: "#FF6400",
        primary60: "#FFEADC",
    },
    aszdziennik: {
        primary120: "#B82D30",
        primary: "#EA3A3E",
        primary60: "#FBD8D8",
    },
    mamadu: {
        primary120: "#55B6B7",
        primary: "#62CACB",
        primary60: "#DFF4F5",
    },
    innpoland: {
        primary120: "#3F842A",
        primary: "#459B2B",
        primary60: "##DAEBD5",
    },
    dadhero: {
        primary120: "#2C63CD",
        primary: "#3376F6",
        primary60: "#D6E4FD",
    }
}

const getPrimaryColors = (brand: Config.Brand): BrandColors[keyof BrandColors] => (mainColor[brand])

export const defaultColors = {
    ...getPrimaryColors(window.BRAND),
    natematPrimary: "#FF6400",
    aszdziennikPrimary: "#EA3A3E",
    innpolandPrimary: "#459B2B",
    mamaduPrimary: "#62CACB",
    dadheroPrimary: "#3376F6",
    darkmodeStroke: "#3A3A3A",
    darkmodeBg: "#1B1B1B",
    grayscale0: "#FFFFFF",
    grayscale10: "#F5F5F5",
    grayscale25: "#E7E7E7",
    grayscale50: "#CCCCCC",
    grayscale75: "#8D8D8D",
    grayscale90: "#1B1B1B",
    grayscale100: "#131212",
    grayscale100Fixed: "#131212",
    grayscale0Fixed: "#FFFFFF",
}

export const { styled, css, globalCss, config, theme, createTheme } = createStitches({
    media: {
        mobile: "(max-width: 995px)",
        desktop: "(min-width: 996px)",
        bp6: "(min-width: 1310px)",
        bp5: "(min-width: 1310px)",
        bp4: "(min-width: 996px)",
        bp3: "(min-width: 768px)",
        bp2: "(min-width: 375px)",
        bp1: "(min-width: 360px)",
    },
    theme: {
        colors: {
            ...defaultColors
        },
        fonts: {
            grotesk: "HK Grotesk, sans-serif",
            charis: "CharisSIL, serif"
        },
        fontWeights: {
            4: 400,
            6: 600,
            7: 700,
            8: 800
        },
        fontSize: {},
        lineHeights: {
            16: "16px",
            18: "18px",
            24: "24px",
            26: "26px",
            30: "30px",
            32: "32px",
            40: "40px",
            62: "62px"

        },
        letterSpacings: {
            0: 0,
            1: 0.1,
            2: 0.2,
            3: 0.3,
            8: 0.8
        }
    },
});

export const darkTheme = createTheme({
    colors: {
        grayscale0: "#131212",
        grayscale10: "#1B1B1B",
        grayscale25: "#8D8D8D",
        grayscale50: "#CCCCCC",
        grayscale75: "#E7E7E7",
        grayscale90: "#8D8D8D",
        grayscale100: "#FFFFFF"
    },
});

export const globalStyles = globalCss({
    "*": {
        boxSizing: "border-box",
        ".container": {
            "@bp2": {
                // border: "1px solid blue",
                width: 375,
            },
        }
    },
    "body": {
        backgroundColor: "$grayscale0",
    },
})

export type CSS = Stitches.CSS<typeof config>