// service - pwa / src / components / Article / Parts / Socials.js

import React from "react";
import styled from "@emotion/styled"

import CommentIcon from "../../../assets/img/comment_darkgray.svg";
import FacebookIcon from "../../../assets/img/facebook_darkgray.svg";
import TwitterIcon from "../../../assets/img/twitter_darkgray.svg";

const MIN_SHARES = 10;
const MIN_COMMENTS = 5;

const Socials = ({ stats, primary }) => {
    const link = window.location.href;
    return (
        <SocialsWrapper primary={primary}>
            {stats.shares >= MIN_SHARES && <SocialIcon href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(link)}>
                <img src={FacebookIcon} alt="share" />
                {stats.shares}
            </SocialIcon>}
            {/* <SocialIcon href={`http://twitter.com/share?text=Test Article&url=${link}&hashtags=hashtag1,hashtag2,hashtag3`}>
                <img src={TwitterIcon} alt="twitter" />
                210
                </SocialIcon>*/}
            {stats.comments >= MIN_COMMENTS && <SocialIcon href="#comments">
                <img src={CommentIcon} alt="komentarze" />
                {stats.comments}
            </SocialIcon>}
        </SocialsWrapper>
    )
}

const SocialsWrapper = styled.div`
    position: absolute;
    display: ${props => props.primary ? 'flex' : 'none'};
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 88px;
    padding: 10px;
    top: 0;
    bottom: 0;
    right: 20px;
    @media (min-width: 992px) {
        position: static;
        display: flex;
        flex-direction: row;
        padding: 10px 6px;
        justify-content: space-between;
        width: 45%;
        margin: 0 0 20px 14px;
    }

`
const SocialIcon = styled.a`
    display: block;
    text-decoration: none;
    color: #8b8b8b;
    font-size: 10px;
    font-style: normal;
    img{
        margin-right: 5px;
    }
`
export default Socials