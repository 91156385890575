import * as React from "react"
import { styled } from "../../../stitches.config"
import { ReactComponent as PlayIcon } from "../../assets/icons/nav/play-audiobrief.svg"
import { ReactComponent as PauseIcon } from "../../assets/icons/nav/pause-audiobrief.svg"
import { useAudioBrief } from "../../../context/AudioBriefContext"


type AudioBriefProps = {
    type?: "bottom" | "top" | "fixed" | "hiddenNav"
    dataAnalyticsName?: string;
}

const AudioBrief: React.FC<AudioBriefProps> = ({ type = "bottom", dataAnalyticsName, children }) => {
    const { state, handlePlay, circleProps } = useAudioBrief();
    if (!state.available) return null;

    if (type === "bottom") {
        return <PlayButton
            type={type}
            onClick={handlePlay}
            data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
        >
            <ProgressCircle
                css={{
                    height: circleProps.radius * 2,
                    width: circleProps.radius * 2,
                    "circle": {
                        strokeDashoffset: state.circleOffset
                    }
                }}>
                <circle
                    fill="transparent"
                    strokeWidth={state.touched ? circleProps.stroke : 0}
                    strokeDasharray={`${circleProps.circ} ${circleProps.circ}`}
                    r={circleProps.fixedRadius}
                    cx={circleProps.radius}
                    cy={circleProps.radius}
                />
            </ProgressCircle>
            {state.playing ? <PauseIcon className="activeBtn" /> : <PlayIcon />}
            {children}
        </PlayButton>
    }

    return <PlayButton
        type={type}
        onClick={handlePlay}
        data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
    >
        {state.playing ? <PauseIcon className="activeBtn" /> : <PlayIcon />}
    </PlayButton>
}

const ProgressCircle = styled('svg', {
    position: "absolute",
    // top: 0,
    // left: 0,
    "circle": {
        transformOrigin: "50% 50%",
        transform: "rotate(90deg)",
        stroke: "$primary",
        transition: "stroke-dashoffset 800ms ease"
    }
})

const PlayButton = styled("button", {
    display: 'none',
    "@bp4": {
        display: 'block'
    },
    position: "relative",
    outline: "none",
    border: "none",
    backgroundColor: "$primary",
    "& .activeBtn": {
        "& rect": {
            fill: "$primary",
        }
    },
    variants: {
        type: {
            bottom: {
                backgroundColor: "$grayscale0",
                "svg": {
                    width: 25,
                    height: 25
                },
            },
            top: {
                width: 48,
                height: 48,
                backgroundColor: "$primary",
                position: "absolute",
                right: 0,
                bottom: -88,
                zIndex: 999999,
                padding: 11,
                "svg": {
                    width: 26,
                    height: 26,
                    "> *": {
                        fill: "$grayscale0 !important"
                    }
                }
            },
            hiddenNav: {
                backgroundColor: "$primary !important",
                // padding: 11,
                "svg": {
                    width: 26,
                    height: 26,
                    "> *": {
                        fill: "$grayscale0 !important"
                    }
                }
            },
            fixed: {
                width: 60,
                height: 60,
                backgroundColor: "$primary",
                "svg": {
                    width: 20,
                    height: 20,
                    "> *": {
                        fill: "$grayscale0"
                    }
                }
            }
        }
    }
})

const Rectangle = styled("button", {
    backgroundColor: "$primary",
    outline: "none",
    border: "none"
})

export default AudioBrief