import React from 'react';
import { styled } from "../stitches.config"
import { bodyLarge } from "./commonStyles/Fonts";
//import PartnershipIcon from "./assets/icons/partnership-icon.svg";
import { ReactComponent as PartnershipIcon } from "./assets/icons/partnership-icon.svg";

interface PartnershipInfoProps {
    partner: string;
}

const PartnershipInfo: React.FC<PartnershipInfoProps> = ({
    partner,
    ...props
}) => {
    return (
        <StyledPartnershipInfo>
            <span>
                <PartnershipIcon />
            </span>
            <p className={bodyLarge()}>{partner}</p>
        </StyledPartnershipInfo>
    );
};

const StyledPartnershipInfo = styled('div', {
    width: "100%",
    background: "$grayscale0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    flexDirection: "row-reverse",
    color: "$grayscale100",
    '@bp4': {
        flexDirection: "row",
    },

    "& p": {
        margin: "0",
    },

    "& span": {
        content: "",
        width: "20px",
        height: "20px",
        margin: "0 0 0 16px",
        flexShrink: "0",
        '@bp4': {
            margin: "0 8px 0 0",
        },
    },

    "& .svg-primary": {
        fill: "$primary"
    }
})

export default PartnershipInfo