import React, { Component, Suspense, useState } from "react";
/** @jsx jsx */ import { jsx, css, Global } from "@emotion/core";
import styled from "@emotion/styled";
import { observable, toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { FacebookProvider } from "react-facebook"
import { onLCP, onFID, onCLS } from 'web-vitals';
import { AdvertisingProvider } from "@skolimow/react-advertising"
import { initDB } from 'react-indexed-db';

// styles (nie wyrzucać, to jest potrzebne)
import Bootstrap from './Bootstrap.js';
import BootstrapBizon from './BootstrapBizon.js';
import boStyles from "./components/composition-components/styles/hp.scss"; //eslint-disable-line

import { authInterceptor } from "./helpers/authInterceptor"
import AuthProvider from "./context/AuthContext"
import AudioBriefProvider from "./context/AudioBriefContext"
import Routes from './Routes';
import StitchesThemeProvider from './redesign/components/StitchesThemeProvider'

import PodcastPlayerContext from './context/PodcastPlayerContext';

import Error from "./components/Error";

//Loaded static each of them have own Suspend
import RedesignNav from "./redesign/components/Navbar/Nav"
import RedesignFooter from "./redesign/components/Footer"
import NavbarBottom from "./redesign/components/Navbar/NavbarBottom"
import PrivateRoute from "./components/PrivateRoute"
import RestrictedRoute from "./components/RestrictedRoute"

import { setYBTargeting } from "./helpers/setYBTargeting"
import { plugins } from './prebid/plugins'
import { sendToAnalytics, sendLCPToGTM } from "./helpers/sendToAnalytics"
import { RELOAD_TIME, DISABLE_PREMIUM_FLAG_ACTIVE } from './components/common/constants/constants'

// style globalne
import { globalStyles } from "./stitches.config"
import Styles from "./Styles" // nowe

import ZenModal, { ZenModalStep } from "./redesign/components/ZenModal";
import ZenModalWrapper from "./redesign/components/ZenModalWrapper";

import Article from './pages/Article';
import Modal from "./redesign/Modal/Modal";

const CategoryPage = React.lazy(() => import(/* webpackChunkName: "components__CategoryPage" */ './components/CategoryPage'));
const CategoryTrudat = React.lazy(() => import(/* webpackChunkName: "components__CategoryTrudat" */ './components/CategoryTrudat'));
const CategoryZdrowie = React.lazy(() => import(/* webpackChunkName: "components__CategoryZdrowie" */ './components/CategoryZdrowie'));

const PrivacyPage = React.lazy(() => import(/* webpackChunkName: "pages__PrivacyPage" */ './pages/PrivacyPage'));
const PostsMapPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PostsMapPage" */ './redesign/pages/PostsMapPage'));
const PostsMapSubpage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PostsMapPage" */ './redesign/pages/PostsMapSubpage'));
const TopicPage = React.lazy(() => import(/* webpackChunkName: "components__TopicPage" */ './components/TopicPage'));
const AuthorPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AuthorPage" */ './redesign/pages/AuthorPage'));
const AuthorsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AuthorsPage" */'./redesign/pages/AuthorsPage'));
const BlogsPageRedesign = React.lazy(() => import(/* webpackChunkName: "redesign__pages__BlogsPage" */'./redesign/pages/BlogsPage'));
const BlogerPageRedesign = React.lazy(() => import(/* webpackChunkName: "redesign__pages__BlogerPage" */'./redesign/pages/BlogerPage'));
const ForYouPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ForYouPage" */'./redesign/pages/ForYouPage'));
const RegistrationForm = React.lazy(() => import(/* webpackChunkName: "redesign__pages__RegistrationForm" */'./redesign/components/RegistrationForm'));
const LoginForm = React.lazy(() => import(/* webpackChunkName: "redesign__pages__LoginForm" */'./redesign/components/LoginForm'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ResetPassword" */'./redesign/components/ResetPassword'));
const ResetPasswordToken = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ResetPasswordToken" */'./redesign/components/ResetPasswordToken'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "redesign__pages__Dashboard" */'./redesign/pages/Dashboard'));
const Contact = React.lazy(() => import(/* webpackChunkName: "redesign__pages__Contact" */'./redesign/pages/Contact'));

const PodcastsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PodcastsPage" */'./redesign/pages/PodcastsPage'));
const PodcastSeriePage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PodcastsPage" */'./redesign/pages/PodcastSeriePage'));

const SearchPage = React.lazy(() =>/* webpackChunkName: "redesign__pages__SearchPage" */ import('./redesign/pages/SearchPage'));

const AdsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AdsPage" */"./pages/AdsPage"));
const InfoPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__InfoPage" */"./pages/InfoPage"));

const CommercialBreakOverlay = React.lazy(() => import(/* webpackChunkName: "components__CommercialBreakOverlay" */"./components/CommercialBreakOverlay"));

export const DBConfig = {
  name: 'UserAnalytics',
  version: 2,
  objectStoresMeta: [
    {
      store: 'visit',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'articleId', keypath: 'articleId', options: { unique: false } },
        { name: 'categoryId', keypath: 'categoryId', options: { unique: false } },
        { name: 'datetime', keypath: 'datetime', options: { unique: false } },
      ]
    },
    {
      store: 'visitedCategories',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'categoryId', keypath: 'categoryId', options: { unique: true } },
        { name: 'count', keypath: 'count', options: { unique: false } },
      ]
    },
    {
      store: 'notifications',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'visitedId', keypath: 'visitedId', options: { unique: true } },
      ]
    },
  ]
};

initDB(DBConfig);

const CalculatorPage = React.lazy(() => import('./pages/FertilityCalcPage'))

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const initAdform = () => {
  var ga = document.createElement("script");
  ga.type = "text/javascript";
  ga.async = true;
  ga.defer = true;
  ga.src =
    ("https:" === document.location.protocol ? "https://" : "http://") +
    "s1.adform.net/banners/scripts/adx.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ga, s);
};
if (!window.TESTING_MODE) {
  initAdform();
}

// const iframe = document.querySelector('.adslot__ad-container[data-name*=_belka_top] iframe')
// const belkaTopExpandButton = iframe.contentWindow.document.getElementById("expand-button");
// if (typeof (belkaTopExpandButton) != 'undefined' && belkaTopExpandButton != null) {
//   belkaTopExpandButton.addEventListener("click", event => {
//     console.log("event KLIKAM")
//     if (this.innerHTML === "ROZWIŃ") {
//       console.log("event ROZWIŃ")
//     } else {
//       console.log("event ZWIŃ")
//     }
//   })
// }


/**
 * wyświetlenie prośby o instalację
 * 
 * @param e
 * @returns
 */
window.addEventListener("beforeinstallprompt", function (e) {
  // window.installPromptEvent = e;
  window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconPromptBefore"]);

  e.userChoice.then(function (res) {
    if (res.outcome === "dismissed") {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconCancelled"]);
    } else if (res.outcome === "default") {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconDismissed"]);
    } else {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconAdded"]);
    }
  });
});
window.addEventListener('appinstalled', (evt) => {
  window._gaq.push(["_trackEvent", 'PWAHomeIcon', "PWAHomeIconInstalled"]);
});
if (window.matchMedia('(display-mode: standalone)').matches) {
  window._gaq.push(["_trackEvent", 'PWAHomeIcon', "PWARunApp"]);
}

authInterceptor();

@withRouter
@inject("ArticleStore", "UIStore", "AdsStore")
@observer
class App extends Component {

  @observable
  timestamp = null
  isZenPaymentSuccess

  updateHistory() {
    const { location, UIStore } = this.props;
    UIStore.updateHistory(location);
  }

  handleVisibilityChange = () => {
    // #5100 PILNE - Zmiany na stronach
    // tymczasowo wyłączmy
    return;

    if (document[hidden]) {
      this.timestamp = Date.now();
    } else {
      let timeNow = Date.now();
      const timeDiff = timeNow - this.timestamp;
      // console.log(timeDiff)
      if (timeDiff > RELOAD_TIME) {
        window.scrollTo(0, 0);
        window.location.reload();
      }
    }
  }

  handleGoogletagCorrelatorUpdate = () => {
    const { googletag } = window;
    googletag.cmd.push(() => {
      googletag.pubads().clearTargeting();
      if (typeof googletag.pubads().updateCorrelator == 'function') {
        googletag.pubads().updateCorrelator();
      }

      for (let i = 0; i < googletag.pubads().getSlots().length; i++) {
        googletag.pubads().getSlots()[i].clearTargeting();
      }
    })
  }

  componentDidMount() {
    const { UIStore, AdsStore } = this.props;
    window.addEventListener('pageLoaded', this.handleGoogletagCorrelatorUpdate)
    window.APP_LOADED = true;
    AdsStore.checkAdlibConfig();
    if (document[hidden]) {
      this.timestamp = Date.now();
    }

    onCLS(sendToAnalytics, true);
    // onFID(sendToAnalytics);
    // onLCP(sendToAnalytics);
    onLCP(sendLCPToGTM);


    UIStore.isDesktop && document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    setYBTargeting();

    window._gaq.push(["_trackEvent", 'PWA', "appComponent-componentDidMount"]);

    // document.getElementById("articleContentInSource")?.remove();
    document.getElementById("staticsplashscreen")?.remove();
    this.updateHistory();
    this.props.history.listen(location => {
      this.updateHistory();
    });
  }

  componentDidCatch(error, info) {
    window._gaq.push(["_trackEvent", 'PWA', "appComponent-componentDidCatch", error.message]);
    fetch(window.API_HOST + "/pwa-error/", {
      method: "POST",
      body: JSON.stringify({
        context: 'appComponent-componentDidCatch',
        error: error
      })
    })
    // window.APP_FORCE_RWD();
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  /**
   * inaczej niż w dokumentacji, ale w dokumentacji porównuje obiekt z obiektem, i tam hash się zmienia
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const oldLocation = prevProps.location;
    const newLocation = this.props.location;
    const oldLocationString = oldLocation.pathname + oldLocation.search + oldLocation.hash;
    const newLocationString = newLocation.pathname + newLocation.search + newLocation.hash;
    if (oldLocationString !== newLocationString) {
      this.onRouteChange()
    }
  }

  onRouteChange = () => {
    const event = new CustomEvent("pageLoaded")
    // setTimeout(() => {
    window.dispatchEvent(event)
    setYBTargeting();
    // }, 200);
  }

  componentWillUnmount() {
    window.removeEventListener("pageLoaded", this.handleGoogletagCorrelatorUpdate)
  }

  render() {
    const {
      updateServiceWorkerStatus,
      serviceWorkerStatus,
      showLoginModal,
      nav
    } = this.props.UIStore;

    const { AdsStore, location, ArticleStore } = this.props
    // AdsStore.tcDataLoaded && AdsStore.isAdConfigLoaded && console.log(toJS(AdsStore.prebidConfig))
    const background = location.state && location.state.background;
    return (
      <StitchesThemeProvider>
        <BootstrapBizon />
        <Styles />
        {globalStyles()}

        <ThemeProvider theme={this.props.UIStore.appTheme}>

          <Main showNavSpace={nav == 'MAIN' || nav === 'AUDIO'}>
            <AdvertisingProvider config={AdsStore.tcDataLoaded && AdsStore.isAdConfigLoaded ? toJS(AdsStore.prebidConfig) : undefined} plugins={plugins} active={true}>
              <FacebookProvider language="pl_PL" appId="192967107477262" wait={true}>
                <AuthProvider>
                  <Suspense fallback={null}>
                    <CommercialBreakOverlay />
                  </Suspense>
                  <AudioBriefProvider>
                    <RedesignNav />
                    <div className="app-content">
                      {window.innerWidth < 996 && <NavbarBottom />}
                      <PodcastPlayerContext>
                        <Modal />
                        <Suspense fallback={null}>
                          <Switch location={background || location}>
                            <Route path="*/story">
                              <Redirect to="/" />
                            </Route>
                            <Route exact path={Routes.home} component={CategoryPage} />
                            <Route path={Routes.categoryTrudat} component={CategoryTrudat} />
                            <Route exact path={Routes.categoryZdrowie} component={CategoryZdrowie} />
                            <Route path={Routes.zdrowieArticle} component={Article} />
                            <Route path={Routes.category} component={CategoryPage} />
                            <Route path={Routes.blogerArticle} component={Article} />
                            <Route path={Routes.blogerHome} component={BlogerPageRedesign} />
                            <Route path={Routes.blogs} component={BlogsPageRedesign} />
                            <Route path={Routes.forYou} component={ForYouPage} />
                            <Route path={Routes.topic} component={TopicPage} />
                            <Route path={Routes.redakcja} component={AuthorsPage} />
                            <Route path={Routes.user} component={AuthorPage} />
                            {window.BRAND === "mamadu" && <Route path={Routes.fertilityCalcPage} component={CalculatorPage} />}
                            <Route path={Routes.info} component={InfoPage} />
                            {!DISABLE_PREMIUM_FLAG_ACTIVE && <RestrictedRoute path={Routes.login} component={LoginForm} />}
                            {!DISABLE_PREMIUM_FLAG_ACTIVE && <RestrictedRoute path={Routes.register} component={RegistrationForm} />}
                            {!DISABLE_PREMIUM_FLAG_ACTIVE && <PrivateRoute path={Routes.dashboard} component={Dashboard} />}
                            {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.resetPassword} component={ResetPassword} />}
                            {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.resetPasswordToken} component={ResetPasswordToken} />}
                            <Route path={Routes.contact} component={Contact} />
                            <Route path={Routes.adTests} component={AdsPage} />
                            <Route path={Routes.search} component={SearchPage} />
                            <Route path={Routes.postsmapcategory} component={PostsMapSubpage} />
                            <Route path={Routes.postsmap} component={PostsMapPage} />
                            <Route path={Routes.privacy} component={PrivacyPage} />
                            <Route path={Routes.podcastSerie} component={PodcastSeriePage} />
                            <Route path={Routes.podcasts} component={PodcastsPage} />
                            <Route exact path={Routes.article} component={Article} />
                            <Route component={Error} />
                          </Switch>
                          <Route path="/:name/zen-mode-payment-success" children={<ZenModal step={ZenModalStep.SUCCESS} />} />
                          {/* {window.innerWidth < 996 && <BottomBar />} */}
                        </Suspense>
                      </PodcastPlayerContext>
                    </div>
                  </AudioBriefProvider>
                  <Suspense fallback={null}>
                    {showLoginModal && <ZenModalWrapper />}
                  </Suspense>
                </AuthProvider>
              </FacebookProvider>
              <RedesignFooter />

              {
                serviceWorkerStatus === "waiting" && (
                  <ServiceWorkerMessage>
                    <div className="info"> Nowa wersja aplikacji dostępna :) </div>
                    <div
                      className="refresh"
                      onClick={() => updateServiceWorkerStatus({ action: "refresh" })}
                    >
                      {" "}
                      Aktualizuj{" "}
                    </div>
                  </ServiceWorkerMessage>
                )
              }
            </AdvertisingProvider>
          </Main>
        </ThemeProvider>
      </StitchesThemeProvider>
    );
  }
}

const Main = styled.main`

  .hp__block--four,
  .hp__block--three,
  .hp__block--two {
    .hp__box--column:hover {
      .hp__box__title {
        color: ${props => props.theme.mainColor};
      }
    }
  }

  .hp__box--main,
  .hp__box--small__content {
    &:hover {
      .hp__box__social,
      .hp__box__title {
        color: ${props => props.theme.mainColor};
      }
    }
  }
  .hp__box--column--blog {
    .hp__box__blog:hover {
      .hp__box__label {
        color: ${props => props.theme.mainColor};
      }
    }
  }
`;


const ServiceWorkerMessage = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.mainColor};
  width: 100vw;
  align-items: center;
  padding: 15px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  z-index: 20;
  box-shadow: 0px -4px 6px 0 rgba(0, 0, 0, 0.3);
  .refresh {
    cursor: pointer;
    margin-left: auto;
    font-weight: bold;
  }
`;

export default App;