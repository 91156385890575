import * as React from "react"
import styled from "@emotion/styled"

import RenderSlateChildren from "./RenderSlateChildren"
import { SlateArticleType } from "../../../../stores/ArticleStore"
import { OrderedListElement } from "./types"

type OrderedListProps = {
    element: OrderedListElement;
    article: SlateArticleType;
}

const OrderedList: React.FC<OrderedListProps> = ({ element, article }) => {
    return (
        <NumeredListBizon>
            {element.children.map((li, index) => (<li key={index}>
                {li.children.map(lic => (
                    <RenderSlateChildren element={lic.children} article={article} />
                ))}
            </li>))}
        </NumeredListBizon>
    );
}

const NumeredListBizon = styled.ol`
    padding-left: 32px;
    font-size: 18px;
    font-family: 'CharisSIL';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
     @media (min-width: 996px) {
        padding-left: 60px;
        font-size: 21px;
        line-height: 32px;
    }
    li  {
        & > div{
            display: inline-block;
        }
    }  
`


export default OrderedList