import React from "react";
import styled from "@emotion/styled";

import Link from "../Link";

const ArticleLinks = props => {
  if (!props.show || props.show == 'off' || !props.links || props.links.length == 0) {
    return null;
  }

  return (
    <BoxWrapper>
      <Title>Powiązane tematy</Title>
      <ListContainer>
        {props.links.map(art => {
          if (art.url && art.url.length > 0 && art.description && art.description.length > 0) {
            return (
              <ListElement>
                <Link to={art.url}>
                  <ArticleTitle>{art.description}</ArticleTitle>
                  <ArticleDate>{art.date}</ArticleDate>
                </Link>
              </ListElement>
            );
          }
        })}
      </ListContainer>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  margin-top: 20px;
  pointer-events: all;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 8px;
  color: white;
`;

const ArticleTitle = styled.div`
  font-size: 15px;
`;

const ArticleDate = styled.div`
  font-size: 10px;
  color: #69707F;
  padding-top: 6px;
`;

const ListContainer = styled.div`
`;

const ListElement = styled.div`
  display: block;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    display: inline-block;
    width: 175px;
	margin-right: 20px;
  }

  > a {
    display: block;
    height: 100px;
    padding: 13px 16px;
    background: white;
    border-radius: 3px;
    text-decoration: none;
    line-height: 18px;
    color: ${props => props.theme.mainColor};
    
    @media (min-width: 992px) {
      opacity: 0.45;
      &:hover {
      	opacity: 1;
      }
    }

  }
`;

export default ArticleLinks;
