import React from "react";
import styled from "@emotion/styled";
import { inject, observer } from "mobx-react";
import Adslot from "../../../components/Adslot";
import KulkiSvg from "../../common/KulkiSvg";

import LeadBizon from "../../../redesign/Lead"


export default class Lead extends React.Component {
  render() {
    const { text } = this.props;

    return (
      <LeadBizon text={text} />
    );
  }
}

Lead.defaultProps = {
  hideAdslots: false
}