import React, { useEffect } from 'react';

import { styled } from "../../../../stitches.config";
import RenderSlateChildren from "./RenderSlateChildren";
//import Blockquote from "./Blockquote"
//import Title from "../Title"
//import Lead from "../Lead"
//import UnorderedList from "./UnorderedList"
//import AuthorDate from "../AuthorDate"
import Picture from "./Picture";

import FrameComponent from "./FrameComponent";
import Adslot from "../../../Adslot";
import MarginWrapper from "../MarginWrapper";
import OnnetworkVideo from "../../Content/body/OnnetworkVideo";
import RelatedArticle from "../RelatedArticle"
import OrderedList from "./OrderedList";

import Youtube from "../Youtube";
import Link from "../../../Link";

// elementy bizon 
import UnorderedListBizon from "../../../../redesign/List"
import SpecialText from "../../../../redesign/atoms/SpecialText"
import LeadBizon from "../../../../redesign/Lead"

import ArticleTitle from "../../../../redesign/ArticleTitle"
import AuthorDateBizon from "../../../../redesign/components/AuthorDate"
import { p, h1, h2, h3 } from "../../../../redesign/commonStyles/Fonts"
import LinkChanger from "../../LinkChanger"
import CtaButton from "../../../../redesign/CtaButton"

import ErrorBoundary from "../ErrorBoundary";
import { SlateRendererProps } from "./SlateRenderer";
import { SlateElementOrLegacyElement } from "./SlateRenderer";
import { useStores } from "../../../../helpers";

const MediaEmbed = React.lazy(() => import("./MediaEmbed"));

const Gallery = React.lazy(() => import(/* webpackChunkName: "Slate__Gallery" */"../../../../redesign/Gallery"));
const Poll = React.lazy(() => import(/* webpackChunkName: "Slate__Poll" */"../Poll"));
const Quiz = React.lazy(() => import(/* webpackChunkName: "Slate__Quiz" */"../Quiz"));

const Quote = React.lazy(() => import(/* webpackChunkName: "Slate__Quote" */"../../../../redesign/Quote"));
const LiveItem = React.lazy(() => import(/* webpackChunkName: "Slate_LiveItem" */"../LiveItem"));
const SocialIcons = React.lazy(() => import(/* webpackChunkName: "Slate__SocialIcons" */"../../../../redesign/components/SocialIcons"));
const HtmlParser = React.lazy(() => import(/* webpackChunkName: "Slate__HtmlParser" */"../../Content/HtmlParser"));
const MediaLinks = React.lazy(() => import(/* webpackChunkName: "Slate__MediaLinks" */"../../../../redesign/MediaLinks"));
const HeaderPodcast = React.lazy(() => import(/* webpackChunkName: "Slate__HeaderPodcast" */"../../../../redesign/HeaderPodcast"));
const SeeAlsoArticles = React.lazy(() => import(/* webpackChunkName: "Slate__SeeAlsoArticles" */"../../../../redesign/components/SeeAlsoArticles"));

const PodcastPlayerWidget = React.lazy(() => import(/* webpackChunkName: "Slate__OnnetworkPodcastPlayer" */"../../../../redesign/components/PodcastPlayer/PodcastPlayerWidget"));

const SlateBlocksBizon: React.FC<SlateRendererProps> = ({ article, parts, hideAdslots = false }) => {
    console.log('SlateBlocksBizon', article)
    const { ArticleStore } = useStores();
    const isLive = ArticleStore.article.display_type === "LIVE";
    const isPodcast = ArticleStore.article.display_type === "ARTICLE_PODCAST";

    useEffect(() => {
        var root = document.getElementById("root");
        root && (root.classList.remove("loading"));

        var acs = document.getElementById("articleContentInSource");
        if (acs) {
            /**
             * bez tego może być mignięcie na mobile
             */
            acs.classList.add('loaded');
            setTimeout(function () {
                document.getElementById("articleContentInSource")?.remove()
            }, 300);
        }
    }, []);

    return (
        <div style={{ whiteSpace: "pre-wrap" }}>
            <React.Suspense fallback={null}>
                {parts.map((el: SlateElementOrLegacyElement, index) => {
                    switch (el.type) {
                        case "cta-button":
                            return <ErrorBoundary key={index}>
                                <CtaButton />
                            </ErrorBoundary>

                        case "post-title":
                            return <ErrorBoundary key={index}>
                                {/*<Title title={el.data} />*/}
                                <ArticleTitle children={el.data} />
                            </ErrorBoundary>
                        case "author-date":
                            return <ErrorBoundary key={index}>
                                {/*<AuthorDate article={article} />*/}
                                {!isLive && <AuthorDateBizon article={article} />}
                            </ErrorBoundary>
                        case "social_icons":
                            return <ErrorBoundary key={index}>
                                <SocialIcons article={el.data} />
                            </ErrorBoundary>
                        case "youtube":
                            return <ErrorBoundary key={index}>
                                <Youtube videoId={el.data} />
                            </ErrorBoundary>
                        case "media_links":
                            return <ErrorBoundary key={index}>
                                <MediaLinks element={el.data} />
                            </ErrorBoundary>
                        case "podcast_header":
                            return <ErrorBoundary key={index}>
                                <HeaderPodcast element={el.data} />
                            </ErrorBoundary>
                        case "lead":
                            return <ErrorBoundary key={index}>
                                {/*<Lead text={el.data} hideAdslots={hideAdslots} />*/}
                                {!article.disableLead && <LeadBizon isPodcast={isPodcast} text={el.data} />}
                            </ErrorBoundary>
                        case "adslot":
                            if (hideAdslots) return null;
                            return <ErrorBoundary key={index}>
                                <MarginWrapper>
                                    <Adslot {...el.data} disableFirework={article.disableFirework} />
                                </MarginWrapper>
                            </ErrorBoundary>
                        case "h1":
                            return <ErrorBoundary key={index}>
                                <RedesignMarginWrapper>
                                    <h1 className={h1()}>
                                        <RenderSlateChildren element={el.children} article={article} />
                                    </h1>
                                </RedesignMarginWrapper>
                            </ErrorBoundary>
                        case "h2":
                            return <ErrorBoundary key={index}>
                                <RedesignMarginWrapper>
                                    <h2 className={h2()}>
                                        <RenderSlateChildren element={el.children} article={article} />
                                    </h2>
                                </RedesignMarginWrapper>
                            </ErrorBoundary>
                        case "h3":
                            return <ErrorBoundary key={index}>
                                <RedesignMarginWrapper>
                                    <h3 className={h3()}>
                                        <RenderSlateChildren element={el.children} article={article} />
                                    </h3>
                                </RedesignMarginWrapper>
                            </ErrorBoundary>
                        case "p":
                            return <ErrorBoundary key={index}>
                                <BreakWordsWrapper>
                                    <p style={{ whiteSpace: "pre-wrap" }} className={p()}>
                                        <RenderSlateChildren element={el.children} article={article} />
                                    </p>
                                </BreakWordsWrapper>
                            </ErrorBoundary>
                        case "picture":
                            return <ErrorBoundary key={index}>
                                <Picture element={el} />
                            </ErrorBoundary>
                        case "blockquote":
                            return <ErrorBoundary key={index}>
                                <Quote element={el} article={article} />
                                {/*<Blockquote element={el} article={article} />*/}
                                {/* złe umiejscowienie SeeAlsoArticles - tymczasowe */}
                                {/*<SeeAlsoArticles articles={ArticleStore.similar_articles} boxSize="big" />*/}
                            </ErrorBoundary>
                        case "gallery":
                            return <ErrorBoundary key={index}>
                                <Gallery pictures={el.data} />
                            </ErrorBoundary>
                        case "similar_articles":
                            return (
                                <SeeAlsoArticles
                                    articles={el.data}
                                    boxSize="big"
                                />
                            );
                        case "poll":
                            return (
                                <ErrorBoundary key={index}>
                                    <Poll data={el.data} />
                                </ErrorBoundary>
                            );
                        case "quiz":
                            return (
                                <ErrorBoundary key={index}>
                                    <Quiz data={el.data} />
                                </ErrorBoundary>
                            );
                        case "media_embed":
                            return <ErrorBoundary key={index}>
                                <MediaEmbed element={el} />
                            </ErrorBoundary>
                        case "element_frame":
                            return <ErrorBoundary key={index}>
                                {/*} <FrameComponent element={el} article={article} /> */}
                                <SpecialText element={el} article={article} />
                            </ErrorBoundary>
                        case "ul":
                            return <ErrorBoundary key={index}>
                                {/*<UnorderedList element={el} article={article} />*/}
                                <UnorderedListBizon element={el} article={article} />
                            </ErrorBoundary>
                        case "ol":
                            return <ErrorBoundary key={index}>
                                <OrderedList element={el} article={article} />
                            </ErrorBoundary>
                        case 'onnetwork':
                            if (hideAdslots)
                                return null;

                            return <ErrorBoundary key={index}>
                                <OnnetworkVideo mid={el.data.id} hookPlayer={el.data.hookOnNetworkPlayer} />
                            </ErrorBoundary>
                        case 'related_article':
                            if (!el.data) return null;
                            return <ErrorBoundary key={index}>
                                <RelatedArticle article={el.data} />
                            </ErrorBoundary>
                        case "html":
                            return (
                                <ErrorBoundary key={index}>
                                    <HtmlParser data={el.data} />
                                </ErrorBoundary>
                            );
                        case "code_block":
                            return <ErrorBoundary key={index}>
                                <>
                                    {el.children.map((codeBlock, codeBlockIndex) => {
                                        return <HtmlParser data={codeBlock.text} key={'code_block_' + codeBlockIndex} />
                                    })}
                                </>
                            </ErrorBoundary>
                        case "live-item":
                            return (
                                <ErrorBoundary key={index}>
                                    <LiveItem settings={el.data} article={article} hideAdslots={hideAdslots} />
                                </ErrorBoundary>
                            );
                        case 'onnetwork-podcast-player':
                            return (
                                <ErrorBoundary key={index}>
                                    <PodcastPlayerWidget data={el.data} />
                                </ErrorBoundary>
                            );
                        case 'link_changer':
                            return <LinkChanger key={index} msg="article changer" url={article.url} data={el.data}>
                                <div style={{ width: 1, height: 1, marginBottom: -18 }} />
                            </LinkChanger>

                        default:
                            return null;
                    }
                })}
            </React.Suspense>
        </div>
    )
}

const RedesignMarginWrapper = styled("div", {
    marginTop: "32px",
    "@bp4": {
        marginTop: "56px",
    },
});

const BreakWordsWrapper = styled("div", {
    "& p": {
        overflowWrap: 'break-word',
    },
});

export default SlateBlocksBizon;
