import React from "react";
import styled from "@emotion/styled";
import Link from "../../Link";
import { inject, observer } from "mobx-react";

@inject("ArticleStore")
@observer
export default class Tags extends React.Component {
  render() {
    const ArticleStore = this.props.ArticleStore;
    if (ArticleStore.fetchedArticle.topics.length > 0) {
      return (
        <div className="container">
          <TagsWrapper>
            <span> Zobacz także: </span>
            {ArticleStore.fetchedArticle.topics.map(topic => (
              <li key={topic.url}>
                {" "}
                <Link to={topic.url}> {topic.name}</Link>{" "}
              </li>
            ))}
          </TagsWrapper>
        </div>
      );
    }
    return null;
  }
}

export const TagsWrapper = styled.ul`
  margin: 0;
  margin-top: 20px;
  margin-left: -7px;
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  width: 100%;
  span {
    display: block;
    flex-basis: 100%;
    font-size: 19px;
    font-weight: 700;
    margin-left: 7px;
    margin-bottom: 5px;
    color: var(--colors-grayscale100);
  }
  a {
    display: inline-block;
    color: ${props => props.theme.secondColor};
    font-size: 13px;
    font-weight: 500;
    margin: 0 7px;
    font-weight: 700;
  }
`;
