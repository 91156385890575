import * as React from "react"
import styled from "@emotion/styled"

import { AnyElement, ChildArray, Child } from "./types"
import Link from "../../../Link"
import { SlateArticleType } from "../../../../stores/ArticleStore"
import { toJS } from "mobx"

type StyledMarkProps = Omit<Child, "text">

type RenderSlateChildrenProps = {
    article: SlateArticleType;
    element: ChildArray
}

const RenderSlateChildren: React.FC<RenderSlateChildrenProps> = ({ element, article }) => {
    return <>
        {element.map(el => {
            if ("type" in el) {
                // @ts-ignore
                return <Link to={el.url} disableNofollow={article.disableNofollowExtra} data-analytics-name="in-text-link">
                    <RenderSlateChildren element={el.children} article={article} />
                </Link>
            } else {
                if (el.superscript) {
                    return <sup>{el.text}</sup>
                }
                if (el.subscript) {
                    return <sub>{el.text}</sub>
                }
                if (el.text !== "") {
                    if (["bold", "italic", "underline", "strikethrough"].some(mark => Object.keys(el).includes(mark))) {
                        return <Mark
                            bold={el.bold}
                            italic={el.italic}
                            underline={el.underline}
                            strikethrough={el.strikethrough}
                        >
                            {el.text}
                        </Mark>
                    } else {
                        return <>{el.text}</>
                    }
                }
            }
        })}
    </>
}

const Mark = styled.span<StyledMarkProps>`
    font-weight: ${({ bold }) => bold ? 700 : "lighter"};
    font-style: ${({ italic }) => italic ? "italic" : "normal"};
    text-decoration: ${props => {
        let style = "";
        if (props.underline) {
            style += "underline "
        }
        if (props.strikethrough) {
            style += "line-through "
        }
        return style
    }};
`

export default RenderSlateChildren