import React, { useEffect } from 'react';

import { Range } from 'react-range';

import { ReactComponent as SoundOnIcon } from "../../../assets/img/podcastPlayer/podcast_sound_on.svg";

interface Props {
    volume: number;
    setVolume: (volume: number) => void
};

const PodcastPlayerVolume: React.FC<Props> = ({ volume, setVolume }) => {

    return (
        <div className="podcastPlayerNav__volume">
            <button className="podcastPlayerNav__volume__button" onClick={() => setVolume(volume > 0 ? 0 : 1)}>
                <SoundOnIcon />
            </button>
            <Range
                step={0.1}
                min={0}
                max={1}
                values={[volume]}
                onChange={(values) => setVolume(values[0])}
                renderTrack={
                    ({ props, children }) => (
                        <div className="podcastPlayerNav__volume__track" {...props}>
                            <div className="podcastPlayerNav__volume__track__current" style={{ width: (volume * 100) + '%' }} />
                            {children}
                        </div>
                    )
                }
                renderThumb={
                    ({ props }) => (
                        <div className="podcastPlayerNav__volume__thumb" {...props} />
                    )
                }
            />
        </div>
    )

}

export default PodcastPlayerVolume;