import React from "react";
import { styled } from "../../stitches.config";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";

import { h3, h4, body, bodyLarge } from "../commonStyles/Fonts";
import BackgroundImage from "../assets/images/login-form-background.png";
import { ReactComponent as GoogleIcon } from "../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as AppleIcon } from "../assets/icons/apple.svg";
import CloseIcon from "../assets/icons/close-cross-black-icon.svg";
import { useStores } from "../../helpers";

import * as Yup from "yup";
import Logo from "../atoms/Logo";

type FormValues = {
  email: string;
};

interface Props {
  onCloseModal: () => void;
}
const LoginModal: React.FC<Props> = ({ onCloseModal }) => {
  const { UIStore } = useStores();
  const history = useHistory();
  const isDesktop = window.innerWidth > 996;
  const initialValues: FormValues = { email: "" };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Pole wymagane")
      .email("Wprowadź poprawny adres e-mail"),
  });

  const handleSubmit = ({ email }: FormValues) => {
    UIStore.setShowLoginModal(false);
    history.push(`/register?email=${email}`);
  };
  return (
    <Wrapper>
      <Content>
        <FormWrapper>
          <FormWrapperInputs>
            <BoxHeader>
              <Logo
                css={{
                  width: 98,
                  height: 19,
                }}
              />
              {!isDesktop &&
                <CloseButton onClick={onCloseModal}>
                  <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7525 10.4142L19.5455 2.62119L17.707 0.782715L9.91401 8.57568L2.12119 0.782868L0.282715 2.62135L8.07553 10.4142L0.282715 18.207L2.12119 20.0455L9.91401 12.2526L17.707 20.0456L19.5455 18.2071L11.7525 10.4142ZM9.91401 8.99995L17.707 1.20698L17.707 1.20703L9.91406 9L9.91401 8.99995ZM2.12119 1.20713L2.12124 1.20718L0.707031 2.6214L0.706979 2.62135L2.12119 1.20713ZM8.4998 10.4142L0.706979 18.207L0.707031 18.207L8.49985 10.4142L8.4998 10.4142Z" />
                  </svg>
                </CloseButton>
              }
            </BoxHeader>
            <FormTitle>
              <p className={h3()}>Zapisz swoje ustawienia</p>
            </FormTitle>
            <FormText>
              <p className={bodyLarge()}>
                Załóż konto użytkownika lub zaloguj się jeśli masz już
                konto w naszym serwisie, żeby zapisać swoje ustawienia.
              </p>
            </FormText>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className={body()}>
                  <label
                    className={`${errors.email && touched.email && "show-error-input-text"
                      }`}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    className={`${bodyLarge()} ${errors.email && touched.email && "show-error-input-border"
                      }`}
                    placeholder="Wpisz swój email"
                  />
                  {errors.email && touched.email && (
                    <span className="validationError">{errors.email}</span>
                  )}
                  <button type="submit" className={bodyLarge()}>
                    Kontynuuj
                  </button>
                  <FormWrapperInputsButtons>
                    <a href="/security/auth?authclient=facebook"><FacebookIcon /></a>
                    <a href="/security/auth?authclient=google"><GoogleIcon /></a>
                  </FormWrapperInputsButtons>
                </Form>
              )}
            </Formik>
          </FormWrapperInputs>
          <FormWrapperInfo>
            <BoxHeader>
              <CloseButton onClick={onCloseModal}>
                <img src={CloseIcon} />
              </CloseButton>
            </BoxHeader>
            <p className={h3()}>
              Korzystaj w pełni z na<span>:</span>Temat
            </p>
            <FormWrapperInfoList className={h4()}>
              <li>Możliwość wyłączenia reklam display i korzystanie z pełni funkcjonalności serwisu</li>
              <li>Twórz swój feed</li>
              <li>Wybieraj co widzisz i w jakiej kolejności</li>
              <li>Otrzymuj dedykowany newsletter od redaktora naczelnego</li>
              <li>Zapisuj tagi i śledź wydarzenia, które Cię interesują</li>
              <li>Tylko 4,99 zł/miesięcznie</li>
            </FormWrapperInfoList>
          </FormWrapperInfo>
        </FormWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.75)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  left: 0,
  zIndex: 20000000000,
});

const Content = styled("div", {});

const FormWrapper = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  background: "$grayscale0",
  "@bp2": {
    width: "auto",
  },
  "& .validationError": {
    color: "$aszdziennikPrimary",
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: "8px",
  },
});

// formularz

const FormTitle = styled("div", {
  display: "flex",
  marginBottom: "24px",
  "& a": {
    paddingTop: "0px",
    "@bp4": {
      display: "none",
      paddingTop: "24px",
    },
  },
  "& p": {
    margin: 0,
  },
});

const FormText = styled("div", {
  "& p": {
    margin: 0,
    width: "332px",
    textAlign: "left",
  },
});

const FormWrapperInputs = styled("div", {
  padding: "32px 16px 32px 16px",
  color: "$grayscale100",
  "@bp4": {
    padding: "36px 40px 32px 40px",
  },
  "& .logo": {
    marginBottom: "28px",
    height: "16px",
    display: "none",
    "@bp4": {
      display: "block",
    },
    "& svg": {
      width: "98px",
      height: "16px",
      "& .dots": {
        fill: "$primary",
      },
    },
  },

  "& form": {
    display: "flex",
    flexDirection: "column",
    "& label": {
      margin: "24px 0 4px 0",
      textAlign: "left",
    },
    "& input": {
      border: "1px solid $grayscale25",
      fontWeight: "500",
      padding: "12px 14px",
      transition: "0.2s",
      caretColor: "$primary",
      color: "$grayscale100Fixed",
      "&::placeholder": {
        color: "$grayscale50",
      },
      "&:hover": {
        border: "1px solid $grayscale50",
      },
      "&:focus": {
        border: "1px solid $grayscale50",
      },
    },
    "& button": {
      background: "$primary",
      borderWidth: "0px",
      color: "$grayscale0",
      margin: "24px 0",
      fontWeight: "500",
      padding: "12px 14px",
    },
  },

  "& .checkbox": {
    margin: "24px 0 0 0",
    display: "flex",
    alignItems: "center",
  },

  ".show-error-input-border": {
    border: "1px solid $aszdziennikPrimary",
    "&:hover": {
      border: "1px solid $aszdziennikPrimary",
    },
    "&:focus": {
      border: "1px solid $aszdziennikPrimary",
    },
  },
  ".show-error-input-text": {
    color: "$aszdziennikPrimary",
  },
  ".show-error-checkbox-border": {
    "&::before": {
      border: "1px solid $aszdziennikPrimary !important",
    },
  },
});

const FormWrapperInputsButtons = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "28px",
  "& a": {
    margin: "0 !important",
    boxSizing: "border-box",
    width: "49%",
    height: "40px",
    background: "none !important",
    border: "1px solid $grayscale25 !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@bp4": {
      height: "48px",
    },
    "& svg": {
      width: "18px",
      height: "18px",
      "@bp4": {
        width: "20px",
        height: "20px",
      },
    },
  },
});

const FormWrapperInfo = styled("div", {
  padding: "36px 46px 0 40px",
  backgroundColor: "rgba(19, 18, 18, 0.8)",
  color: "$grayscale0",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  display: "none",
  flexDirection: "column",
  "@bp4": {
    display: "flex",
  },

  "& p": {
    width: "332px",
    margin: 0,
    color: "#fff",

    "& span": {
      color: "$primary",
      margin: 0,
    },
  },

  "& a": {
    width: "20px",
    height: "20px",
    margin: "0 0 25px auto",
  },
});

const FormWrapperInfoList = styled("ul", {
  width: "332px",
  paddingLeft: "0px",
  listStyle: "none",
  marginTop: "24px",
  color: "$grayscale0Fixed",
  "& li": {
    position: "relative",
    paddingLeft: "16px",
    marginBottom: "24px",
    textAlign: "left",
    "&:before": {
      position: "absolute",
      left: 0,
      top: "6px",
      content: " ",
      width: "8px",
      height: "8px",
      background: "$primary",
      borderRadius: "50%",
    },
  },
});

const BoxHeader = styled("div", {
  paddingBottom: "24px",
  borderBottom: "1px solid $grayscale10",
  marginBottom: "24px",
  display: "flex",
});

const CloseButton = styled("button", {
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  border: "none",
  background: "none",
  width: "19px !important",
  height: "19px",
  padding: "0",
  lineHeight: 0,
  img: {
    width: "100%",
    height: "100%",
  },
  "@bp4": {
    filter: "invert(1)",
  },
  "svg": {
    fill: "$grayscale100",
    width: "16px",
    height: "16px",
  }
});

export default LoginModal;
