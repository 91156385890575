import React, { Suspense } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { h1, body } from "../../../../redesign/commonStyles/Fonts";
import AuthorBrid from "./AuthorBrid";

export interface Author {
  name: string;
  image: string;
  profession: string;
  about: string;
  portfolio: Array<string>;
}
interface IData {
  text?: string;
  backgroundUrl: string;
  authors: Array<Author>;
}

type TWrap = {
  src: string;
};

const BridImageBanner: React.FC<{ blockData: IData }> = observer(
  ({ blockData }) => {
    return (
      <Wrap src={blockData.backgroundUrl}>
        <Title className="title-wrap">
          {blockData.authors && blockData.authors.length > 0
            ?
            <Suspense fallback={null}>
              <AuthorsWrapper>
                <ImagesWrapper>
                  {blockData.authors.map((item, index) => (
                    <AuthorBrid author={item} key={index} />
                  ))}
                </ImagesWrapper>
                <a className={body()} href="#zespol-realizujacy-projekt">zespół {blockData.authors.length} osobowy</a>
              </AuthorsWrapper>
            </Suspense>
            :
            null
          }
          <h1 className={h1()}><span>{blockData.text}</span></h1>
        </Title>
        <div className="bg-img" />
      </Wrap>
    );
  }
);

const Wrap = styled.div<TWrap>`
  position: relative;
  .bg-img {
    padding-top: 78.125%;
    z-index: -19;
    background: url(${(props) => props.src}) center no-repeat;
    background-size: cover;
    @media (min-width: 996px) { 
      margin-bottom: -30px;
    }
  }

  & > img {
    position: absolute;
    top: 100px;
    object-fit: cover;
    width: 100%;
    height: 250px;
    z-index: -20;
  }
  @media (min-width: 996px) {
    height: 624px;
    .bg-img {
      position: absolute;
      top: 0;
      margin: 0;
      width: 100%;
      height: 624px;
      padding-top: 0;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${(props) => props.src}) center no-repeat;
      background-size: cover;
    }
  }
`;

const Title = styled.div`
  width: 90%;
  position: absolute;
  bottom: 0;
  margin: 0 26px;
  h1 {
    color: #131212;
    line-height: 37px !important;
    margin-top: 18px;
    span {
      background-color: #fff;
      padding-left: 2px;
      padding-right: 2px;
      box-shadow: rgb(255 255 255) 2px 0px 0px, rgb(255 255 255) -2px 0px 0px;
    }
  }
  @media (min-width: 996px) {
    position: absolute;
    bottom: 46px;
    margin: 0 0 0 86px;
    h1 {
      width: 65%;
      line-height: 71px !important;
      margin-top: 26px;
    }
  }
`;

const ImagesWrapper = styled.div`
    margin-left: 10px;
    img {
        margin-left: -10px;
        border-radius: 40px;
        border: 1px solid #fff;
    }
`

const AuthorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  a {
    margin-left: 10px;
    display: block;
    font-size: 12px;
    color: #FF6400;
    text-decoration: underline;
  }
`

export default BridImageBanner;
