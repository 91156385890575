import React from 'react';
import { styled } from "../stitches.config";
import Link from "../components/Link";
import { bodyLarge } from "./commonStyles/Fonts";
import HomeIcon from "./assets/icons/home-icon.svg";

import CtaAccountButton from "./CtaAccountButton";
import CtaMamaduButton from "./CtaMamaduButton";

type CtaButtonProps = {
    brand?: Config.Brand;
};

export const CtaButton: React.FC<CtaButtonProps> = ({
    brand = window.BRAND,
    ...props
}) => {

    if (brand === "natemat")
        return <CtaAccountButton />;
    else if (brand === "mamadu")
        return <CtaMamaduButton />;
    else
        return (
            <StyledCtaButton className={bodyLarge()}>
                <Link data-analytics-name="article-cta-btn" to='/'>
                    <StyledHomeIcon />
                    Więcej ciekawych artykułów znajdziesz na stronie głównej
                    {brand === "dadhero" && " dadHERO.pl"}
                    {brand === "aszdziennik" && " ASZdziennik.pl"}
                    {brand === "innpoland" && " INNPoland.pl"}
                </Link>
            </StyledCtaButton>
        )
};


const StyledCtaButton = styled('div', {
    "a": {
        width: "100%",
        margin: "0 auto 18px auto",
        color: "$grayscale0Fixed !important",
        backgroundColor: "$primary",
        border: "2px solid $primary",
        cursor: "pointer",
        padding: "7px 16px 9px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "$primary120",
            border: "2px solid $primary120",
        },
    }
})

const StyledHomeIcon = styled('div', {
    display: "flex",
    marginRight: "16px",
    width: "16px",
    height: "17px",
    backgroundImage: `url(${HomeIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexShrink: 0,
})

export default CtaButton