import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react";

import { useStores, inIframe } from "../helpers";

const Link = observer(({ to = "", className = "", children, style = undefined, state = undefined, onRedirectFinish = () => { }, disableNofollow = false, 'data-analytics-name': dataAnalyticsName = "" }) => {
  const { UIStore, AdsStore } = useStores();
  let link = document.createElement("a");
  link.setAttribute("href", to);
  // jeśli link wewnętrzny to pomijam domene
  if (window.location.hostname === link.hostname) {
    to = link.pathname;
  }

  const routerLinkClick = (e) => {
    UIStore.hideAllPopUps();

    // @todo zrobić bez timeout, czyli po przeniesieniu do nowego routa
    setTimeout(() => window.scrollTo(0, 0), 150);
    // @todo - gdzie jest zdefiniowane UIStore.hideSidebar???
    // return UIStore.hideSidebar(e);
  };

  const isGlob360Brand = to.startsWith("http") ? ["natemat", "innpoland", "aszdziennik", "mamadu", "dadhero", "grupanatemat"].some(str => to.includes(str)) : true
  window.addEventListener("redirectFinish", () => AdsStore.handleRedirectFinish(onRedirectFinish));
  return inIframe() || to.startsWith("http") ? (
    <a
      className={className}
      target={inIframe() ? "_top" : "_blank"}
      rel={(disableNofollow || isGlob360Brand) ? undefined : 'noopener noreferrer nofollow'}
      style={style}
      onClick={UIStore.hideSidebar}
      href={to}
      data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      style={style}
      onClick={routerLinkClick}
      className={className}
      data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
      to={{
        pathname: to,
        search: link.search,
        hash: link.hash,
        state: state
      }}
    >
      {children}
    </RouterLink>
  );
})


export default Link;
