import styled from "@emotion/styled";

export const H2 = styled.h2<{ color?: string }>`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: ${({ color }) => (color ? color : "#131212")};
  @media (min-width: 996px) {
    font-size: 38px;
  }
`;

export const H3 = styled.h3<{ color?: string }>`
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: ${({ color }) => (color ? color : "#272424")};
`;

export const H4 = styled.h4`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.09;
  letter-spacing: -0.32px;
  color: #333333;
  font-weight: normal;
  font-stretch: normal;
  @media (min-width: 996px) {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #b7b7b7;
  }
`;

type TCircleImg = {
  diameter: number;
};

export const CircleImg = styled.img<TCircleImg>`
  width: ${({ diameter }) => diameter}px;
  height: ${({ diameter }) => diameter}px;
  border-radius: 50%;
`;
