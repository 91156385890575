import * as React from "react";
import {
    styled,
} from "../../../stitches.config";

import Link from "../../../components/Link";
import Logo from "../../atoms/Logo";
//import ArrowLeft from "../../assets/icons/arrow-left-nav.svg";


export const NavbarSimple: React.FC = () => {
    return (
        <StyledNavbarSimple>
            <Link to="./">
                <Arrow>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.08696 13.7025L0.384155 7.99967L6.08696 2.29687L7.21833 3.42825L3.44713 7.19944L15.5335 7.19944L15.5335 8.79944L3.44667 8.79944L7.21833 12.5711L6.08696 13.7025ZM6.08704 2.5798L0.667129 7.99971L6.087 13.4196L6.08696 13.4196L0.666999 7.99967L6.08696 2.57972L6.08704 2.5798ZM2.96445 7.39944L2.96441 7.39948L15.3335 7.39948V7.39944L2.96445 7.39944Z" />
                    </svg>
                </Arrow>
                <Logo
                    css={{
                        width: 78,
                        height: 12,
                        "& svg": {
                            height: 12,
                        }
                    }}
                    type="dark"
                />
            </Link>
        </StyledNavbarSimple>
    );
};

const StyledNavbarSimple = styled("nav", {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "40px",
    zIndex: 10,
    "& a": {
        backgroundColor: "$grayscale100Fixed",
        width: "134px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
});

const Arrow = styled("div", {
    width: "16px",
    height: "16px",
    marginRight: "8px",
    "& svg": {
        fill: "$primary"
    }
})


export default NavbarSimple;
