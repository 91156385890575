import React, { Component } from "react";
import { inject, observer } from "mobx-react";

@inject("ArticleStore")
@observer
class TrudatVoteDescription extends Component {
  render() {
    var description = null,
      vote = this.props.ArticleStore.trudatVote,
      context = this.props.context;

    console.log("trudatVote", vote, context);

    if (vote === null) {
      // do nothing
    } else if ((vote && context === "true") || (!vote && context === "false")) {
      description = "Brawo, miałeś rację!";
    } else {
      description = "Niestety, nie trafiłeś";
    }

    return (
      <div className="art__conclusion__vote-description">{description}</div>
    );
  }
}

export default TrudatVoteDescription;
