import * as React from "react"
import { styled, CSS, defaultColors } from "../../stitches.config"
import { ReactComponent as NtLogo } from "../assets/logos/natemat/natemat-logo.svg"
import { ReactComponent as AszLogo } from "../assets/logos/aszdziennik/aszdziennik-logo.svg"
import { ReactComponent as DadLogo } from "../assets/logos/dadhero/dadhero-logo.svg"
import { ReactComponent as InnLogo } from "../assets/logos/innpoland/innpoland-logo.svg"
import { ReactComponent as MmdLogo } from "../assets/logos/mamadu/mamadu-logo.svg"


type LogoProps = {
    // TODO zostawiam na razie tak - type clashes https://gist.github.com/sseagull/e2e7fe58f7f236897d342e776bb549a2
    css?: any;
    type?: "white" | "dark" | "color";
    className?: string;
    brand?: Config.Brand;
}

const logo: { [key in Config.Brand]: React.ReactElement } = {
    natemat: <NtLogo />,
    aszdziennik: <AszLogo />,
    dadhero: <DadLogo />,
    innpoland: <InnLogo />,
    mamadu: <MmdLogo />
}

const Logo: React.FC<LogoProps> = ({ css, className = "", type = "white", brand = window.BRAND }) => {
    return <StyledLogo css={{ ...css }}
        className={className}
        color={type}
        brand={brand}>
        {logo[brand]}
    </StyledLogo>
}

const StyledLogo = styled('div', {
    maxWidth: 356,
    maxHeight: 116,
    display: "grid",
    placeItems: "center",
    variants: {
        color: {
            white: {
                backgroundColor: defaultColors.grayscale0,
            },
            dark: {
                backgroundColor: defaultColors.grayscale100,
                "& svg": {
                    "& path": { fill: defaultColors.grayscale0 },
                }
            },
            color: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: defaultColors.grayscale0
                    }
                }
            }
        },
        brand: {
            natemat: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$natematPrimary"
                    }
                }
            },
            aszdziennik: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$aszdziennikPrimary"
                    }
                }
            },
            mamadu: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$mamaduPrimary"
                    }
                }
            },
            innpoland: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$innpolandPrimary"
                    }
                }
            },
            dadhero: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$dadheroPrimary"
                    }
                }
            },
        }
    },
    compoundVariants: [
        {
            brand: 'natemat',
            color: 'color',
            css: {
                backgroundColor: "$natematPrimary",
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$grayscale0"
                    }
                }
            }
        },
        {
            brand: 'aszdziennik',
            color: 'color',
            css: {
                backgroundColor: "$aszdziennikPrimary",
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$grayscale0"
                    }
                }
            }
        },
        {
            brand: 'mamadu',
            color: 'color',
            css: {
                backgroundColor: "$mamaduPrimary",
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$grayscale0"
                    }
                }
            }
        },
        {
            brand: 'innpoland',
            color: 'color',
            css: {
                backgroundColor: "$innpolandPrimary",
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$grayscale0"
                    }
                }
            }
        },
        {
            brand: 'dadhero',
            color: 'color',
            css: {
                backgroundColor: "$dadheroPrimary",
                "& svg": {
                    "& [class$=dots]": {
                        fill: "$grayscale0"
                    }
                }
            }
        },
    ]
})

export default Logo