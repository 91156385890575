import React from "react";
import { styled } from "../../../stitches.config";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import Logo from "../../atoms/Logo";

import dadheroMobile from "../../../assets/img/errorPage/DadheroMobile.png"
import dadheroDesktop from "../../../assets/img/errorPage/DadheroDesktop.png"

type ErrorProps = {
    isDarkMode?: boolean;
};

export const ErrorDadhero: React.FC<ErrorProps> = ({
    isDarkMode,
}) => {
    return (
        <Box>
            <Description>
                dadherozero.
                <br />
                Nie mamy nic na ten temat, ale nie szkodzi. Przeczytaj w dadhero inne interesujące teksty.
            </Description>
            <ImageMobile />
            <LogoWrapper>
                <Logo type={isDarkMode ? "dark" : "white"} brand="dadhero" />
            </LogoWrapper>
        </Box>
    );
}

const Box = styled("div", {
    width: "100%",
    maxWidth: "1272px",
    margin: "0 auto",
    color: "$grayscale100",
    marginBottom: "32px",
    position: "relative",
    marginTop: "40px",
    "@bp4": {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center right",
        height: "500px",
        marginBottom: "48px",
        backgroundImage: `url(${dadheroDesktop})`,
    },
});

const ImageMobile = styled("div", {
    width: "100%",
    maxWidth: "400px",
    height: "270px",
    margin: "0 auto 60px auto",
    backgroundPosition: "center",
    backgroundImage: `url(${dadheroMobile})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    "@bp4": {
        display: "none"
    },
});

const LogoWrapper = styled("div", {
    margin: "0 auto",
    width: "150px",
    height: "30px",
    "@bp4": {
        position: "absolute",
        left: 0,
        bottom: 0,
    }
});

const Description = styled("div", {
    maxWidth: "400px",
    width: "100%",
    padding: "0 24px",
    color: "$grayscale100",
    margin: "0 auto 32px auto",
    textAlign: "left",
    fontSize: "18px",
    lineHeight: "24px",
    "@bp4": {
        margin: "0 0 32px 0",
        maxWidth: "520px",
        padding: "0",
        fontSize: "28px",
        lineHeight: "32px",
    }
});

export default withThemeContext(ErrorDadhero);
