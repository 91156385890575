import React from "react";
import styled from "@emotion/styled";

import Body from "./Content/Body";
import Parts from "./Parts/Parts";

import SlateRenderer from "./Parts/Slate/SlateRenderer";
import {
  ColourModeContext,
  withThemeContext,
} from "../../redesign/components/StitchesThemeProvider";
import { css } from "@emotion/core";
import Breadcrumbs from "../../redesign/components/Breadcrumbs";

class Content extends React.Component {
  render() {
    const { isDarkMode } = this.props;
    const article = this.props.article;
    const fetchedArticle = this.props.fetchedArticle;
    const slateRendered = ["ARTICLE_SLATE", "ARTICLE_VIDEO", "ARTICLE_PODCAST"];

    slateRendered.push("LIVE");

    return (
      <section>
        <Wrapper
          isDarkMode={isDarkMode}
          className={"article-body article-body--" + article.category.type}
          id="article-body"
        >

          <div className="art">
            {slateRendered.includes(article.display_type) ? (
              <SlateRenderer
                article={article}
                parts={article.BodyPart}
                hideAdslots={this.props.hideAdslots}
              />
            ) : (
              <Parts
                article={article}
                parts={article.BodyPart}
                hideAdslots={this.props.hideAdslots}
                fetchedArticle={fetchedArticle}
              />
            )}
          </div>
          <Body hideAdslots={this.props.hideAdslots} />
        </Wrapper>
      </section>
    );
  }
}

const Wrapper = styled.div`
  ${({ isDarkMode }) =>
    isDarkMode &&
    css`
      .art {
        h1 {
          color: white;
        }
        color: #e7e7e7;
      }
    `};
  a {
    text-decoration: none;
    color: ${(props) => props.theme.mainColor};
  }
  a:visited {
    color: ${(props) => props.theme.mainColor};
  }

  @media (min-width: 996px) {
    margin-top: 10px;
  }

  .breadcrumbs{
    margin-top: 50px;
  }
`;

export default withThemeContext(Content);
