import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import MODAL_CONFIG, { ModalFields, ModalType } from "../redesign/Modal/config";
import { PaymentType } from "../redesign/types/Payment";
import arrayCompare from "./arrayCompares";
import getCamelizeValue from "./getCamelizeValue";

enum ModalParams {
  MODAL_TYPE = "modal-type",
}

const getSearchParams = (searchParams: URLSearchParams): string[] => {
  const parsedParams: string[] = [];
  // @ts-ignore
  for (const key of searchParams.keys()) {
    if (key !== ModalParams.MODAL_TYPE) parsedParams.push(key);
  }
  return parsedParams;
};

const getModalParams = (type: ModalType | null) => {
  let params: string[] = Object.values(ModalParams);
  if (type) {
    params = params.concat(MODAL_CONFIG[type].params);
  }
  return params;
};

const getRequireParams = (type: ModalType, data: ModalFields) => {
  switch (type) {
    case ModalType.PAYMENT:
      const paymentType = data.paymentType as PaymentType;
      // @ts-ignore
      return MODAL_CONFIG.PAYMENT.requireParams[paymentType];
    default:
      return [];
  }
};

const useModal = (): {
  modalType: ModalType | null;
  modalData: ModalFields | null;
  toggleModal: () => void;
} => {
  const location = useLocation();
  const history = useHistory();
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [modalData, setModalData] = useState<ModalFields | null>(null);

  const getModalData = (modalType: ModalType): ModalFields => {
    const paramsURL = new URLSearchParams(location.search);
    const modalParams = getModalParams(modalType);
    const modalData = {};
    modalParams.forEach((param) => {
      // @ts-ignore
      modalData[getCamelizeValue(param)] = paramsURL.get(param);
    });
    return modalData as ModalFields;
  };

  useEffect(() => {
    const paramsURL = new URLSearchParams(location.search);
    const type = paramsURL.get(ModalParams.MODAL_TYPE)?.toUpperCase();
    const isValidType = type && type in ModalType;

    if (isValidType) {
      const paramsData = getModalData(type as ModalType);
      const requireParams = getRequireParams(type as ModalType, paramsData) || [];
      const params = getSearchParams(paramsURL);
      const isValidParams = arrayCompare(requireParams, params);


      if (isValidParams) {
        setModalType(type as ModalType);
        setModalData(paramsData);
      } else {
        setModalType(null);
        setModalData(null);
      }
    }
  }, [location]);

  const removeParams = () => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const params = getModalParams(modalType);

    Object.values(params).forEach((param) => {
      searchParams.delete(param);
    });
    history.replace({ search: searchParams.toString() });
  };

  const toggleModal = () => {
    setModalType(null);
    removeParams();
  };

  return {
    modalType,
    toggleModal,
    modalData,
  };
};

export default useModal;
