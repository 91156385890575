import React from 'react';
import { styled } from "../../stitches.config"
import { p, pStrong } from "../commonStyles/Fonts";
import Link from "../../components/Link";

import RenderSlateChildren from "../../components/Article/Parts/Slate/RenderSlateChildren"
import { SlateArticleType } from "../../stores/ArticleStore"
import { FrameElement } from "../../components/Article/Parts/Slate/types"
import { withThemeContext } from '../components/StitchesThemeProvider';

interface SpecialTextProps {
    element: FrameElement;
    article: SlateArticleType;
    isDarkMode?: boolean;
}

const SpecialText: React.FC<SpecialTextProps> = ({
    element,
    article,
    isDarkMode,
    ...props
}) => {
    return (
        <StyledSpecialText className={p()}>
            <RenderSlateChildren element={element.children} article={article} />
        </StyledSpecialText>
    );
};

const StyledSpecialText = styled('div', {
    margin: "40px 0 32px 0",
    background: "$grayscale0",
    borderLeft: "4px solid $primary",
    padding: "32px 40px 40px 40px",
    "@bp4": {
        margin: "64px 0 56px 0",
    },
    "& p": {
        margin: "0 0 16px 0",
    },
    "& a": {
        color: "$primary",
        textDecoration: "none",
        borderBottom: "1px solid $primary",
        marginBottom: "16px",
    },
    variants: {
        variant: {
            dark: {
                background: "#232323",
            }
        }
    }
})

export default withThemeContext(SpecialText)