import React from 'react'
import styled from "@emotion/styled";
import { observer } from 'mobx-react'
import Html from './Html'

import { useStores } from '../../../helpers/useStores'


const Header = ({ header }) => {
    const { text, level } = header;
    const { ArticleStore } = useStores();

    if (ArticleStore.article.display_type === "BRID") {
        return (
            <BridHeaderWrap className="brid-header-wrap">
                {(() => {
                    switch (level) {
                        case 1:
                            return (<h1>{text}</h1>);
                        case 2:
                            return (<h2>{text}</h2>);
                        case 3:
                            return (<h3>{text}</h3>);
                        case 4:
                            return (<h4>{text}</h4>);
                        case 5:
                            return (<h5>{text}</h5>);
                        case 6:
                            return (<h6>{text}</h6>);
                    }
                })()}
            </BridHeaderWrap>
        )
    }


    if (ArticleStore.article.display_type === "HARDCOVER") {
        return (
            <HardcoverHeaderWrap>
                {(() => {
                    switch (level) {
                        case 1:
                            return (<h1>{text}</h1>);
                        case 2:
                            return (<h2>{text}</h2>);
                        case 3:
                            return (<h3>{text}</h3>);
                        case 4:
                            return (<h4>{text}</h4>);
                        case 5:
                            return (<h5>{text}</h5>);
                        case 6:
                            return (<h6>{text}</h6>);
                    }
                })()}
            </HardcoverHeaderWrap>
        )
    }

    return (

        <BizonHeaderWrap isAligned={header.isAligned}>
            {(() => {
                switch (level) {
                    case 1:
                        return (<h1>{text}</h1>);
                    case 2:
                        return (<h2>{text}</h2>);
                    case 3:
                        return (<h3>{text}</h3>);
                    case 4:
                        return (<h4>{text}</h4>);
                    case 5:
                        return (<h5>{text}</h5>);
                    case 6:
                        return (<h6>{text}</h6>);
                }
            })()}
        </BizonHeaderWrap>
    )
}
const BizonHeaderWrap = styled.div`
    margin: 15px 38px;
    text-align: ${props => props.isAligned ? 'center' : 'left'};
    /* color: $grayscale100 */ 
    color: #131212;	
    h1,h2,h3,h4,h5,h6 {
        font-family: "HK Grotesk";
        font-weight: 700;
        letter-spacing: 0.2px;
        margin: 0;
    }
    h1{
        font-size: 28px;
        line-height: 34px;
    }
    h2, h3 {
        font-size: 24px;
        line-height: 30px;
    }
    h4,h5,h6{
        font-size: 17px;
        font-weight: 600;
    }

    @media (min-width: 996px) {
        margin: 35px auto;
        max-width: 840px;
        h1,h2,h3,h4,h5,h6 {
            font-weight: 700; 
            line-height: initial;
        }
        h1{
            font-size: 54px;
            line-height: 62px;
            letter-spacing: 0px;
        }
        h2{
            font-size: 32px;
            line-height: 40px;
            letter-spacing: 0.3px;
        }
        h3{
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.2px;
        }
        h4, h5, h6 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
  }
`

const BridHeaderWrap = styled.div`
    padding: 0px 16px;
    p{
        margin: 0;
        font-weight: 300;
        color: #272424;
        font-size: 17px;
    }
    @media (min-width: 992px) {
        padding: 0px 48px;
        p{
            font-size: 18px;
            color: #737373;
        }
    }
`

const HardcoverHeaderWrap = styled.div`
    margin: 9px 35px;
    h1,h2,h3,h4,h5,h6 {
        font-family: "HK Grotesk", sans-serif;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0;
    }
    @media (min-width: 992px) {
        margin: 25px 115px;
    }
`

export default Header