import React, { useState, useEffect, useRef } from "react"

/**
 * ukrywa zawartość jeśli wysokość zawartości jest mniejsza niż $height
 * używane np. do widgetu komentarzy z facebooka
 * 
 * @param {*} param0 
 * @returns 
 */
const ContentHider = ({ children, height = 200 }) => {
    const [showContent, showShowContent] = useState(false);

    const contentRef = useRef(null);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(e => {
            e.forEach(entry => {
                //Recalculate
                const contentHeight = entry.contentRect.height;
                if (contentHeight >= height) {
                    showShowContent(true);
                    resizeObserver.unobserve(entry.target);
                }
                //console.log('ContentHider', contentHeight, height)
            })
        });
        if (contentRef.current)
            resizeObserver.observe(contentRef.current);

        return () => {
            resizeObserver.disconnect();
        }
    }, []);

    return (
        <div style={{ overflow: "hidden", height: (showContent ? 'auto' : '0px') }}>
            <div style={{ minHeight: `${height + 20}px` }}>
                <div ref={contentRef}>{children}</div>
            </div>
        </div>
    );
}

export default ContentHider