import React from 'react';
import styled from "@emotion/styled";
import Html from './Html';
import Parts from './Parts';

const Block = ({ settings, article, hideAdslots }) => {
    const { parts, position, style } = settings;
    return (
        <Wrapper className={position + " " + style}>
            <Parts parts={parts} article={article} hideAdslots={hideAdslots} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;

    &.pos_indent {
        padding-left: 10px;
        padding-right: 10px;
        @media (min-width: 992px) {
            padding-left: 20px;
        }   
    }
    &.style_gray {
        /* padding-top: 5px;
        padding-bottom: 5px; */
        background: #E9E9E9;
    }
    &.ls_gray {
        /* border-left: 4px solid #DBDBDB; 
        wyłączenie globalne szarego borderu na zlecenie Michała Mańkowskiego 20.05.2020 - informacja w docs:
        https://docs.google.com/document/d/1GYqcH3LV12jGhsWoCNh3MxGgLuVLBi1gJWWxbsfyoaM/edit?ts=5d5bb3af#
        */
    }
    &.style_nomargins{
        /* #5149 */
        padding-left: 0;
        padding-right: 0;
    }

    p {
        margin: 0;
    }
`

export default Block;