import * as React from "react"

type AdditionalAttributes = {
    [key: string]: string;
}

export const useScript = (url: string, additionalAttrs: AdditionalAttributes = {}): void => {
    React.useEffect(() => {
        const script: HTMLScriptElement = document.createElement('script');

        script.async = true;
        script.src = url;

        for (const key of Object.keys(additionalAttrs)) {
            script.setAttribute(key, additionalAttrs[key])
        }

        document.body.appendChild(script);
        return () => {
            document.body?.removeChild(script);
        }
    }, [url])
}