import React from 'react';
import styled from "@emotion/styled"
import Socials from './Socials'

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"

const LastElement = ({ blockData, articleStats }) => {
    const { url = "", authorInfo, picsInfo, additionalInfo } = blockData
    const srcUrl = url.replace("0,0,0,0", `${window.innerWidth},0,0,0`)
    return (<LastElWrapper src={srcUrl}>
        <div>
        </div>
        {authorInfo &&
            <ArticleInfo>
                <p>
                    <span className="article-info__authorInfo"></span>{authorInfo}
                    <br />
                    <span className="article-info__picsInfo"></span>{picsInfo}
                    {additionalInfo !== "" && <span><br /><br />{additionalInfo}</span>}
                </p>
                <Socials stats={articleStats} />
            </ArticleInfo>
        }
    </LastElWrapper>)
}

const LastElWrapper = styled.div`
    margin-top: 30px;
    position: relative;
    min-height: calc(38% + 60px);
    & > div:first-of-type {
        background: ${props => `url(${props.src}) center no-repeat`};
        background-size: 100%;
        padding-bottom: 38%;
        @media (min-width: 992px) {
            height: 553px;
        }
    }
`

const ArticleInfo = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 38px;
    background-color: #fff;
    min-height: 60px;
    p{	
        margin-top: 15px;
        color: #2D2828;	
        font-size: 12px;	
        letter-spacing: -0.34px;	
        line-height: 15px;
    }
    .article-info__authorInfo {
        &::before {
           content: "Autor: ";
        }
    }
    .article-info__picsInfo {
        &::before {
            content: "Zdjęcia: ";
        }
    }
    
@media (min-width: 992px) {
    width: 220px;
    top: 90px;
    left: 123px;
    p{	
        margin: 21px auto;
        max-height: 90px;	
        width: 174px;	
        color: #272424;	
        font-size: 12px;	
        letter-spacing: -0.34px;	
        line-height: 15px;
    }
}
`

export default withDrawingBoard(props => props.blockData.hasCanvas)(LastElement)