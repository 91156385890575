
function getSelector(node, maxLen = 100) {
    let sel = '';
    try {
        while (node && node.nodeType !== 9) {
            const part = node.id ? '#' + node.id : node.nodeName.toLowerCase() + (
                (node.className && node.className.length) ?
                    '.' + Array.from(node.classList.values()).join('.') : '');
            if (sel.length + part.length > maxLen - 1) return sel || part;
            sel = sel ? part + '>' + sel : part;
            if (node.id) break;
            node = node.parentNode;
        }
    } catch (err) {
        // Do nothing...
    }
    return sel;
}

function getLargestLayoutShiftEntry(entries) {
    return entries.reduce((a, b) => a && a.value > b.value ? a : b);
}

function getLargestLayoutShiftSource(sources) {
    return sources.reduce((a, b) => {
        return a.node && a.previousRect.width * a.previousRect.height >
            b.previousRect.width * b.previousRect.height ? a : b;
    });
}

function wasFIDBeforeDCL(fidEntry) {
    const navEntry = performance.getEntriesByType('navigation')[0];
    return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
}

function getDebugInfo(name, entries = []) {
    // In some cases there won't be any entries (e.g. if CLS is 0,
    // or for LCP after a bfcache restore), so we have to check first.
    if (entries.length) {
        if (name === 'LCP') {
            const lastEntry = entries[entries.length - 1];
            return {
                debug_target: getSelector(lastEntry.element),
                event_time: lastEntry.startTime,
            };
        } else if (name === 'FID') {
            const firstEntry = entries[0];
            return {
                debug_target: getSelector(firstEntry.target),
                debug_event: firstEntry.name,
                debug_timing: wasFIDBeforeDCL(firstEntry) ? 'pre_dcl' : 'post_dcl',
                event_time: firstEntry.startTime,
            };
        } else if (name === 'CLS') {
            const largestEntry = getLargestLayoutShiftEntry(entries);
            if (largestEntry && largestEntry.sources) {
                const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
                if (largestSource) {
                    return {
                        debug_target: getSelector(largestSource.node),
                        event_time: largestEntry.startTime,
                    };
                }
            }
        }
    }
    // Return default/empty params in case there are no entries.
    return {
        debug_target: '(not set)',
    };
}

export const sendLCPToGTM = ({ name, delta, id, entries }) => {
    let label = id;
    let value = Math.round(name === 'CLS' ? delta * 1000 : delta);

    const element = entries?.at(-1)?.element;
    if (element) {
        label = element?.nodeName + "." + element?.className;
        console.log('LCP', label);
    }

    console.log('LCP', label, entries);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "analyticsEvent",
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventLabel: label,
        eventValue: value,
        nonInteraction: true
    });
}

export const sendToAnalytics = ({ name, delta, id, entries }) => {
    let label = id;
    let value = Math.round(name === 'CLS' ? delta * 1000 : delta);

    if (name == 'LCP') {
        const element = entries?.at(-1)?.element;
        if (element) {
            label = element?.nodeName + "." + element?.className;
            // console.log('LCP', label);
        }
    } else if (name == 'CLS') {
        if (delta < 0.1) {
            return null;
        }

        const element = entries?.at(-1)?.sources[0]?.node;
        if (element && element.className) {
            label = element?.nodeName + "." + element?.className;
            // console.log("CLS", label, value, element, entries);
        }
    }

    // console.log({ name, delta: name === 'CLS' ? delta * 1000 : delta, id, ...getDebugInfo(name, entries) })
    // gtag tylko na natemat
    window.gtag instanceof Function && window.gtag('event', name, {
        event_category: 'Web Vitals',
        event_action: name,
        event_label: label,
        value: value,
        non_interaction: true,
        ...getDebugInfo(name, entries)
    })

    if (window.ga) {
        window.ga('send', 'event', {
            eventCategory: 'Web Vitals',
            eventAction: name,
            eventLabel: label,
            eventValue: value,
            nonInteraction: true,
            transport: 'beacon',

            // OPTIONAL: any additional params or debug info here.
            // See: https://web.dev/debug-web-vitals-in-the-field/
            // dimension1: '...',
            // dimension2: '...',
            // ...
        });
    }

    if (window._gaq) {
        window._gaq.push(["_trackEvent", "Web Vitals", name, label, value, true]);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "analyticsEvent",
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventLabel: label,
        eventValue: value,
        nonInteraction: true
    });
}