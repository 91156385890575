import React from "react";
import ProgressiveMedia from "./ProgressiveMedia"

const Media = ({ media = {}, ...props }) => {
    const { ['background-image']: backgroundImage = {}, ['background-image-mobile']: backgroundImageMobile = {} } = media;
    const mediaObject = (window.innerWidth < 996 &&
        (backgroundImageMobile?.hash && backgroundImageMobile?.hash.length > 0)
        ? backgroundImageMobile
        : backgroundImage);

    // console.log('cHeight - media', props, backgroundImageMobile, backgroundImage);
    return <ProgressiveMedia
        mediaProperties={mediaObject}
        {...props} />
}

export default Media;