import React from 'react';
import { styled } from "../../../../../../stitches.config"
import { bodySmall } from "../../../../../../redesign/commonStyles/Fonts";
import KulkiSvg from "../../../../../common/KulkiSvg"


const ExpandBarBizon = () => {
    return (
        <StyledExpandBarBizon>
            <TextWrap>
                <span className={bodySmall()}>
                    <KulkiSvg />
                    rozwiń
                </span>
            </TextWrap>
        </StyledExpandBarBizon>
    );
};

const StyledExpandBarBizon = styled('div', {
    width: "100%",
    borderBottom: "3px solid $primary",
    background: "transparent",
    position: "relative",
    bottom: "24px",
})

const TextWrap = styled('div', {
    display: "flex",
    "& span": {
        color: '$grayscale0Fixed',
        fontFamily: "$grotesk",
        background: "$primary",
        padding: "3px 8px 3px 5px",
        fontSize: '1em !important',
        fontWeight: "500 !important",
        "& svg": {
            width: "8px",
            height: "16px",
            marginRight: "3px",
        }
    },
    "& circle": {
        fill: "$grayscale0Fixed",
        stroke: "$grayscale0Fixed",
    }
})

export default ExpandBarBizon