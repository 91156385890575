import React from "react";
// import { inject, observer } from "mobx-react";
import styled from "@emotion/styled";

import { withConsent } from "./HOCs/withConsent"

class OnnetworkPlayer extends React.Component {

  /**
   * nigdy nie aktualizuj, wyświetlaj ten sam player
   */
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  componentDidMount() {
    var that = this;

    // timeout, żeby to się wszystko wyrenderowało
    // setTimeout(function () {
    if (that.playerElement && that.playerElement.getAttribute('data-onnetwork-mid')) {
      var videoSrc = 'https://video.onnetwork.tv/embed.php?sid=' + that.playerElement.getAttribute('data-onnetwork-mid');

      const script = document.createElement("script");
      script.src = videoSrc;
      script.async = true;

      that.playerElement.innerHTML = '';
      if (!window.TESTING_MODE) {
        that.playerElement.appendChild(script);
      }
    }
    // }, 200);
  }

  render() {
    const mid = (this.props.mid && this.props.mid.id ? this.props.mid.id : this.props.mid);

    if (!mid) {
      return null;
    } else if (mid.length > 20) {
      // kod iframe
      return (<div className="onnetwork-video">
        <iframe className="exsminframe" src={"https://video.onnetwork.tv/frame_nat.php?mid=" + mid} frameBorder="0" scrolling="no" allowFullScreen> </iframe>
      </div>);
    } else {
      // kod script
      return (<PlayerContainer
        data-onnetwork-mid={mid}
        innerRef={element => { this.playerElement = element; }}>
      </PlayerContainer>);
    }
  }
}

const PlayerContainer = styled.div`
  margin: 18px 0;
`;

// export default OnnetworkPlayer
export default withConsent(OnnetworkPlayer)