import * as React from "react";
import styled from "@emotion/styled";
import { toJS } from "mobx"
import Media from "../../../common/Media"
import ConditionalWrapper from "../../../../helpers/ConditionalWrapper"
import Link from "../../../Link"

import { ImageElement } from "./types"

type PictureProps = {
    element: ImageElement;
    titlePart?: boolean;
}

const Picture: React.FC<PictureProps> = ({ element, ...props }) => {
    // console.log({ element: toJS(element) })
    if (!element.url && !element.data.img) {
        return null;
    }
    const width = props.titlePart ? 1170 : 750
    const url = element.url || element.data.img
    const imgUrl = url.replace("780,0,0,0", `${width * 2},0,0,0`)
    return <div>
        <FigureWrapper>
            <ConditionalWrapper
                condition={!!element.link || !!element.data?.link}
                wrapper={(children) => <Link to={element.link || element.data?.link}>{children}</Link>}
            >
                {element.data?.disablePreload
                    ?
                    <picture>
                        <source srcSet={imgUrl.replace(".jpg", ".webp")} type="image/webp" />
                        <source srcSet={imgUrl} type="image/jpeg" />
                        <img src={imgUrl} alt={element.data?.alt} title={element.title} width={`${element.data.width}px`} height={`${element.data.height}px`} />
                    </picture>
                    : <Media
                        src={element.url || element.data?.img}
                        alt={element.alt || element.data?.alt}
                        title={element.title || element.data?.title}
                        height={element.data?.height || element.data?.media?.height}
                        width={element.data?.width || element.data?.media?.width}
                        preserveRatio={true}
                    />}
            </ConditionalWrapper>
            {/* brzydko, ale trzeba ujednolicić obiekty zdjęć */}
            {(element.figcaption || element.source) && <figcaption>
                {element.figcaption}
                <span> {element.source} </span>
            </figcaption>}
            {(element.data?.figcaption || element.data?.source) && <figcaption>
                {element.data?.figcaption}
                <span> {element.data?.source} </span>
            </figcaption>}

        </FigureWrapper>
    </div>
}

const FigureWrapper = styled.figure`
  /* margin: 0 -20px 18px -20px; */
    margin:  0 0 32px 0;
    @media (min-width: 996px) {
        margin: 0 0 56px 0;
    }
  img {
    width: 100%;
    height: auto;
  }
  figcaption {
    font-style: normal;
    font-size: 14px;
    padding: 8px 0 0 0;
    line-height: 18px;
    color: #8D8D8D;
    text-align: center;
    font-weight: 600;

    @media (min-width: 996px) {
        padding: 12px 0 0 0;
    }

    span {
      position: relative;
    }
  }
`;

export default Picture