import React from "react";
import styled from "@emotion/styled";

import Media from "../../common/Media"
import ConditionalWrapper from "../../../helpers/ConditionalWrapper"
import Link from "../../Link"

const Picture = ({ picture, ...props }) => {
  if (!picture?.img) {
    return null;
  }
  const width = props.titlePart ? 1170 : 750

  const imgUrl = picture.img.replace("780,0,0,0", `${width * 2},0,0,0`);
  return <div>
    <FigureWrapper>
      <ConditionalWrapper
        condition={!!picture.link}
        wrapper={(children) => <Link to={picture.link}>{children}</Link>}
      >
        {picture.disablePreload
          ? <picture>
            <source srcSet={imgUrl.replace(".jpg", ".webp")} type="image/webp" />
            <source srcSet={imgUrl} type="image/jpeg" />
            <img src={imgUrl} alt={picture.alt} title={picture.title} width={`${picture.width}px`} height={`${picture.height}px`} />
          </picture>
          : <Media
            src={picture.img}
            alt={picture.alt}
            title={picture.title}
            height={picture.height}
            width={picture.width}
            preserveRatio={true}
          />
        }
      </ConditionalWrapper>
      {(picture.figcaption || picture.source) && <figcaption>
        {picture.figcaption}
        <span> {picture.source} </span>
      </figcaption>}
    </FigureWrapper>
  </div>
}

const FigureWrapper = styled.figure`
  /* margin: 0 -20px 18px -20px; */
  img {
    width: 100%;
    height: auto;
  }
   figcaption {
    font-style: normal;
    font-size:  14px;
    padding: 8px 0 0 0;
    line-height: 18px;
    color: #8D8D8D;
    text-align: center;
    font-weight: 600;

    @media (min-width: 996px) {
        padding: 12px 0 0 0;
    }

    span {
      position: relative;
    }
  }
`;

export default Picture