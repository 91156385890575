import React from "react"
import styled from "@emotion/styled"
import ReactCompareImage from 'react-compare-image';

import { withDrawingBoard } from "../../HOCs/withDrawingBoard"

const ImageCompare = ({ blockData, ...props }) => {
    const { url1 = "", url2 = "" } = blockData;
    return <Wrap>
        <ReactCompareImage leftImage={url1} rightImage={url2} skeleton={() => { console.log(props) }} />
    </Wrap>
}

const Wrap = styled.div`
    margin: 25px auto;
    @media(min-width: 992px){
        max-width: 700px;
        margin: 35px auto;
    }
`;

export default withDrawingBoard(props => props.blockData.hasCanvas)(ImageCompare)