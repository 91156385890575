import React from 'react';
import { observer } from 'mobx-react'

import { styled } from "../../stitches.config"
import Author from '../Author';
//import SocialButton from "../atoms/SocialButton"
import SocialButtonLike from "../atoms/SocialButtonLike"
import SocialButtonComment from "../atoms/SocialButtonComment"
import SocialButtonShare from "../atoms/SocialButtonShare"
import { useStores, pushDataLayer } from "../../helpers"

interface AuthorDateProps {
    article: ArticleAuthor;
}

const AuthorDate: React.FC<AuthorDateProps> = observer(({
    article
}) => {
    const { ArticleStore } = useStores();
    const url = ArticleStore.article.fb_url;

    return (
        <StyledAuthorDate className="author-date">
            <Wrapper>
                <Author article={article} starSize={"small"} border={true} starButton />
            </Wrapper>
            <Stats>
                <SocialButtonLike url={url} />
                <SocialButtonComment />
                <SocialButtonShare url={url} />
            </Stats>
        </StyledAuthorDate>
    );
});

const StyledAuthorDate = styled('div', {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
    "@bp4": {
        marginBottom: "40px",
    }
})

const Stats = styled('div', {
    display: "none",
    "@bp4": {
        display: "flex",
    }
})

const Wrapper = styled('div', {
    "& .avatar": {
        width: "72px",
        height: "72px",
        borderRadius: "72px",
        marginRight: "20px !important",
    },
    "& p": {
        margin: "0 0 4px 0 !important",
    },
})

export default AuthorDate