import React, { useState } from "react";

import { useAuth } from "../../context/AuthContext";
import { useStores } from "../../helpers/useStores";
import ZenModal, { ZenModalStep } from "./ZenModal";
import LoginModal from "./LoginModal";

const ZenModalWrapper: React.FC = () => {
  const { UIStore } = useStores();
  const [{ user }] = useAuth();

  return user
    ? (user.valid ? null : <ZenModal onCloseModal={() => UIStore.setShowLoginModal(false)} step={ZenModalStep.WELCOME} />)
    : <LoginModal onCloseModal={() => UIStore.setShowLoginModal(false)} />

};


export default ZenModalWrapper;
